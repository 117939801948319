import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUserImpersonation, patchUserImpersonation } from "../../services/httpClient";
import useApp from "../../utils/useApp";

const InternalLogin = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { setAuth }: any = useApp();
  const [user, setUser] = useState("");
  const [errMsg, setErrMsg] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    const authCode = queryParams.get("authCode");

    if (authCode) {
      setTimeout(() => {
        fetchUserImpersonation(authCode)
          .then((res: any) => {
            const response = res[0].data;
            const id = response[0]?.id;
            setUser(response[0]?.firstname + " " + response[0]?.lastname);

            const expireDate = new Date(response[0]?.authcode_expiration_at);
            const currentDate = new Date();

            //Check if expired auth code
            if (currentDate < expireDate) {
              const profile = {
                id: response[0]?.user_id,
                firstname: response[0]?.firstname,
                lastname: response[0]?.lastname,
                email: response[0]?.email,
                role: response[0]?.role,
                userOrgID: response[0]?.organization_id,
              };

              let admin: boolean = false;
              if (response[0]?.role === "admin") {
                admin = true;
              }

              setAuth({ profile, admin, twofactor: true });

              setTimeout(() => {
                patchUserImpersonation(id)
                  .then((res: any) => {
                    navigate("/");
                  })
                  .catch((err: any) => {
                    console.log(err);
                  });
              }, 1000);
            } else {
              setErrMsg("Authorization Expired");
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      }, 1000);
    }
  }, []);

  return (
    <div className="h-100 app-primary-alt-color align-content-center">
      <Row className="w-100 text-center">
        <Col xs={8} sm={8} md={6} lg={5} xl={4} className="m-auto">
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <Card className="p-5 shadow-sm rounded-lg d-block">
            <img src="/AndGo_Logo_Color_Horizontal.png" width="220" height="50" alt="And Go" className="mb-4" />
            <h3 className="mt-3 mb-3 app-orange-text">Logging in as {user}</h3>
            <Spinner animation="border" style={{ color: "#DA7E39" }} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default InternalLogin;
