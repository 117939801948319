import React, { useEffect, useState } from "react";
import { Button, Col, Container, ListGroup, ListGroupItem, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchGoals, fetchNextTasks } from "../../../services/httpClient";
import { Goal } from "../../../types/goal";
import { Task } from "../../../types/task";
import ToastMsg from "../../ui/ToastMsg";
import CreateGoal from "./CreateGoal";
import GoalCard from "./GoalCard";
import TaskItem from "./TaskItem";
import { trackNavigationEvent } from "../../../utils/analytics";

interface GoalsState {
  viewGoals: boolean;
  setViewGoals: any;
  viewCompleted: boolean;
  setViewCompleted: any;
  viewArchive: boolean;
  setViewArchive: any;
  createGoal: boolean;
  setCreateGoal: any;
  myCase: boolean;
  getData: boolean;
}

const Goals = ({
  viewGoals,
  setViewGoals,
  viewCompleted,
  setViewCompleted,
  viewArchive,
  setViewArchive,
  createGoal,
  setCreateGoal,
  myCase,
  getData,
}: GoalsState) => {
  const { id } = useParams();
  const [currentGoals, setCurrentGoals] = useState<Goal[]>();
  const [completedGoals, setCompletedGoals] = useState<Goal[]>();
  const [archivedGoals, setArchivedGoals] = useState<Goal[]>();
  const [next, setNext] = useState<Task[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  const updateViewGoals = (e: any) => {
    setViewGoals(e);
    setViewCompleted(false);
    setViewArchive(false);
    setCreateGoal(false);

    trackNavigationEvent("switch_view", "goals");
  };

  const updateViewCompleted = (e: any) => {
    setViewGoals(false);
    setViewCompleted(e);
    setViewArchive(false);
    setCreateGoal(false);

    trackNavigationEvent("switch_view", "completed_goals");
  };

  const updateViewArchive = (e: any) => {
    setViewGoals(false);
    setViewCompleted(false);
    setViewArchive(e);
    setCreateGoal(false);

    trackNavigationEvent("switch_view", "archived_goals");
  };

  const updateCreateGoal = (e: any) => {
    setViewGoals(false);
    setViewCompleted(false);
    setViewArchive(false);
    setCreateGoal(e);
  };

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getGoals();
    }
  }, [getData]);

  const getGoals = () => {
    fetchGoals(id)
      .then((res: any) => {
        const allGoals = res[0].data;

        const current = allGoals.filter((n: any) => n.is_completed === 0 && n.is_archived === 0);
        setCurrentGoals(current);

        fetchNextTasks(id)
          .then((res: any) => {
            const nextTasks = res[0].data;
            nextTasks.forEach((n: Task) => {
              if (n.next_at) {
                var nextDate = new Date(n.next_at);
                nextDate = new Date(nextDate.setDate(nextDate.getDate())); //subtract here to test diff
                nextDate.setHours(nextDate.getHours() - 5);
                const currentDate = new Date();
                const timeDiff = currentDate.getTime() - nextDate.getTime();
                const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
                n.age = daysDiff;
              }
            });

            setNext(nextTasks);
          })
          .catch((err: any) => {
            console.log(err);
            setIsLoading(false);
          });

        const completed = allGoals.filter((n: any) => n.is_completed === 1 && n.is_archived !== 1);
        setCompletedGoals(completed);

        const archive = allGoals.filter((n: any) => n.is_archived === 1);
        setArchivedGoals(archive);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          {viewGoals === true && (
            <>
              <Row className="mb-0">
                <Col className="col-5">
                  <h4 className="app-header-text">Current Goals</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-secondary-color me-3 button" onClick={() => updateViewCompleted(true)}>
                    Completed
                  </Button>
                  <Button className="app-secondary-color me-3 button" onClick={() => updateViewArchive(true)}>
                    Archived
                  </Button>
                  {myCase && (
                    <Button className="app-primary-color button" onClick={() => updateCreateGoal(true)}>
                      Create Goal
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                {currentGoals?.length === 0 ? (
                  <p className="ps-3">No Current Goals</p>
                ) : (
                  currentGoals?.map((g: Goal) => (
                    <GoalCard
                      key={g.id}
                      goal={g}
                      fetchGoals={getGoals}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                      myCase={myCase}
                    />
                  ))
                )}
              </Row>
              <Row>
                <Col>
                  <h4 className="app-header-text">Next Tasks</h4>
                </Col>
              </Row>
              <Row>
                <ListGroup className="pe-0 pb-5">
                  {next?.length === 0 ? (
                    <p className="ps-3">No Next Tasks</p>
                  ) : (
                    next?.map((n: any) => (
                      <ListGroupItem key={n.id}>
                        <TaskItem task={n} goalArchived={0} goalCompleted={0} inNextTasks={true} myCase={myCase} />
                      </ListGroupItem>
                    ))
                  )}
                </ListGroup>
              </Row>
            </>
          )}
          {viewCompleted === true && (
            <>
              <Row className="mb-0">
                <Col>
                  <h4 className="app-header-text">Completed Goals</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-primary-color button" onClick={() => updateViewGoals(true)}>
                    Back
                  </Button>
                </Col>
              </Row>
              <Row>
                {completedGoals?.length === 0 ? (
                  <p className="ps-3">No Completed Goals</p>
                ) : (
                  completedGoals?.map((a: any) => (
                    <GoalCard
                      key={a.id}
                      goal={a}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  ))
                )}
              </Row>
            </>
          )}
          {viewArchive === true && (
            <>
              <Row className="mb-0">
                <Col>
                  <h4 className="app-header-text">Archived Goals</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-primary-color button" onClick={() => updateViewGoals(true)}>
                    Back
                  </Button>
                </Col>
              </Row>
              <Row>
                {archivedGoals?.length === 0 ? (
                  <p className="ps-3">No Archived Goals</p>
                ) : (
                  archivedGoals?.map((a: any) => (
                    <GoalCard
                      key={a.id}
                      goal={a}
                      fetchGoals={getGoals}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  ))
                )}
              </Row>
            </>
          )}
          {createGoal === true && (
            <>
              <Row className="mb-1">
                <Col>
                  <h4 className="app-header-text">Create Goal</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-primary-color button" onClick={() => updateViewGoals(true)}>
                    Done
                  </Button>
                </Col>
              </Row>
              <Row>
                <CreateGoal
                  fetchGoals={getGoals}
                  setShowToast={setShowToast}
                  setToastText={setToastText}
                  setToastBody={setToastBody}
                />
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Goals;
