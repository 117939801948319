import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import DatePicker from "../ui/DatePicker";

interface DatePickerState {
  startDate: string | undefined;
  setStartDate: any;
  endDate: string | undefined;
  setEndDate: any;
  dateInterval: number;
  setDateInterval: any;
  intervalOne: number;
  intervalOneText: string;
  intervalTwo: number;
  intervalTwoText: string;
  intervalThree: number;
  intervalThreeText: string;
  intervalFour: number;
  intervalFourText: string;
  onClickRefresh?: any;
  hideDates?: boolean;
  hideBtnId?: string;
}

const ReportTimeInterval = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dateInterval,
  setDateInterval,
  intervalOne,
  intervalOneText,
  intervalTwo,
  intervalTwoText,
  intervalThree,
  intervalThreeText,
  intervalFour,
  intervalFourText,
  onClickRefresh,
  hideDates = false,
  hideBtnId = "",
}: DatePickerState) => {
  const [timeOneActive, setTimeOneActive] = useState<boolean>(false);
  const [timeTwoActive, setTimeTwoActive] = useState<boolean>(false);
  const [timeThreeActive, setTimeThreeActive] = useState<boolean>(false);
  const [timeFourActive, setTimeFourActive] = useState<boolean>(true);

  const updateTime = (time: string, interval: number) => {
    setDateInterval(interval);
    const start = new Date();
    start.setDate(start.getDate() - interval);
    setStartDate(start.toISOString().slice(0, 10));
    setEndDate(new Date().toISOString().slice(0, 10));
    onClickRefresh(start.toISOString().slice(0, 10), new Date().toISOString().slice(0, 10));

    switch (time) {
      case "one":
        setTimeOneActive(true);
        setTimeTwoActive(false);
        setTimeThreeActive(false);
        setTimeFourActive(false);
        break;
      case "two":
        setTimeOneActive(false);
        setTimeTwoActive(true);
        setTimeThreeActive(false);
        setTimeFourActive(false);
        break;
      case "three":
        setTimeOneActive(false);
        setTimeTwoActive(false);
        setTimeThreeActive(true);
        setTimeFourActive(false);
        break;
      case "four":
        setTimeOneActive(false);
        setTimeTwoActive(false);
        setTimeThreeActive(false);
        setTimeFourActive(true);
        break;
      default:
        setTimeOneActive(true);
        setTimeTwoActive(false);
        setTimeThreeActive(false);
        setTimeFourActive(false);
        break;
    }
  };

  const onButtonClick = () => {
    onClickRefresh(startDate, endDate);
  };

  return (
    <>
      {!hideDates && (
        <Row>
          <Col></Col>
          <Col className="col-3">
            <DatePicker label="Start Date" currentValue={startDate} setCurrentValue={setStartDate} />
          </Col>
          <Col className="col-3">
            <DatePicker label="End Date" currentValue={endDate} setCurrentValue={setEndDate} />
          </Col>
          <Col className="col-2 d-flex align-items-center">
            <Button id={hideBtnId} className="app-primary-color h-75 w-100 button" onClick={() => onButtonClick()}>
              {"Refresh"}
            </Button>
          </Col>
          <Col></Col>
        </Row>
      )}
      <Row>
        <Col></Col>
        <Col className="col-2 d-grid">
          <Button
            className={timeOneActive ? "app-secondary-color button" : "app-secondary-color-inverse button"}
            onClick={() => updateTime("one", intervalOne)}
          >
            {intervalOneText}
          </Button>
        </Col>
        <Col className="col-2 d-grid">
          <Button
            className={timeTwoActive ? "button app-secondary-color button" : "app-secondary-color-inverse button"}
            onClick={(e) => updateTime("two", intervalTwo)}
          >
            {intervalTwoText}
          </Button>
        </Col>
        <Col className="col-2 d-grid">
          <Button
            className={timeThreeActive ? "button app-secondary-color button" : "app-secondary-color-inverse button"}
            onClick={(e) => updateTime("three", intervalThree)}
          >
            {intervalThreeText}
          </Button>
        </Col>
        <Col className="col-2 d-grid">
          <Button
            className={timeFourActive ? "button app-secondary-color button" : "app-secondary-color-inverse button"}
            onClick={(e) => updateTime("four", intervalFour)}
          >
            {intervalFourText}
          </Button>
        </Col>
        <Col></Col>
      </Row>
    </>
  );
};

export default ReportTimeInterval;
