import React from "react";
import { Toast } from "react-bootstrap";

interface ToastState {
  showToast: any;
  setShowToast: any;
  toastText: string;
  toastBody: string;
}

const ToastMsg = ({ showToast, setShowToast, toastText, toastBody }: ToastState) => {
  return (
    <Toast
      onClose={() => setShowToast(false)}
      show={showToast}
      delay={3000}
      className="toast-location"
      bg="light"
      autohide
    >
      <Toast.Header>
        <strong className="me-auto">{toastText}</strong>
      </Toast.Header>
      <Toast.Body>{toastBody}</Toast.Body>
    </Toast>
  );
};

export default ToastMsg;
