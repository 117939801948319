import ReactGA from "react-ga4";

const initializeGA = () => {
  if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
    const trackingId = "G-KNVN427704";
    if (trackingId) {
      ReactGA.initialize(trackingId);
    }
  }
  // else {
  //   const trackingId = "G-F00F2JZVPS"; //Dev
  //   if (trackingId) {
  //     ReactGA.initialize(trackingId);
  //   }
  // }
};

const trackPageView = (path: string) => {
  if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
    ReactGA.send({ hitType: "pageview", page: path });
  }
};

const trackNavigationEvent = (action: string, label: string) => {
  if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
    ReactGA.event({ category: "Navigation", action, label });
  }
};

const trackDataEvent = (action: string, label: string) => {
  if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
    ReactGA.event({ category: "Data", action, label });
  }
};

const trackFeatureEvent = (action: string, label: string) => {
  if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
    ReactGA.event({ category: "Feature", action, label });
  }
};

export { initializeGA, trackPageView, trackNavigationEvent, trackDataEvent, trackFeatureEvent };
