import React, { useState } from "react";
import { Button, Card, Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deleteUser, fetchUsers } from "../../services/httpClient";
import { User } from "../../types/user";

interface UserTabState {
  users: User[];
  setUsers: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const UserTab = ({ users, setUsers, setShowToast, setToastText, setToastBody }: UserTabState) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<User>();

  let navigate = useNavigate();

  const editUser = (e: any) => {
    navigate(`/users/user/${e}`);
  };

  const onClickDelete = (e: any) => {
    setIsDeleting(true);
    setUserToDelete(e);
  };

  const onClickDeleteFinal = () => {
    setIsLoading(true);

    deleteUser(userToDelete?.id)
      .then((res: any) => {
        setIsDeleting(false);
        setUserToDelete(undefined);

        fetchUsers()
          .then((res: any) => {
            const userList = res[0].data;
            setUsers(userList);
            setShowToast(true);
            setToastText("User Deleted");
            setToastBody(userToDelete?.firstname + " " + userToDelete?.lastname);
            setIsLoading(false);
          })
          .catch((err: any) => {
            console.log(err);
            setIsLoading(false);
          });
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onClickCancelDelete = () => {
    setIsDeleting(false);
    setUserToDelete(undefined);
  };

  const renderDelete = () => {
    return (
      <Card className="bg-light">
        <Card.Body>
          <Stack direction="horizontal" gap={3}>
            <Stack style={{ justifyContent: "center" }}>
              <Card.Subtitle>
                Are you sure you want to delete {userToDelete?.firstname + " " + userToDelete?.lastname}?
              </Card.Subtitle>
            </Stack>
            <Button className="app-danger-color button" onClick={() => onClickDeleteFinal()}>
              Confirm Delete
            </Button>
            <div className="vr" />
            <Button className="app-primary-color button" onClick={() => onClickCancelDelete()}>
              Cancel
            </Button>
          </Stack>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Row>
      <Col>
        <Stack gap={3}>
          {isDeleting ? (
            <>{renderDelete()}</>
          ) : isLoading ? (
            <Container className="div-main">
              <Button size="sm" className="spinner-button spinner-button-main">
                <Spinner animation="border" style={{ color: "#F6893D" }} />
              </Button>
            </Container>
          ) : (
            <>
              {users.map((user: any) => (
                <Card key={user.id} className="bg-light">
                  <Card.Body>
                    <Stack direction="horizontal" gap={3}>
                      <Stack className="cursor-pointer" onClick={(e) => editUser(user.id)}>
                        <Card.Subtitle>
                          {user.firstname} {user.preferredname ? "'" + user.preferredname + "'" : ""} {user.lastname}
                        </Card.Subtitle>
                        <Card.Subtitle className="text-muted mt-1">{user.email} </Card.Subtitle>
                      </Stack>
                      {user.cases && (
                        <Card.Subtitle className="text-muted mt-1">{user.cases?.length + " Cases"} </Card.Subtitle>
                      )}
                      <Button className="app-secondary-color button" onClick={(e) => editUser(user.id)}>
                        Edit
                      </Button>
                      <div className="vr" />
                      <Button className="app-danger-color button" onClick={(e) => onClickDelete(user)}>
                        Delete
                      </Button>
                    </Stack>
                  </Card.Body>
                </Card>
              ))}
            </>
          )}
        </Stack>
      </Col>
    </Row>
  );
};

export default UserTab;
