import React from "react";
import { Table } from "react-bootstrap";
import { ViewFilesRow } from "./ViewFilesRow";

interface ViewFilesState {
  docs: any;
  fetchDocuments: any;
  myCase: boolean;
  setIsLoading: any;
}

export const ViewFiles = ({ docs, fetchDocuments, myCase, setIsLoading }: ViewFilesState) => {
  return (
    <Table striped className="border-0">
      <thead>
        <tr>
          <th>File Name</th>
          <th>Created</th>
          <th></th>
        </tr>
      </thead>
      <tbody className="border-0">
        {docs?.map((d: any) => (
          <ViewFilesRow
            key={d.id}
            item={d}
            fetchDocuments={fetchDocuments}
            myCase={myCase}
            setIsLoading={setIsLoading}
          />
        ))}
      </tbody>
      <tfoot className="border-0">
        <tr>
          <td className="border-0"></td>
          <td className="border-0"></td>
          <td className="footnote text-muted border-0">Pdfs and Images are Viewable</td>
        </tr>
      </tfoot>
    </Table>
  );
};
