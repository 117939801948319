import React, { useEffect, useState } from "react";
import { Row, Col, Container, Stack, Button, Spinner, Card } from "react-bootstrap";
import { useParams, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  fetchRoles,
  fetchSupportTypes,
  fetchUser,
  fetchUserCases,
  logout,
  patchUserData,
  postUserData,
} from "../../services/httpClient";
import Input from "../ui/Input";
import InputPhone from "../ui/InputPhone";
import PageTitle from "../ui/PageTitle";
import SelectObject from "../ui/SelectObject";
import ToastMsg from "../ui/ToastMsg";
import { Case } from "../../types/case";
import SaveChangesModal from "../ui/SaveChangesModal";
import useApp from "../../utils/useApp";
import { unsavedChanges, currentUrl } from "../../App";

const EditUser = () => {
  const location = useLocation();
  currentUrl.value = location.pathname;
  const { id } = useParams();
  const [profile] = useSearchParams();
  const idAsNumber = id && /^\d+$/.test(id) ? parseInt(id, 10) : null;
  const { auth, setAuth, showMenu }: any = useApp();
  const [editMode] = useState(id !== undefined ? true : false);
  const [isMyUserProfile] = useState(auth?.profile?.id === idAsNumber ? true : false);
  const [role, setRole] = useState<string>();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [preferredName, setPreferredName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<number | null>(null);
  const [relationship, setRelationship] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>();
  const [isGuardian, setIsGuardian] = useState<boolean>(false);
  const [gender, setGender] = useState<string>("");
  const [race, setRace] = useState<string>("");
  const [ethnicity, setEthnicity] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [supportType, setSupportType] = useState<string>("");
  const [twofactorSetup, setTwoFactorSetup] = useState<boolean>(false);
  const [cases, setCases] = useState<Case[]>([]);
  const [showUserCases, setShowUserCases] = useState<boolean>(false);
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");
  const [isDisabling, setIsDisabling] = useState(false);
  const [isTwoFactorDisabled, setIsTwoFactorDisabled] = useState(false);
  const heading = editMode ? "Edit User" : "Create User";
  const [roles, setRoles] = useState<any[]>([]);
  const [supportTypes, setSupportTypes] = useState<any[]>([]);

  let navigate = useNavigate();

  const backUser = (e: any) => {
    if (saveIsEnabled) {
      setShowModal(true);
    } else {
      navigate(`/users`);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getRoles();
    getSupportTypes();

    if (editMode) {
      getUser();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (saveIsEnabled) {
      unsavedChanges.value = true;
    } else {
      unsavedChanges.value = false;
    }
  }, [saveIsEnabled]);

  const getRoles = () => {
    fetchRoles().then((res: any) => {
      const roleList = res[0].data;
      setRoles(roleList.filter((r: any) => r.key !== "family"));
    });
  };

  const getSupportTypes = () => {
    fetchSupportTypes().then((res: any) => {
      setSupportTypes(res[0].data);
    });
  };

  function validateEmail(email: string) {
    var regexp = /\S+@\S+\.\S+/;
    return regexp.test(email);
  }

  function validatePhone(phone: string) {
    let length = phone.length;
    return length === 11;
  }

  const getUser = () => {
    fetchUser(id)
      .then((res: any) => {
        setRole(res[0].data.role);
        setFirstName(res[0].data.firstname);
        setLastName(res[0].data.lastname);
        setPreferredName(res[0].data.preferredname);
        setEmail(res[0].data.email);
        setPhone(res[0].data.phonenumber === "" ? null : res[0].data.phonenumber);
        setRelationship(res[0].data.relationship);
        setBirthDate(res[0].data.birthdate);
        setIsGuardian(res[0].data.is_guardian);
        setGender(res[0].data.gender);
        setRace(res[0].data.race);
        setEthnicity(res[0].data.ethnicity);
        setOrganization(res[0].data.organization);
        setSupportType(res[0].data.supporttype);
        setTwoFactorSetup(res[0].data.twofactorSetup);

        getUserCases(res[0].data.id, res[0].data.role);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getUserCases = (id: number, role: string) => {
    fetchUserCases().then((res: any) => {
      var cases = res[0].data;
      var filteredCases: any[] = [];

      if (role === "support") {
        cases.forEach((c: any) => {
          c.support.forEach((s: any) => {
            if (s.user_id === id) {
              filteredCases = [...filteredCases, c];
            }
          });
        });
      } else if (role === "family") {
        cases.forEach((c: any) => {
          c.family.forEach((s: any) => {
            if (s.user_id === id) {
              filteredCases = [...filteredCases, c];
            }
          });
        });
      } else {
        filteredCases = cases.filter((c: any) => c.primaryCM?.user_id === id || c.secondaryCM?.user_id === id);
      }

      setCases(filteredCases);
    });
  };

  const saveUser = () => {
    setIsLoading(true);
    setErrMsg("");

    if (isValidEmail && isValidPhone) {
      const updatedUser = {
        role: role,
        email: email,
        firstname: firstName,
        lastname: lastName,
        preferredname: preferredName,
        phonenumber: phone,
        relationship: relationship,
        birthdate: birthDate,
        gender: gender,
        race: race,
        ethnicity: ethnicity,
        is_guardian: isGuardian,
        organization: organization,
        supporttype: supportType,
      };

      if (editMode) {
        patchUserData(updatedUser, id)
          .then((res: any) => {
            setSaveIsEnabled(false);
            setShowToast(true);
            setToastText("User Updated");
            const toast = preferredName
              ? firstName + " '" + preferredName + "' " + lastName
              : firstName + " " + lastName;
            setToastBody(toast);

            if (isMyUserProfile) {
              setAuth((prevAuth: any) => ({
                ...prevAuth,
                profile: {
                  ...prevAuth.profile,
                  firstname: firstName,
                  preferredname: preferredName,
                },
              }));
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            setErrMsg(err?.response?.data?.msg);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        postUserData(updatedUser)
          .then((res: any) => {
            setSaveIsEnabled(false);
            setShowToast(true);
            setToastText("User Created");
            const toast = preferredName
              ? firstName + " '" + preferredName + "' " + lastName
              : firstName + " " + lastName;
            setToastBody(toast);
            clearUserState();
          })
          .catch((err: any) => {
            setIsLoading(false);
            setErrMsg(err?.response?.data?.msg);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      setIsLoading(false);
      setErrMsg("Email or Phone is in Invalid Format");
    }
  };

  const clearUserState = () => {
    setRole("");
    setFirstName("");
    setLastName("");
    setPreferredName("");
    setEmail("");
    setPhone(null);
    setRelationship("");
    setBirthDate("");
    setIsGuardian(false);
    setGender("");
    setRace("");
    setEthnicity("");
    setOrganization("");
    setSupportType("");
  };

  function enableSave() {
    if (role && email && phone && isValidEmail && isValidPhone) {
      setSaveIsEnabled(true);
    } else {
      setSaveIsEnabled(false);
    }
  }

  const onClickDisable = () => {
    setIsDisabling(true);
  };

  const disableTwoFactor = () => {
    setIsLoading(true);

    const updatedUser = {
      totpsecret: "delete",
    };

    patchUserData(updatedUser, id)
      .then((res: any) => {
        setIsDisabling(false);
        setShowToast(true);
        setToastText("User Updated");
        const toast = "Two-Factor Reset";
        setToastBody(toast);
        setIsTwoFactorDisabled(true);

        if (isMyUserProfile) {
          logout()
            .then((res: any) => {
              localStorage.removeItem("auth");
              navigate("/login");
              setTwoFactorSetup(false);
              setIsTwoFactorDisabled(false);
            })
            .catch((err: any) => {
              console.log(err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          setTimeout(() => {
            setTwoFactorSetup(false);
            setIsTwoFactorDisabled(false);
            setIsLoading(false);
          }, 3000);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onClickCancelDisable = () => {
    setIsDisabling(false);
  };

  const updateRole = (e: any) => {
    setRole(e.target.value);

    if (e.target.value === "family") {
      setIsGuardian(true);
    } else {
      setIsGuardian(false);
    }

    if (e.target.value && email && phone && isValidEmail && isValidPhone) {
      setSaveIsEnabled(true);
    } else {
      setSaveIsEnabled(false);
    }
  };

  const updateFirstName = (e: any) => {
    setFirstName(e.target.value);
    enableSave();
  };

  const updateLastName = (e: any) => {
    setLastName(e.target.value);
    enableSave();
  };

  const updatePreferredName = (e: any) => {
    setPreferredName(e.target.value);
    enableSave();
  };

  const updateEmail = (e: any) => {
    setEmail(e.target.value);

    let validEmail = validateEmail(e.target.value);
    if (validEmail) {
      setIsValidEmail(true);
      if (isValidPhone) {
        if (role && phone) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      }
    } else {
      setIsValidEmail(false);
      setSaveIsEnabled(false);
    }
  };

  const updatePhone = (e: any) => {
    setPhone(e);
    let validPhone = validatePhone(e);
    if (validPhone) {
      setIsValidPhone(true);
      if (isValidEmail) {
        if (role && email) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      }
    } else {
      setIsValidPhone(false);
      setSaveIsEnabled(false);
    }
  };

  const updateOrganization = (e: any) => {
    setOrganization(e.target.value);
    enableSave();
  };

  const updateSupportType = (e: any) => {
    setSupportType(e.target.value);
    enableSave();
  };

  const renderDisableTwoFactor = () => {
    return (
      <Col className="col-8">
        <Stack direction="horizontal" gap={3}>
          <Stack style={{ justifyContent: "center" }}>
            <Card.Subtitle>
              Are you sure you want to reset two-factor for {firstName} {lastName}?{" "}
              {isMyUserProfile ? "This will log you out." : ""}
            </Card.Subtitle>
          </Stack>
          <Button className="app-danger-color button" onClick={() => disableTwoFactor()}>
            Confirm Reset
          </Button>
          <div className="vr" />
          <Button className="app-primary-color button" onClick={() => onClickCancelDisable()}>
            Cancel
          </Button>
        </Stack>
      </Col>
    );
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          {profile.get("profile") !== "true" ? (
            <>
              <Row className="mb-3">
                <Col className="col-6 text-start">
                  <Button className={"app-primary-color button"} onClick={(e) => backUser(e)}>
                    {"Back to Users"}
                  </Button>
                </Col>
                <Col className="col-6 text-end">
                  <Button
                    id="pagetitle-button"
                    className={"app-secondary-color button"}
                    onClick={saveUser}
                    disabled={!saveIsEnabled}
                  >
                    {"Save"}
                  </Button>
                </Col>
              </Row>
              <PageTitle title={heading} />
            </>
          ) : (
            <PageTitle
              title={heading}
              buttonTitle="Save"
              onButtonClick={saveUser}
              buttonDisabled={!saveIsEnabled}
              buttonColor="secondary"
            />
          )}
          <Stack gap={3}>
            <Row>
              <Col className="col-4">
                <SelectObject
                  label="Role"
                  value={role}
                  options={roles}
                  onUpdate={(e: any) => updateRole(e)}
                  disabled={auth?.profile?.role === "admin" ? false : true}
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-4">
                <Input label="First Name" type="text" value={firstName} onUpdate={(e: any) => updateFirstName(e)} />
              </Col>
              <Col className="col-4">
                <Input label="Last Name" type="text" value={lastName} onUpdate={(e: any) => updateLastName(e)} />
              </Col>
            </Row>
            <Row>
              <Col className="col-4">
                <Input label="Email" type="email" value={email} onUpdate={(e: any) => updateEmail(e)} required={true} />
              </Col>
              <Col className="col-4">
                <InputPhone label="Phone" value={phone} onUpdate={(e: any) => updatePhone(e)} required={true} />
              </Col>
            </Row>
            <Row>
              <Col className="col-4">
                <Input
                  label="Preferred Name"
                  type="text"
                  value={preferredName}
                  onUpdate={(e: any) => updatePreferredName(e)}
                />
              </Col>
              {role === "support" && (
                <Col className="col-4">
                  <Input
                    label="Organization"
                    type="text"
                    value={organization}
                    onUpdate={(e: any) => updateOrganization(e)}
                  />
                </Col>
              )}
            </Row>
            {role === "support" && (
              <Row>
                <Col className="col-4">
                  <SelectObject
                    label="Support Type"
                    value={supportType}
                    options={supportTypes}
                    onUpdate={(e: any) => updateSupportType(e)}
                  />
                </Col>
              </Row>
            )}
            {role !== "family" && editMode && (
              <Row className="mt-1">
                {twofactorSetup ? (
                  isDisabling ? (
                    <>{renderDisableTwoFactor()}</>
                  ) : (
                    <Col className="col-4">
                      <Button className="app-tertiary-color me-3 button" onClick={(e) => onClickDisable()}>
                        Reset Two-Factor Authentication
                      </Button>
                    </Col>
                  )
                ) : (
                  <Col className="col-4">
                    <h5 className="m-0 p-0 successmsg ">Two-Factor Not Yet Configured</h5>
                  </Col>
                )}
              </Row>
            )}
            {role !== "family" && (
              <>
                <Row className="mt-1">
                  <Col className="col-4">
                    <Button
                      className="app-tertiary-color me-3 button"
                      onClick={(e) => setShowUserCases(!showUserCases)}
                    >
                      {showUserCases ? "Hide User Cases" : "View User Cases"}
                    </Button>
                  </Col>
                </Row>
                {showUserCases && (
                  <>
                    {cases.length > 0 ? (
                      <>
                        <h4 className="app-header-text mt-2">User Cases</h4>
                        <Row>
                          {cases.map((c: any) => (
                            <Col key={c.id} className="col-4 mb-2">
                              <Card key={c.name} className="bg-light">
                                <Card.Body>
                                  <Stack direction="horizontal" gap={3}>
                                    <Stack gap={2}>
                                      <Card.Subtitle>{c.name}</Card.Subtitle>
                                      <Card.Subtitle className="text-muted">{c.status}</Card.Subtitle>
                                    </Stack>
                                  </Stack>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </>
                    ) : (
                      <h6>No User Cases</h6>
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        </Container>
      )}
      {isTwoFactorDisabled && isMyUserProfile && (
        <Row className="pt-5 mt-5">
          <Col>
            <h4 className="p-3 text-center successmsg ">Logging Out!</h4>
          </Col>
        </Row>
      )}
      <SaveChangesModal showModal={showModal} setShowModal={setShowModal} path="/users" />
    </>
  );
};

export default EditUser;
