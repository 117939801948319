import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { InteractionsBarChart } from "../report-ui/InteractionsBarChart";
import { SingleCardMetric } from "../report-ui/SingleCardMetric";
import ReportTimeInterval from "../report-ui/ReportTimeInterval";
import { fetchReportInteractions } from "../../services/httpClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import html2canvas from "html2canvas";

const InteractionTab = ({ buildPDF, canvas, setCanvas }: any) => {
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [dateInterval, setDateInterval] = useState<number>(365);
  const [total, setTotal] = useState<number>(0);
  const [standard, setStandard] = useState<number>(0);
  const [contact, setContact] = useState<number>(0);
  const [intervention, setIntervention] = useState<number>(0);
  const [referral, setReferral] = useState<number>(0);
  const [student, setStudent] = useState<number>(0);
  const [barData, setBarData] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);

  const [reportRunning, setReportRunning] = useState<boolean>(false);

  useEffect(() => {
    const start = new Date();
    start.setDate(start.getDate() - dateInterval);
    setStartDate(start.toISOString().slice(0, 10));
    setEndDate(new Date().toISOString().slice(0, 10));
    getInteractions(start.toISOString().slice(0, 10), new Date().toISOString().slice(0, 10));
  }, []);

  useEffect(() => {
    if (reportRunning) {
      buildPDF();
      setReportRunning(false);
    }
  }, [canvas]);

  const getInteractions = (start: string, end: string) => {
    fetchReportInteractions(start, end)
      .then((res: any) => {
        const interactions = res[0].data;
        setTotal(interactions.length);
        setStandard(interactions.filter((i: any) => i.name === "Standard").length);
        setContact(interactions.filter((i: any) => i.name === "Contact").length);
        setIntervention(interactions.filter((i: any) => i.name === "Intervention Follow-Up").length);
        setReferral(interactions.filter((i: any) => i.name === "Referral").length);
        setStudent(interactions.filter((i: any) => i.name === "Student Connections").length);

        //use interactions to get the bar data
        const barData = interactions.reduce((groups: any, item: any) => {
          const { created_by, firstname, lastname } = item;

          if (!groups[created_by]) {
            groups[created_by] = {
              created_by,
              firstname,
              lastname,
              count: 0,
            };
          }

          groups[created_by].count++;

          return groups;
        }, {});

        setBarData(Object.values(barData));

        //use interactions to get the table data
        const tableData = interactions.reduce((groups: any, item: any) => {
          const { created_by, name, firstname, lastname } = item;

          if (!groups[created_by]) {
            groups[created_by] = {
              created_by,
              firstname,
              lastname,
              totals: {},
            };
          }

          if (!groups[created_by].totals[name]) {
            groups[created_by].totals[name] = 0;
          }

          groups[created_by].totals[name]++;

          return groups;
        }, {});

        const allNames: string[] = ["Standard", "Contact", "Intervention Follow-Up", "Referral", "Student Connections"];

        for (const created_by in tableData) {
          for (const name of allNames) {
            if (!tableData[created_by].totals[name]) {
              tableData[created_by].totals[name] = 0;
            }
          }
        }

        const resultArray = Object.values(tableData);
        setTableData(resultArray);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const downloadReport = async () => {
    setReportRunning(true);
    const reportSection = document.getElementById("interaction-section");
    const reportTitle = document.getElementById("interaction-title");
    const refreshButton = document.getElementById("interaction-refresh-button");
    const barChart = document.getElementById("interaction-bar");

    if (reportSection) {
      if (reportTitle) {
        reportTitle.style.visibility = "hidden";
        reportTitle.style.display = "none";
      }
      if (refreshButton) refreshButton.style.visibility = "hidden";
      if (barChart) {
        barChart.style.visibility = "hidden";
        barChart.style.display = "none";
      }

      const images = reportSection.getElementsByTagName("img");
      for (let i = 0; i < images.length; i++) {
        images[i].crossOrigin = "anonymous";
      }

      const canvas = await html2canvas(reportSection, { useCORS: true });
      setCanvas(canvas);

      if (reportTitle) {
        reportTitle.style.visibility = "visible";
        reportTitle.style.display = "flex";
      }
      if (refreshButton) refreshButton.style.visibility = "visible";
      if (barChart) {
        barChart.style.visibility = "visible";
        barChart.style.display = "flex";
      }
    } else {
      console.error("Report section not found");
      setReportRunning(false);
    }
  };

  return (
    <>
      <Container id="interaction-section" className="lg vstack gap-3">
        <Row id="interaction-title">
          <Col className="col-8">
            <h4 className="hero-heading app-header-text">Interactions</h4>
          </Col>
          <Col className="text-end col-4">
            <Button className="app-primary-color button" onClick={() => downloadReport()}>
              <FontAwesomeIcon icon={faDownload} />
              {" Report"}
            </Button>
          </Col>
        </Row>
        <ReportTimeInterval
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          dateInterval={dateInterval}
          setDateInterval={setDateInterval}
          intervalOne={30}
          intervalOneText={"30 Days"}
          intervalTwo={90}
          intervalTwoText={"90 Days"}
          intervalThree={180}
          intervalThreeText={"180 Days"}
          intervalFour={365}
          intervalFourText={"Last Year"}
          onClickRefresh={getInteractions}
          hideBtnId="interaction-refresh-button"
        />
        <Row className="mb-3 mt-3">
          <Col className="mb-3">
            <SingleCardMetric title="Total" total={total} />
          </Col>
          <Col className="mb-3">
            <SingleCardMetric title="Standard" total={standard} />
          </Col>
          <Col className="mb-3">
            <SingleCardMetric title="Contact" total={contact} />
          </Col>
          <Col className="mb-3">
            <SingleCardMetric title="Intervention" total={intervention} />
          </Col>
          <Col className="mb-3">
            <SingleCardMetric title="Referral" total={referral} />
          </Col>
          <Col className="mb-3">
            <SingleCardMetric title="Student" total={student} />
          </Col>
        </Row>
        <Row id="interaction-bar" className="mb-3">
          <Col className="col-12">
            <InteractionsBarChart data={barData} />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="col-10">
            <Table striped>
              <thead>
                <tr>
                  <th>Case Manager</th>
                  <th>Total</th>
                  <th>Standard</th>
                  <th>Contact</th>
                  <th>Intervention</th>
                  <th>Referral</th>
                  <th>Student</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((d: any) => (
                  <tr key={d.created_by}>
                    <td>{d.firstname + " " + d.lastname}</td>
                    <td>
                      {d.totals.Standard +
                        d.totals.Contact +
                        d.totals["Intervention Follow-Up"] +
                        d.totals.Referral +
                        d.totals["Student Connections"]}
                    </td>
                    <td>{d.totals.Standard}</td>
                    <td>{d.totals.Contact}</td>
                    <td>{d.totals["Intervention Follow-Up"]}</td>
                    <td>{d.totals.Referral}</td>
                    <td>{d.totals["Student Connections"]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InteractionTab;
