import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface InputPhoneState {
  label: string;
  value: any;
  onUpdate: any;
  disabled?: any;
  required?: boolean;
}

const InputPhone = ({ label, value, onUpdate, disabled, required = false }: InputPhoneState) => {
  return (
    <Stack direction="horizontal">
      <OverlayTrigger placement="top" delay={{ show: 150, hide: 250 }} overlay={<Tooltip>Required</Tooltip>}>
        <PhoneInput
          countryCodeEditable={false}
          country={"us"}
          onlyCountries={["us"]}
          disableDropdown={true}
          placeholder={label}
          value={value}
          onChange={onUpdate}
          disabled={disabled}
          containerClass="form-floating"
          inputClass="phone-input w-100"
        />
      </OverlayTrigger>
      {required && (
        <FontAwesomeIcon
          className="app-tertiary-color-inverse"
          icon={faAsterisk}
          style={{
            position: "relative",
            right: 15,
            top: -20,
            zIndex: 1,
            fontSize: 11,
          }}
        />
      )}
    </Stack>
  );
};

export default InputPhone;
