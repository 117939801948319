import React from "react";
import { Container, Stack } from "react-bootstrap";

const MyCaseTab = ({ component }: any) => {
  return (
    <Container className="lg">
      <Stack gap={3}>{component}</Stack>
    </Container>
  );
};

export default MyCaseTab;
