import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

interface SelectState {
  label: string;
  value: any;
  options: any;
  onUpdate: any;
  showFirst?: boolean;
}

const Select = ({ label, value, options, onUpdate, showFirst = true }: SelectState) => {
  return (
    <FloatingLabel controlId="floatingInput" label={label}>
      <Form.Select className="decorated" value={value} onChange={onUpdate}>
        {showFirst && <option key={0}>Select from the following</option>}
        {options.map((i: any) => (
          <option key={i} value={i}>
            {i}
          </option>
        ))}
      </Form.Select>
    </FloatingLabel>
  );
};
export default Select;
