import React, { useEffect, useState } from "react";
import { Group } from "../../types/group";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import ToastMsg from "../ui/ToastMsg";
import PageTitle from "../ui/PageTitle";
import { deleteGroup, fetchGroups } from "../../services/httpClient";
import useApp from "../../utils/useApp";

const AllGroups = () => {
  const { showMenu }: any = useApp();
  const [filteredGroups, setFilteredGroups] = useState<Group[]>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [groupToDelete, setGroupToDelete] = useState<Group>();
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  let navigate = useNavigate();

  const editGroup = (e: any) => {
    navigate(`/groups/group/${e}`);
  };

  const newGroup = () => {
    navigate(`/groups/group`);
  };

  const newGroupMeeting = (e: any) => {
    navigate(`/groups/group/${e}/meeting`);
  };

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = () => {
    setIsLoading(true);

    fetchGroups()
      .then((res: any) => {
        const groups = res[0].data;
        setFilteredGroups(groups);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onClickDelete = (e: any) => {
    setIsDeleting(true);
    setGroupToDelete(e);
  };

  const onClickDeleteFinal = () => {
    setIsLoading(true);
    setIsDeleting(false);
    const groupName: string = groupToDelete?.name ? groupToDelete.name : "";

    deleteGroup(groupToDelete?.id)
      .then((res: any) => {
        getGroups();
        setShowToast(true);
        setToastText("Group Deleted");
        setToastBody(groupName);
        setGroupToDelete(undefined);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onClickCancelDelete = () => {
    setIsDeleting(false);
    setGroupToDelete(undefined);
  };

  const renderDelete = () => {
    return (
      <Card className="bg-light">
        <Card.Body>
          <Stack direction="horizontal" gap={3}>
            <Stack style={{ justifyContent: "center" }}>
              <Card.Subtitle>Are you sure you want to delete {groupToDelete?.name}?</Card.Subtitle>
            </Stack>
            <Button className="app-danger-color button" onClick={() => onClickDeleteFinal()}>
              Confirm Delete
            </Button>
            <div className="vr" />
            <Button className="app-primary-color button" onClick={() => onClickCancelDelete()}>
              Cancel
            </Button>
          </Stack>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <PageTitle
            title={"Groups (" + filteredGroups.length + ")"}
            buttonTitle="Create Group"
            onButtonClick={newGroup}
            descriptionText="The group function is used to record attendance at meetings and any special notes for participants. Notes are saved in a participant's case."
          />
          <Row className="pb-5 mt-2">
            <Col className="pe-0">
              <Stack gap={3}>
                {isDeleting ? (
                  <>{renderDelete()}</>
                ) : (
                  filteredGroups.map((item: any, index: any) => (
                    <Card key={index} className="bg-light">
                      <Card.Body>
                        <Stack direction="horizontal" gap={3}>
                          <Stack className="cursor-pointer" onClick={(e) => editGroup(item.id)}>
                            <Card.Subtitle>{item.name}</Card.Subtitle>
                            <Card.Subtitle className="text-muted mt-1">{item?.description}</Card.Subtitle>
                          </Stack>
                          <Button className="app-tertiary-color button" onClick={(e) => newGroupMeeting(item.id)}>
                            Meeting
                          </Button>
                          <div className="vr" />
                          <Button className="app-secondary-color button" onClick={(e) => editGroup(item.id)}>
                            Edit
                          </Button>
                          <div className="vr" />
                          <Button className="app-danger-color button" onClick={(e) => onClickDelete(item)}>
                            Delete
                          </Button>
                        </Stack>
                      </Card.Body>
                    </Card>
                  ))
                )}
              </Stack>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default AllGroups;
