import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, FloatingLabel, Alert, Spinner, Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchUserSecret, logout, verifyTwoFactor } from "../../services/httpClient";
import QRCode from "react-qr-code";
import useApp from "../../utils/useApp";

const TwoFactorSetup = () => {
  const { auth, setAuth }: any = useApp();
  const [secretUrl, setSecretUrl] = useState("");
  const [secret, setSecret] = useState("");
  const [code, setCode] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccessfulSetup, setIsSuccessfulSetup] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  let navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    fetchUserSecret()
      .then((res: any) => {
        const sec = res[0].data.secret;
        setSecret(sec);
        const environment = process.env.REACT_APP_ENVIRONMENT;
        const envSuffix = environment != "Prod" ? "-" + environment : "";
        setSecretUrl(
          "otpauth://totp/SelfLyft" +
            envSuffix +
            ":" +
            auth?.profile?.email +
            "?secret=" +
            sec +
            "&issuer=SelfLyft" +
            envSuffix +
            ""
        );
      })
      .catch((err: any) => {})
      .finally(() => {
        setIsLoading(false);
        inputRef?.current?.focus();
      });
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    verifyTwoFactor(code, secret)
      .then((res: any) => {
        setIsSuccessfulSetup(true);
        setIsLoading(true);

        setTimeout(() => {
          setAuth({ ...auth, twofactor: true });
          navigate("/");
          setIsLoading(false);
        }, 3000);
      })
      .catch((err: any) => {
        var _attempts = attempts + 1;
        setAttempts(_attempts);
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err?.response?.status === 401) {
          setErrMsg("Invalid Code. Attempt " + _attempts + "/3");
          if (_attempts === 3) {
            setTimeout(() => {
              handleLogout();
            }, 1000);
          }
        } else {
          setErrMsg("Login Failed");
        }
      });
  };

  const handleLogout = () => {
    logout()
      .then((res: any) => {
        localStorage.removeItem("auth");
        navigate("/login");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <div className="h-100 app-primary-alt-color align-content-center">
      {isLoading ? (
        <Row className="w-100 pb-5 d-flex align-item-center justify-content-center m-0">
          <Col xs={10} sm={8} md={6} lg={5} xl={4} xxl={4} className="pb-5 text-center">
            <Button size="sm" className="spinner-button">
              <Spinner animation="border" style={{ color: "#F6893D" }} />
            </Button>
          </Col>
        </Row>
      ) : (
        <Row className="w-100 pb-5 d-flex align-item-center justify-content-center m-0">
          <Col xs={10} sm={8} md={6} lg={5} xl={4} xxl={4} className="pb-5">
            <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
              {errMsg}
            </p>
            <Card className="shadow-sm rounded-lg d-block">
              <Image src="/AndGo_Logo_Color_Horizontal.png" fluid className="pt-5 px-5 pb-5" />
              <Form onSubmit={handleSubmit}>
                <h4 className="text-center app-header-text">Setup Two-Factor Authentication</h4>
                <h6 className="text-center text-secondary mb-3 mx-3">Scan QR Code in Authenticator App </h6>
                <div className="text-center mb-3">
                  <QRCode value={secretUrl} />
                </div>
                <h6 className="p-1 text-center text-secondary ">Verify Code </h6>
                <FloatingLabel label="Code" className="mx-3 mb-3 app-header-text">
                  <Form.Control
                    type="text"
                    placeholder="Code"
                    id="user"
                    onChange={(e) => {
                      setCode(e.target.value);
                      setErrMsg("");
                    }}
                    value={code}
                    ref={inputRef}
                    required
                  />
                </FloatingLabel>
                <div className="d-grid w-100">
                  <Button className="button app-tertiary-color text-center mx-3 p-3" type="submit">
                    Activate
                  </Button>
                </div>
                <Alert key="light" variant="light" className="p-2 text-center">
                  <Alert.Link onClick={handleLogout}>Back to login</Alert.Link>
                </Alert>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
      {isSuccessfulSetup && (
        <Row className="pt-5 mt-5">
          <Col>
            <h4 className="p-3 text-center text-white ">Two-Factor Successfully Enabled!</h4>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TwoFactorSetup;
