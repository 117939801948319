import React, { createRef, useEffect, useState } from "react";
import { Button, Col, Container, Navbar, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faChartColumn, faFolderOpen, faHouse, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import useApp from "../../utils/useApp";
import SaveChangesModal from "./SaveChangesModal";
import { unsavedChanges } from "../../App";

interface MainHeaderMenuState {
  activeMyCases: boolean;
  activeUsers: boolean;
  activeCases: boolean;
  activeGroups: boolean;
  activeAnnouncements: boolean;
  activeReports: boolean;
  routeChange: any;
}

const MainHeaderMenu = ({
  activeMyCases,
  activeUsers,
  activeCases,
  activeGroups,
  activeAnnouncements,
  activeReports,
  routeChange,
}: MainHeaderMenuState) => {
  const [isSmediumScreen, setIsSmediumScreen] = useState(window.innerWidth < 992);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const { auth }: any = useApp();
  const myCaseBtnRef = createRef<HTMLButtonElement>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pendingRoute, setPendingRoute] = useState<string>("");

  useEffect(() => {
    const handleResize = () => {
      setIsSmediumScreen(window.innerWidth < 992);
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRouteChange = (route: string) => {
    if (unsavedChanges.value === true) {
      setShowModal(true);
      setPendingRoute(route);
    } else {
      routeChange(route);
    }
  };

  return (
    <>
      <Navbar className="nav-action">
        <Container className="custom-container">
          <Row className="w-100 d-flex justify-content-center">
            <Col className="col-2 custom-col-width d-flex justify-content-center">
              <Button
                ref={myCaseBtnRef}
                className={
                  activeMyCases ? "button-nav app-tertiary-color" : "button-nav app-primary-color-inverse mx-0"
                }
                onClick={() => handleRouteChange("/my-cases")}
              >
                {!isSmallScreen ? (
                  <>
                    <FontAwesomeIcon icon={faHouse} className="me-2 fa-nav" />
                    My Cases
                  </>
                ) : (
                  <FontAwesomeIcon icon={faHouse} />
                )}
              </Button>
            </Col>
            {auth?.admin === true && (
              <Col className="col-2 custom-col d-flex justify-content-center">
                <Button
                  className={
                    activeUsers ? "button-nav app-tertiary-color" : "button-nav app-primary-color-inverse mx-0"
                  }
                  onClick={() => handleRouteChange("/users")}
                >
                  {!isSmallScreen ? (
                    <>
                      <FontAwesomeIcon icon={faUser} className="me-2 fa-nav" />
                      Users
                    </>
                  ) : (
                    <FontAwesomeIcon icon={faUser} />
                  )}
                </Button>
              </Col>
            )}
            {auth?.admin === true && (
              <Col className="col-2 custom-col d-flex justify-content-center">
                <Button
                  className={
                    activeCases ? "button-nav app-tertiary-color" : "button-nav app-primary-color-inverse mx-0"
                  }
                  onClick={() => handleRouteChange("/cases")}
                >
                  {!isSmallScreen ? (
                    <>
                      <FontAwesomeIcon icon={faFolderOpen} className="me-2 fa-nav" />
                      Cases
                    </>
                  ) : (
                    <FontAwesomeIcon icon={faFolderOpen} />
                  )}
                </Button>
              </Col>
            )}
            <Col className="col-2 custom-col d-flex justify-content-center">
              <Button
                className={activeGroups ? "button-nav app-tertiary-color" : "button-nav app-primary-color-inverse mx-0"}
                onClick={() => handleRouteChange("/groups")}
              >
                {!isSmallScreen ? (
                  <>
                    <FontAwesomeIcon icon={faUsers} className="me-2 fa-nav" />
                    Groups
                  </>
                ) : (
                  <FontAwesomeIcon icon={faUsers} />
                )}
              </Button>
            </Col>
            <Col className="col-2 custom-col d-flex justify-content-center">
              <Button
                className={
                  activeAnnouncements ? "button-nav app-tertiary-color" : "button-nav app-primary-color-inverse mx-0"
                }
                onClick={() => handleRouteChange("/announcements")}
              >
                {!isSmallScreen ? (
                  <>
                    <FontAwesomeIcon icon={faBullhorn} className="me-2 fa-nav" />
                    {isSmediumScreen ? "Announce" : "Announcements"}
                  </>
                ) : (
                  <FontAwesomeIcon icon={faBullhorn} />
                )}
              </Button>
            </Col>
            {auth?.admin === true && (
              <Col className="col-2 custom-col d-flex justify-content-center">
                <Button
                  className={
                    activeReports ? "button-nav app-tertiary-color" : "button-nav app-primary-color-inverse mx-0"
                  }
                  onClick={() => handleRouteChange("/reports")}
                >
                  {!isSmallScreen ? (
                    <>
                      <FontAwesomeIcon icon={faChartColumn} className="me-2 fa-nav" />
                      Reports
                    </>
                  ) : (
                    <FontAwesomeIcon icon={faChartColumn} />
                  )}
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </Navbar>
      <SaveChangesModal showModal={showModal} setShowModal={setShowModal} path={pendingRoute} />
    </>
  );
};

export default MainHeaderMenu;
