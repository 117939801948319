import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import ReportTimeInterval from "../report-ui/ReportTimeInterval";
import { SingleCardMetric } from "../report-ui/SingleCardMetric";
import { fetchReportGoals, fetchReportTasks } from "../../services/httpClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import html2canvas from "html2canvas";

const GoalsAndTasksTab = ({ buildPDF, canvas, setCanvas }: any) => {
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [dateInterval, setDateInterval] = useState<number>(365);
  const [goalData, setGoalData] = useState<any>([]);
  const [taskData, setTaskData] = useState<any>([]);

  const [reportRunning, setReportRunning] = useState<boolean>(false);

  useEffect(() => {
    const start = new Date();
    start.setDate(start.getDate() - dateInterval);
    setStartDate(start.toISOString().slice(0, 10));
    setEndDate(new Date().toISOString().slice(0, 10));
    getGoalsAndTasks(start.toISOString().slice(0, 10), new Date().toISOString().slice(0, 10));
  }, []);

  useEffect(() => {
    if (reportRunning) {
      buildPDF();
      setReportRunning(false);
    }
  }, [canvas]);

  const getGoalsAndTasks = (start: string, end: string) => {
    fetchReportGoals(start, end)
      .then((res: any) => {
        const goals = res[0].data;
        setGoalData(goals);

        fetchReportTasks(start, end).then((res: any) => {
          const tasks = res[0].data;
          setTaskData(tasks);
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const goalHeaders = [
    { label: "ID", key: "id" },
    { label: "Goal Name", key: "goal_name" },
    { label: "Completed Date", key: "completed_at" },
    { label: "Case ID", key: "case_id" },
    { label: "Case Name", key: "case_name" },
  ];

  const taskHeaders = [
    { label: "ID", key: "id" },
    { label: "Task Name", key: "task_name" },
    { label: "Completed Date", key: "cm_reviewed_at" },
    { label: "Goal ID", key: "goal_id" },
    { label: "Goal Name", key: "goal_name" },
    { label: "Case ID", key: "case_id" },
    { label: "Case Name", key: "case_name" },
  ];

  const downloadReport = async () => {
    setReportRunning(true);
    const reportSection = document.getElementById("goals-section");
    const reportTitle = document.getElementById("goals-title");
    const refreshButton = document.getElementById("goals-refresh-button");
    const downloadGoalsButton = document.getElementById("download-goals-button");
    const downloadTasksButton = document.getElementById("download-tasks-button");

    if (reportSection) {
      if (reportTitle) {
        reportTitle.style.visibility = "hidden";
        reportTitle.style.display = "none";
      }
      if (refreshButton) refreshButton.style.visibility = "hidden";
      if (downloadGoalsButton) downloadGoalsButton.style.visibility = "hidden";
      if (downloadTasksButton) downloadTasksButton.style.visibility = "hidden";

      const images = reportSection.getElementsByTagName("img");
      for (let i = 0; i < images.length; i++) {
        images[i].crossOrigin = "anonymous";
      }

      const canvas = await html2canvas(reportSection, { useCORS: true });
      setCanvas(canvas);

      if (reportTitle) {
        reportTitle.style.visibility = "visible";
        reportTitle.style.display = "flex";
      }
      if (refreshButton) refreshButton.style.visibility = "visible";
      if (downloadGoalsButton) downloadGoalsButton.style.visibility = "visible";
      if (downloadTasksButton) downloadTasksButton.style.visibility = "visible";
    } else {
      console.error("Report section not found");
      setReportRunning(false);
    }
  };

  return (
    <>
      <Container id="goals-section" className="lg vstack gap-3">
        <Row id="goals-title" className="mb-2">
          <Col className="col-8">
            <h4 className="hero-heading app-header-text">Goals and Tasks</h4>
          </Col>
          <Col className="text-end col-4">
            <Button className="app-primary-color button" onClick={() => downloadReport()}>
              <FontAwesomeIcon icon={faDownload} />
              {" Report"}
            </Button>
          </Col>
        </Row>
        <ReportTimeInterval
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          dateInterval={dateInterval}
          setDateInterval={setDateInterval}
          intervalOne={30}
          intervalOneText={"30 Days"}
          intervalTwo={90}
          intervalTwoText={"90 Days"}
          intervalThree={180}
          intervalThreeText={"180 Days"}
          intervalFour={365}
          intervalFourText={"Last Year"}
          onClickRefresh={getGoalsAndTasks}
          hideBtnId="goals-refresh-button"
        />
        <Row className="mt-3 mb-3">
          <Col></Col>
          <Col className="col-3">
            <SingleCardMetric title="Goals Completed" total={goalData.length} />
          </Col>
          <Col className="col-3">
            <SingleCardMetric title="Tasks Completed" total={taskData.length} />
          </Col>
          <Col></Col>
        </Row>
        {goalData.length > 0 && (
          <>
            <Row>
              <Col>
                <h5 className="app-header-text">Goals Completed</h5>
              </Col>
              <Col className="text-end">
                <CSVLink
                  id="download-goals-button"
                  data={goalData}
                  headers={goalHeaders}
                  filename={"goals.csv"}
                  className="btn button app-tertiary-color"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faDownload} />
                </CSVLink>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-12">
                <Table striped>
                  <thead>
                    <tr>
                      <th>Case Name</th>
                      <th>Goal</th>
                      <th>Completed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {goalData?.map((d: any) => (
                      <tr key={d.id}>
                        <td>{d.case_name}</td>
                        <td>{d.goal_name}</td>
                        <td>{d.completed_at}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
        {taskData.length > 0 && (
          <>
            <Row>
              <Col>
                <h5 className="app-header-text">Tasks Completed</h5>
              </Col>
              <Col className="text-end">
                <CSVLink
                  id="download-tasks-button"
                  data={taskData}
                  headers={taskHeaders}
                  filename={"tasks.csv"}
                  className="btn button app-tertiary-color"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faDownload} />
                </CSVLink>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col className="col-12">
                <Table striped>
                  <thead>
                    <tr>
                      <th>Case Name</th>
                      <th>Task</th>
                      <th>Goal</th>
                      <th>Completed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {taskData?.map((d: any) => (
                      <tr key={d.id}>
                        <td>{d.case_name}</td>
                        <td>{d.task_name}</td>
                        <td>{d.goal_name}</td>
                        <td>{d.cm_reviewed_at}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default GoalsAndTasksTab;
