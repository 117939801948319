import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import React from "react";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { deleteNoteDocument, fetchNoteDocumentDownload } from "../../../services/httpClient";
import { Note } from "../../../types/note";

interface NoteFilesState {
  documents: any;
  setDocuments: any;
  note?: Note;
  editNote?: boolean;
}

export const ViewNoteFiles = ({ documents, setDocuments, note, editNote = false }: NoteFilesState) => {
  const { id } = useParams();

  const onClickDownload = (docId: string, docName: string) => {
    fetchNoteDocumentDownload(id, note?.id, docId).then((res: any) => {
      FileSaver.saveAs(res[0].data, docName);
    });
  };

  const onClickDelete = (docId: string, docName: string) => {
    setDocuments(
      documents.filter((d: any) => {
        return d.name !== docName;
      })
    );

    if (editNote) {
      deleteNoteDocument(id, note?.id, docId).then((res: any) => {});
    }
  };

  return (
    <Table striped>
      <tbody>
        {documents?.map((d: any) => (
          <tr key={d.id}>
            <td>{d.name}</td>
            <td className="text-end">
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Download</Tooltip>}>
                <Button className="me-3 app-secondary-color button" onClick={() => onClickDownload(d.id, d.name)}>
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Delete</Tooltip>}>
                <Button className="app-danger-color button" onClick={() => onClickDelete(d.id, d.name)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </OverlayTrigger>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
