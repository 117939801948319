import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const ContinuumLineChart = ({ assessData, titleText = "Overall Continuum" }: any) => {
  const yLabels = ["", "In-Crisis", "", "At-Risk", "", "Safe", "", "Stable", "", "Thriving"];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: titleText,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 10,
        ticks: {
          autoSkip: false,
          callback: function (value: any, index: any, values: any) {
            return yLabels[index]; // Display the predefined string labels
          },
        },
      },
      x: {
        ticks: {
          autoSkip: false, // Prevent automatic skipping of labels
          maxRotation: 30, // Rotate labels at a 45-degree angle
          minRotation: 30, // Also set the minimum rotation angle
        },
      },
    },
  };

  const chartData = {
    labels: assessData.map((item: any) => item.date),
    datasets: [
      {
        label: "Score",
        data: assessData.map((item: any) => yLabels.indexOf(item.score)),
        borderColor: "rgb(218 , 126 , 57)",
        backgroundColor: "rgba(218 , 126 , 57, 0.5)",
      },
    ],
  };

  return (
    <div>
      <Line options={options} data={chartData} />
    </div>
  );
};
