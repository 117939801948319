import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useApp from "../../utils/useApp";

const RequireAuth = () => {
  const { auth }: any = useApp();
  const location = useLocation();

  return auth?.profile ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
