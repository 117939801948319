import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  fetchEthnicities,
  fetchGenders,
  fetchRaces,
  fetchRelationships,
  patchMember,
  postMember,
} from "../../../services/httpClient";
import DatePicker from "../../ui/DatePicker";
import Input from "../../ui/Input";
import InputPhone from "../../ui/InputPhone";
import SelectObject from "../../ui/SelectObject";
import ConfirmDelete from "./ConfirmDelete";

interface EditMemberState {
  user?: any;
  fetchHousehold: any;
  resetHouseholdTab: any;
  type?: string;
  setUserToSave: any;
  onClickDoneEdit: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  myCase: boolean;
}

const EditMember = ({
  user,
  fetchHousehold,
  resetHouseholdTab,
  type,
  setUserToSave,
  onClickDoneEdit,
  setShowToast,
  setToastText,
  setToastBody,
  myCase,
}: EditMemberState) => {
  const { id } = useParams();
  const [editMode] = useState(type !== undefined ? true : false);
  const heading = editMode ? "Edit " + type : "Create Member";
  const [memberId, setMemberId] = useState<string>();
  const [firstName, setFirstName] = useState<string | undefined>("");
  const [lastName, setLastName] = useState<string>("");
  const [preferredName, setPreferredName] = useState<string>("");
  const [phone, setPhone] = useState<number | null>(null);
  const [relationship, setRelationship] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>();
  const [school, setSchool] = useState<string>("");
  const [grade, setGrade] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [race, setRace] = useState<string>("");
  const [ethnicity, setEthnicity] = useState<string>("");
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const [errMsg, setErrMsg] = useState("");
  const [relationships, setRelationships] = useState<any[]>([]);
  const [genders, setGenders] = useState<any[]>([]);
  const [races, setRaces] = useState<any[]>([]);
  const [ethnicities, setEthnicities] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<string>();

  const bottomRef = useRef();

  const handleScroll = (ref: any) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
    }
  };

  useEffect(() => {
    getRelationships();
    getGenders();
    getRaces();
    getEthnicities();
    handleScroll(bottomRef);
  }, []);

  const getRelationships = () => {
    fetchRelationships().then((res: any) => {
      setRelationships(res[0].data);
    });
  };

  const getGenders = () => {
    fetchGenders().then((res: any) => {
      setGenders(res[0].data);
    });
  };

  const getRaces = () => {
    fetchRaces().then((res: any) => {
      setRaces(res[0].data);
    });
  };

  const getEthnicities = () => {
    fetchEthnicities().then((res: any) => {
      setEthnicities(res[0].data);
    });
  };

  useEffect(() => {
    if (editMode) {
      setMemberId(user?.id);
      setFirstName(user?.firstname);
      setLastName(user?.lastname);
      setPreferredName(user?.preferredname);
      setPhone(user?.phonenumber === "" ? null : user?.phonenumber);
      setRelationship(user?.relationship);
      setBirthDate(user?.birthdate);
      setSchool(user?.school);
      setGrade(user?.grade);
      setGender(user?.gender);
      setRace(user?.race);
      setEthnicity(user?.ethnicity);
    }
  }, [user]);

  function validatePhone(phone: string) {
    let length = phone.length;
    return length === 11;
  }

  function enableSave() {
    if (editMode) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    } else {
      if (firstName) {
        setSaveIsEnabled(true);
        setUserToSave(true);
      } else {
        setSaveIsEnabled(false);
        setUserToSave(false);
      }
    }
  }

  const saveMember = () => {
    setErrMsg("");

    if (isValidPhone) {
      const updatedMember = {
        firstname: firstName,
        lastname: lastName,
        preferredname: preferredName,
        phonenumber: phone,
        relationship: relationship,
        birthdate: birthDate,
        gender: gender,
        race: race,
        ethnicity: ethnicity,
        school: school,
        grade: grade,
      };

      if (editMode) {
        patchMember(updatedMember, id, memberId)
          .then((res: any) => {
            setSaveIsEnabled(false);
            setUserToSave(false);
            fetchHousehold();
            setShowToast(true);
            setToastText("Member Updated");
            const toast = preferredName
              ? firstName + " '" + preferredName + "' " + lastName
              : firstName + " " + lastName;
            setToastBody(toast);
          })
          .catch((err: any) => {
            setErrMsg(err?.response?.data?.msg);
          });
      } else {
        setIsLoading(true);
        postMember(updatedMember, id)
          .then((res: any) => {
            setSaveIsEnabled(false);
            setUserToSave(false);
            fetchHousehold();
            clearMember();
            setShowToast(true);
            setToastText("Member Created");
            const toast = preferredName
              ? firstName + " '" + preferredName + "' " + lastName
              : firstName + " " + lastName;
            setToastBody(toast);
          })
          .catch((err: any) => {
            setErrMsg(err?.response?.data?.msg);
            setIsLoading(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      setErrMsg("Phone is in Invalid Format");
    }
    handleScroll(bottomRef);
  };

  const clearMember = () => {
    setFirstName("");
    setLastName("");
    setPreferredName("");
    setPhone(null);
    setRelationship("");
    setBirthDate("");
    setGender("");
    setRace("");
    setEthnicity("");
    setSchool("");
    setGrade("");
  };

  const onClickDelete = () => {
    setIsDeleting(true);
    setMemberToDelete(firstName + " " + lastName);
  };

  const updateFirstName = (e: any) => {
    setFirstName(e.target.value);
    enableSave();
  };

  const updateLastName = (e: any) => {
    setLastName(e.target.value);
    enableSave();
  };

  const updatePreferredName = (e: any) => {
    setPreferredName(e.target.value);
    enableSave();
  };

  const updatePhone = (e: any) => {
    setPhone(e);

    let validPhone = validatePhone(e);
    if (validPhone) {
      setIsValidPhone(true);
      enableSave();
    } else {
      if (e === "1") {
        setIsValidPhone(true);
        enableSave();
      } else {
        setIsValidPhone(false);
      }
    }
  };

  const updateRelationship = (e: any) => {
    setRelationship(e.target.value);
    enableSave();
  };

  const updateBirthDate = (e: any) => {
    setBirthDate(e);
    enableSave();
  };

  const updateSchool = (e: any) => {
    setSchool(e.target.value);
    enableSave();
  };

  const updateGrade = (e: any) => {
    setGrade(e.target.value);
    enableSave();
  };

  const updateGender = (e: any) => {
    setGender(e.target.value);
    enableSave();
  };

  const updateRace = (e: any) => {
    setRace(e.target.value);
    enableSave();
  };

  const updateEthnicity = (e: any) => {
    setEthnicity(e.target.value);
    enableSave();
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main mb-5">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : isDeleting ? (
        <ConfirmDelete
          userId={memberId}
          setIsDeleting={setIsDeleting}
          userToDelete={memberToDelete}
          setUserToDelete={setMemberToDelete}
          fetchHousehold={fetchHousehold}
          resetHouseholdTab={resetHouseholdTab}
          type="Member"
          setShowToast={setShowToast}
          setToastText={setToastText}
          setToastBody={setToastBody}
        />
      ) : (
        <div>
          <Stack gap={3}>
            <Row>
              <Col>
                <h4 className="app-header-text">{heading}</h4>
              </Col>
              <Col className="text-end">
                <Stack className="stack-float" direction="horizontal" gap={3}>
                  {myCase && (
                    <>
                      {editMode === true && (
                        <>
                          <Button className="app-danger-color button" onClick={() => onClickDelete()}>
                            Delete
                          </Button>
                          <div className="vr" />
                        </>
                      )}
                      <Button
                        className="app-secondary-color button"
                        onClick={() => saveMember()}
                        disabled={!saveIsEnabled}
                      >
                        Save
                      </Button>
                      <div className="vr" />
                    </>
                  )}
                  <Button className="app-primary-color button" onClick={() => onClickDoneEdit()}>
                    Done
                  </Button>
                </Stack>
              </Col>
            </Row>
            <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
              {errMsg}
            </p>
            <Row>
              <Col className="col-4">
                <Input
                  label="First Name"
                  type="text"
                  value={firstName}
                  onUpdate={(e: any) => updateFirstName(e)}
                  required={editMode ? false : true}
                />
              </Col>
              <Col className="col-4">
                <Input label="Last Name" type="text" value={lastName} onUpdate={(e: any) => updateLastName(e)} />
              </Col>
            </Row>
            <Row>
              <Col className="col-4">
                <InputPhone label="Phone" value={phone} onUpdate={(e: any) => updatePhone(e)} />
              </Col>
            </Row>
            <Row>
              <Col className="col-4">
                <Input
                  label="Preferred Name"
                  type="text"
                  value={preferredName}
                  onUpdate={(e: any) => updatePreferredName(e)}
                />
              </Col>
              <Col className="col-4">
                <DatePicker
                  label="Birth Date"
                  currentValue={birthDate}
                  setCurrentValue={updateBirthDate}
                  hasMaxDate={true}
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-4">
                <SelectObject
                  label="Relationship"
                  value={relationship}
                  options={relationships}
                  onUpdate={(e: any) => updateRelationship(e)}
                />
              </Col>
              <Col className="col-4">
                <SelectObject label="Gender" value={gender} options={genders} onUpdate={(e: any) => updateGender(e)} />
              </Col>
            </Row>
            <Row>
              <Col className="col-4">
                <SelectObject
                  label="Ethnicity"
                  value={ethnicity}
                  options={ethnicities}
                  onUpdate={(e: any) => updateEthnicity(e)}
                />
              </Col>
              <Col className="col-4">
                <SelectObject label="Race" value={race} options={races} onUpdate={(e: any) => updateRace(e)} />
              </Col>
            </Row>
            {relationship === "child" && (
              <>
                <Row>
                  <Col className="col-4">
                    <Input label="School" type="text" value={school} onUpdate={(e: any) => updateSchool(e)} />
                  </Col>
                  <Col className="col-2">
                    <Input label="Grade" type="text" value={grade} onUpdate={(e: any) => updateGrade(e)} />
                  </Col>
                </Row>
                <Row ref={bottomRef} style={{ height: "0px" }}></Row>
              </>
            )}

            {editMode && <Row ref={bottomRef} style={{ height: "80px" }}></Row>}
          </Stack>
        </div>
      )}
    </>
  );
};

export default EditMember;
