import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { User } from "../../../types/user";

interface HouseholdCardState {
  user: User;
  setEditGuardian: any;
  setEditChild: any;
  setEditHousehold: any;
  setCreateGuardian: any;
  setCreateMember: any;
  currentUser: User | undefined;
  setCurrentUser: any;
  userToSave: boolean;
  setShowModal: any;
}

const HouseholdCard = ({
  user,
  setEditGuardian,
  setEditChild,
  setEditHousehold,
  setCreateGuardian,
  setCreateMember,
  currentUser,
  setCurrentUser,
  userToSave,
  setShowModal,
}: HouseholdCardState) => {
  const handleOnClick = () => {
    if (userToSave) {
      setShowModal(true);
    } else {
      if (currentUser?.id === user?.id && currentUser?.relationship === user?.relationship) {
        setCurrentUser(undefined);
      } else {
        setCurrentUser(user);
      }

      if (currentUser?.id === user?.id && currentUser?.relationship === user?.relationship) {
        setEditGuardian(false);
        setEditChild(false);
        setEditHousehold(false);
      } else if (user.is_guardian) {
        setEditGuardian(true);
        setEditChild(false);
        setEditHousehold(false);
      } else if (user.relationship === "child") {
        setEditGuardian(false);
        setEditChild(true);
        setEditHousehold(false);
      } else {
        setEditGuardian(false);
        setEditChild(false);
        setEditHousehold(true);
      }

      setCreateGuardian(false);
      setCreateMember(false);
      setShowModal(false);
    }
  };

  return (
    <Card className="bg-light" onClick={handleOnClick}>
      <Card.Body>
        <Row>
          <Col>
            <h5
              className={
                currentUser?.id === user?.id && currentUser?.relationship === user?.relationship
                  ? "app-orange-text"
                  : "app-header-text"
              }
            >
              {user.firstname} {user.preferredname ? "'" + user.preferredname + "'" : ""} {user.lastname}
            </h5>
          </Col>
        </Row>
        <Row>
          <h6 className="text-muted first-letter">{user.relationship}</h6>
        </Row>
        <Row>
          <p className="pt-0 pb-0 mb-0">
            <small>{user.email}</small>
          </p>
          <p className="pt-0 pb-0 mb-0">
            <small>
              {user.phonenumber === "1"
                ? ""
                : user.phonenumber
                ? user.phonenumber.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, "+$1 ($2) $3-$4")
                : ""}
            </small>
          </p>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default HouseholdCard;
