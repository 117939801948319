import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, Title, BarElement, Tooltip } from "chart.js";
import { Card } from "react-bootstrap";

// Register the required Chart.js scales
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export const InteractionsBarChart = ({ data }: any) => {
  const chartData = {
    labels: data.map((item: any) => item.firstname + " " + item.lastname),
    datasets: [
      {
        label: "Interactions",
        data: data.map((item: any) => item.count),
        backgroundColor: ["#5D82A0"],
      },
    ],
  };

  const options = {
    indexAxis: "y" as const,
    scales: {
      y: {
        beginAtZero: true,
        max: Math.max(...data.map((item: any) => item.count)) + 50,
        ticks: {
          font: {
            size: 10,
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Case Manager Interactions",
      },
    },
    elements: {
      bar: {
        borderWidth: 1,
        borderRadius: 4,
        barPercentage: 0.8,
        categoryPercentage: 0.9,
      },
    },
  };

  return (
    <Card className="p-3">
      <Bar data={chartData} options={options} />
    </Card>
  );
};
