import api from "../utils/api";
import apiBlob from "../utils/apiBlob";

///Auth

export async function login(user: string, password: string) {
  const response = await Promise.all([api.post("/users/auth", { email: user, password: password })]);
  return response;
}

export async function fetchUserSecret() {
  const response = await Promise.all([api.get("/users/totpsecret")]);
  return response;
}

export async function verifyTwoFactor(totpCode: string, totpSecret?: string) {
  var obj = {};

  if (totpSecret) {
    obj = {
      code: totpCode,
      secret: totpSecret,
    };
  } else {
    obj = { code: totpCode };
  }
  const response = await Promise.all([api.post("/users/verifytotp", obj)]);
  return response;
}

export async function logout() {
  const response = await Promise.all([
    api
      .post("/users/logout")
      .then()
      .catch(function (error) {
        console.log(error);
      }),
  ]);
  return response;
}

export async function sendPasswordReset(email: string) {
  const response = await Promise.all([
    api.post(`/users/sendpasswordreset`, { emailPhone: email, mode: "desktop", type: "email" }),
  ]);
  return response;
}

export async function resetPassword(resetCode: string, emailPhone: string, password: string) {
  const response = await Promise.all([
    api.post(`/users/resetpassword`, { resetCode: resetCode, emailPhone: emailPhone, password: password }),
  ]);
  return response;
}

///Announcements
export async function fetchAnnouncements() {
  const response = await Promise.all([
    api.get(`/announcements`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchAnnouncement(id?: string) {
  const response = await Promise.all([api.get(`/announcements/${id}`)]);
  return response;
}

export async function patchAnnouncement(c: object, id?: string) {
  const response = await Promise.all([api.patch(`/announcements/${id}`, c)]);
  return response;
}

export async function postAnnouncement(c: object) {
  const response = await Promise.all([api.post(`/announcements`, c)]);
  return response;
}

export async function deleteAnnouncement(id?: string) {
  const response = await Promise.all([api.delete(`/announcements/${id}`)]);
  return response;
}

///Users
export async function fetchUsers() {
  const response = await Promise.all([
    api.get(`/users`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchUser(id?: string) {
  const response = await Promise.all([api.get(`/users/${id}`)]);
  return response;
}

export async function fetchFamilyUsers() {
  const response = await Promise.all([api.get(`/users/family`)]);
  return response;
}

export async function patchUserData(user: object, id?: string) {
  const response = await Promise.all([api.patch(`/users/${id}`, user)]);
  return response;
}

export async function postUserData(user: object) {
  const response = await Promise.all([api.post(`/users`, user)]);
  return response;
}

export async function deleteUser(id?: string) {
  const response = await Promise.all([api.delete(`/users/${id}`)]);
  return response;
}

///Cases
export async function fetchCases() {
  const response = await Promise.all([
    api.get(`/cases`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchCase(id?: string) {
  const response = await Promise.all([api.get(`/cases/${id}`)]);
  return response;
}

export async function patchCaseData(c: object, id?: string) {
  const response = await Promise.all([api.patch(`/cases/${id}`, c)]);
  return response;
}

export async function postCaseData(c: object) {
  const response = await Promise.all([api.post(`/cases`, c)]);
  return response;
}

export async function deleteCase(id?: string) {
  const response = await Promise.all([api.delete(`/cases/${id}`)]);
  return response;
}

///Group
export async function fetchGroups() {
  const response = await Promise.all([
    api.get(`/groups`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchGroup(id?: string) {
  const response = await Promise.all([api.get(`/groups/${id}`)]);
  return response;
}

export async function patchGroup(c: object, id?: string) {
  const response = await Promise.all([api.patch(`/groups/${id}`, c)]);
  return response;
}

export async function postGroup(g: object) {
  const response = await Promise.all([api.post(`/groups`, g)]);
  return response;
}

export async function deleteGroup(id?: string) {
  const response = await Promise.all([api.delete(`/groups/${id}`)]);
  return response;
}

///Group Participants
export async function fetchGroupParticipants(id?: string) {
  const response = await Promise.all([api.get(`/groups/${id}/participants`)]);
  return response;
}

export async function postGroupParticipant(p: object, id?: string) {
  const response = await Promise.all([api.post(`/groups/${id}/participants`, p)]);
  return response;
}

export async function deleteGroupParticipant(id?: string, partId?: string) {
  const response = await Promise.all([api.delete(`/groups/${id}/participants/${partId}`)]);
  return response;
}

export async function fetchGroupPossibleParticipants(id?: string) {
  const response = await Promise.all([api.get(`/groups/${id}/participants/possible`)]);
  return response;
}

///Reports
export async function fetchReportInteractions(startDate: string, endDate: string) {
  const response = await Promise.all([
    api.get(`/reports/interactions?startDate=${startDate}&endDate=${endDate}`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchReportGoals(startDate: string, endDate: string) {
  const response = await Promise.all([
    api.get(`/reports/goals?startDate=${startDate}&endDate=${endDate}`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchReportTasks(startDate: string, endDate: string) {
  const response = await Promise.all([
    api.get(`/reports/tasks?startDate=${startDate}&endDate=${endDate}`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchReportWellBeing(startDate: string, endDate: string) {
  const response = await Promise.all([
    api.get(`/reports/wellbeing?startDate=${startDate}&endDate=${endDate}`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchReportContinuum() {
  const response = await Promise.all([
    api.get(`/reports/continuum`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

///User Case Reports
export async function fetchUserCaseReportInteractions(startDate: string, endDate: string, id?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/reports/interactions?startDate=${startDate}&endDate=${endDate}`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchUserCaseReportGoals(startDate: string, endDate: string, id?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/reports/goals?startDate=${startDate}&endDate=${endDate}`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchUserCaseReportTasks(startDate: string, endDate: string, id?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/reports/tasks?startDate=${startDate}&endDate=${endDate}`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchUserCaseReportWellBeing(startDate: string, endDate: string, id?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/reports/wellbeing?startDate=${startDate}&endDate=${endDate}`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchUserCaseReportContinuum(id?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/reports/continuum`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

///User Cases
export async function fetchUserCases() {
  const response = await Promise.all([
    api.get(`/user-cases`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchUserCase(id?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function patchUserCase(c: object, id?: string) {
  const response = await Promise.all([api.patch(`/user-cases/${id}`, c)]);
  return response;
}

///User Case Guardians
export async function fetchUserCaseGuardians(id?: string, deleted: boolean = false) {
  if (deleted) {
    const response = await Promise.all([
      api.get(`/user-cases/${id}/guardians?deleted=${deleted}`).catch((error) => {
        if (error.response.status === 401) {
          logout().then((res: any) => {
            localStorage.removeItem("auth");
            window.location.reload();
          });
        }
      }),
    ]);
    return response;
  } else {
    const response = await Promise.all([
      api.get(`/user-cases/${id}/guardians`).catch((error) => {
        if (error.response.status === 401) {
          logout().then((res: any) => {
            localStorage.removeItem("auth");
            window.location.reload();
          });
        }
      }),
    ]);
    return response;
  }
}

export async function patchGuardian(guardian: object, id?: string, guardianId?: string) {
  const response = await Promise.all([api.patch(`/user-cases/${id}/guardians/${guardianId}`, guardian)]);
  return response;
}

export async function postGuardian(guardian: object, id?: string) {
  const response = await Promise.all([api.post(`/user-cases/${id}/guardians`, guardian)]);
  return response;
}

export async function deleteGuardian(id?: string, guardianId?: string) {
  const response = await Promise.all([api.delete(`/user-cases/${id}/guardians/${guardianId}`)]);
  return response;
}

///User Case Members
export async function fetchUserCaseMembers(id?: string, deleted: boolean = false) {
  if (deleted) {
    const response = await Promise.all([
      api.get(`/user-cases/${id}/members?deleted=${deleted}`).catch((error) => {
        if (error.response.status === 401) {
          logout().then((res: any) => {
            localStorage.removeItem("auth");
            window.location.reload();
          });
        }
      }),
    ]);
    return response;
  } else {
    const response = await Promise.all([
      api.get(`/user-cases/${id}/members`).catch((error) => {
        if (error.response.status === 401) {
          logout().then((res: any) => {
            localStorage.removeItem("auth");
            window.location.reload();
          });
        }
      }),
    ]);
    return response;
  }
}

export async function fetchUserCaseMember(id?: string, memberId?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/members/${memberId}`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function patchMember(member: object, id?: string, memberId?: string) {
  const response = await Promise.all([api.patch(`/user-cases/${id}/members/${memberId}`, member)]);
  return response;
}

export async function postMember(member: object, id?: string) {
  const response = await Promise.all([api.post(`/user-cases/${id}/members`, member)]);
  return response;
}

export async function deleteMember(id?: string, memberId?: string) {
  const response = await Promise.all([api.delete(`/user-cases/${id}/members/${memberId}`)]);
  return response;
}

//User Case Notes
export async function fetchNotes(id?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/notes`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function postNote(note: object, id?: string) {
  const response = await Promise.all([api.post(`/user-cases/${id}/notes`, note)]);
  return response;
}

export async function patchNote(note?: object, id?: string, noteId?: string) {
  const response = await Promise.all([api.patch(`/user-cases/${id}/notes/${noteId}`, note)]);
  return response;
}

//User Case Notes Docs
export async function fetchNoteDocuments(id?: string, noteId?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/notes/${noteId}/documents`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchNoteDocumentDownload(id?: string, noteId?: string, docId?: string) {
  const response = await Promise.all([apiBlob.get(`/user-cases/${id}/notes/${noteId}/documents/${docId}/download`)]);
  return response;
}

export async function postNoteDocument(name: string, data: string, id?: string, noteId?: string) {
  const response = await Promise.all([
    api.post(`/user-cases/${id}/notes/${noteId}/documents`, {
      name: name,
      data: data,
    }),
  ]);
  return response;
}

export async function deleteNoteDocument(id?: string, noteId?: string, docId?: string) {
  const response = await Promise.all([api.delete(`/user-cases/${id}/notes/${noteId}/documents/${docId}`)]);
  return response;
}

//User Case Notes Recordings
export async function fetchNoteRecordings(id?: string, noteId?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/notes/${noteId}/recordings`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchNoteRecordingData(id?: string, noteId?: string, recordingId?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/notes/${noteId}/recordings/${recordingId}/data`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function deleteNoteRecordings(id?: string, noteId?: string, recordingId?: string) {
  const response = await Promise.all([api.delete(`/user-cases/${id}/notes/${noteId}/recordings/${recordingId}`)]);
  return response;
}

//User Case Transcripts
export async function fetchTranscript(id: string) {
  const response = await Promise.all([
    api.get(`/transcripts/${id}`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

//Documents
export async function fetchDocuments(id?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/documents`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchDocumentDownload(id?: string, docId?: string) {
  const response = await Promise.all([apiBlob.get(`/user-cases/${id}/documents/${docId}/download`)]);
  return response;
}

export async function fetchDocumentView(id?: string, docId?: string) {
  const response = await Promise.all([apiBlob.get(`/user-cases/${id}/documents/${docId}/view`)]);
  return response;
}

export async function postDocument(name: string, data: string, id?: string) {
  const response = await Promise.all([
    api.post(`/user-cases/${id}/documents`, {
      name: name,
      data: data,
    }),
  ]);
  return response;
}

export async function deleteDocument(id?: string, docId?: string) {
  const response = await Promise.all([api.delete(`/user-cases/${id}/documents/${docId}`)]);
  return response;
}

//User Case Supports
export async function fetchUserCaseSupports(id?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/supports`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function patchSupportsData(support: object, id?: string, supportId?: string) {
  const response = await Promise.all([api.patch(`/user-cases/${id}/supports/${supportId}`, support)]);
  return response;
}

export async function postSupportsData(id?: string, support?: string) {
  const response = await Promise.all([api.post(`/user-cases/${id}/supports`, { user_id: support })]);
  return response;
}

export async function deleteSupports(id?: string, supportId?: string) {
  const response = await Promise.all([api.delete(`/user-cases/${id}/supports/${supportId}`)]);
  return response;
}

//User Case Supports Docs
export async function fetchSupportsRelease(id?: string, supportId?: string) {
  const response = await Promise.all([api.get(`/user-cases/${id}/supports/${supportId}/release`)]);
  return response;
}

export async function fetchSupportsReleaseDownload(id?: string, supportId?: string) {
  const response = await Promise.all([apiBlob.get(`/user-cases/${id}/supports/${supportId}/release/download`)]);
  return response;
}

export async function postSupportsRelease(name: string, data: string, id?: string, supportId?: string) {
  const response = await Promise.all([
    api.post(`/user-cases/${id}/supports/${supportId}/release`, {
      name: name,
      data: data,
    }),
  ]);
  return response;
}

export async function deleteSupportsRelease(id?: string, supportId?: string) {
  const response = await Promise.all([api.delete(`/user-cases/${id}/supports/${supportId}/release`)]);
  return response;
}

//User Case Goals
export async function fetchGoals(id?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/goals`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function patchGoal(goal: object, id?: string, goalId?: string) {
  const response = await Promise.all([api.patch(`/user-cases/${id}/goals/${goalId}`, goal)]);
  return response;
}

export async function postGoal(goal: object, id?: string) {
  const response = await Promise.all([api.post(`/user-cases/${id}/goals`, goal)]);
  return response;
}

export async function deleteGoal(id?: string, goalId?: string) {
  const response = await Promise.all([api.delete(`/user-cases/${id}/goals/${goalId}`)]);
  return response;
}

//User Case Goal Tasks
export async function patchTask(task: object, id?: string, goalId?: string, taskId?: string) {
  const response = await Promise.all([api.patch(`/user-cases/${id}/goals/${goalId}/tasks/${taskId}`, task)]);
  return response;
}

export async function postTask(task: object, id?: string, goalId?: string) {
  const response = await Promise.all([api.post(`/user-cases/${id}/goals/${goalId}/tasks`, task)]);
  return response;
}

export async function deleteTask(id?: string, goalId?: string, taskId?: string) {
  const response = await Promise.all([api.delete(`/user-cases/${id}/goals/${goalId}/tasks/${taskId}`)]);
  return response;
}

export async function fetchNextTasks(id?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/next-tasks`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

//User Case Assessments
export async function fetchAssessments(id?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/assessments`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchAssessment(id?: string, assessId?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/assessments/${assessId}`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function postAssessment(assess: object, id?: string) {
  const response = await Promise.all([api.post(`/user-cases/${id}/assessments`, assess)]);
  return response;
}

//User Case Convesations
export async function fetchConversations(id?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/conversations`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function postConversation(conv: object, id?: string) {
  const response = await Promise.all([api.post(`/user-cases/${id}/conversations`, conv)]);
  return response;
}

//User Case Convesations / Messages
export async function fetchMessages(id?: string, convId?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/conversations/${convId}/messages`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function fetchAddlMessages(id?: string, convId?: string, firstId?: string) {
  const response = await Promise.all([
    api.get(`/user-cases/${id}/conversations/${convId}/messages?firstID=${firstId}`).catch((error) => {
      if (error.response.status === 401) {
        logout().then((res: any) => {
          localStorage.removeItem("auth");
          window.location.reload();
        });
      }
    }),
  ]);
  return response;
}

export async function postNewMessage(message: object, id?: string, convId?: string) {
  const response = await Promise.all([api.post(`/user-cases/${id}/conversations/${convId}/messages`, message)]);
  return response;
}

//User Case Notes Magic Summary
export async function fetchSummary(id?: string) {
  const response = await Promise.all([api.get(`/user-cases/${id}/summary`).catch((error) => {})]);
  return response;
}

export async function postSummary(id?: string) {
  const response = await Promise.all([api.post(`/user-cases/${id}/summary`).catch((error) => {})]);
  return response;
}

///Dropdowns
export async function fetchRelationships() {
  const response = await Promise.all([api.get(`/relationships`)]);
  return response;
}

export async function fetchGenders() {
  const response = await Promise.all([api.get(`/genders`)]);
  return response;
}

export async function fetchRaces() {
  const response = await Promise.all([api.get(`/races`)]);
  return response;
}

export async function fetchEthnicities() {
  const response = await Promise.all([api.get(`/ethnicities`)]);
  return response;
}

export async function fetchSupportTypes() {
  const response = await Promise.all([api.get(`/supporttypes`)]);
  return response;
}

export async function fetchNoteTypes() {
  const response = await Promise.all([api.get(`/notetypes`)]);
  return response;
}

export async function fetchCaseStatuses() {
  const response = await Promise.all([api.get(`/casestatuses`)]);
  return response;
}

export async function fetchRoles() {
  const response = await Promise.all([api.get(`/userroles`)]);
  return response;
}

///User Impersonation
export async function fetchUserImpersonation(authCode: string) {
  const response = await Promise.all([api.get(`/user-impersonations?authCode=${authCode}`)]);
  return response;
}

export async function patchUserImpersonation(id: string) {
  const response = await Promise.all([api.patch(`/user-impersonations/${id}`)]);
  return response;
}

//////////////////////////////////////////////////////////
