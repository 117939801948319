import React, { useEffect, useState } from "react";
import { Row, Col, Stack, Card, Container, Button, Spinner } from "react-bootstrap";
import { User } from "../../../types/user";
import PageTitle from "../../ui/PageTitle";
import { fetchUserCaseGuardians, fetchUserCaseMembers } from "../../../services/httpClient";
import { useParams } from "react-router-dom";

interface GroupsState {
  getData: boolean;
}

const Groups = ({ getData }: GroupsState) => {
  const { id } = useParams();
  const [guardians, setGuardians] = useState<User[]>([]);
  const [members, setMembers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getGroups();
    }
  }, [getData]);

  const getGroups = () => {
    fetchUserCaseGuardians(id, false)
      .then((res: any) => {
        var guards = res[0].data;
        setGuardians(guards);

        fetchUserCaseMembers(id, false)
          .then((res: any) => {
            var membs = res[0].data;
            setMembers(membs);
          })
          .catch((err: any) => {
            console.log(err);
            setIsLoading(false);
          });

        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <PageTitle title="Groups" type="section" />
          <PageTitle title="Guardian(s)" type="section" />
          <Row>
            {guardians.map((g: User) => (
              <Col className="col-lg-3 col-md-4 col-sm-6 mb-2">
                <Stack gap={1}>
                  <h5 className="app-header-text">
                    {g.firstname} {g.lastname}
                  </h5>
                  {g?.groups?.length === 0 ? (
                    <p>No Groups</p>
                  ) : (
                    g?.groups?.map((p: any) => (
                      <Card key={p.id} className="bg-light">
                        <Card.Body>
                          <Stack direction="horizontal" gap={3}>
                            <Stack gap={2}>
                              <Card.Subtitle>{p.name}</Card.Subtitle>
                            </Stack>
                          </Stack>
                        </Card.Body>
                      </Card>
                    ))
                  )}
                </Stack>
              </Col>
            ))}
          </Row>
          <PageTitle title="Children" type="section" />
          <Row>
            {members
              .filter((f: any) => f.relationship === "child")
              .map((m: any) => (
                <Col className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <Stack gap={1}>
                    <h5 className="app-header-text">
                      {m.firstname} {m.lastname}
                    </h5>
                    {m?.groups?.length === 0 ? (
                      <p>No Groups</p>
                    ) : (
                      m?.groups?.map((p: any) => (
                        <Card key={p.id} className="bg-light">
                          <Card.Body>
                            <Stack direction="horizontal" gap={3}>
                              <Stack gap={2}>
                                <Card.Subtitle>{p.name}</Card.Subtitle>
                              </Stack>
                            </Stack>
                          </Card.Body>
                        </Card>
                      ))
                    )}
                  </Stack>
                </Col>
              ))}
          </Row>
          <PageTitle title="Other Household" type="section" />
          <Row>
            {members
              .filter((f: any) => f.relationship !== "child")
              .map((m: any) => (
                <Col className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <Stack gap={1}>
                    <h5 className="app-header-text">
                      {m.firstname} {m.lastname}
                    </h5>
                    {m?.groups?.length === 0 ? (
                      <p>No Groups</p>
                    ) : (
                      m?.groups?.map((p: any) => (
                        <Card key={p.id} className="bg-light">
                          <Card.Body>
                            <Stack direction="horizontal" gap={3}>
                              <Stack gap={2}>
                                <Card.Subtitle>{p.name}</Card.Subtitle>
                              </Stack>
                            </Stack>
                          </Card.Body>
                        </Card>
                      ))
                    )}
                  </Stack>
                </Col>
              ))}
          </Row>
        </>
      )}
    </>
  );
};

export default Groups;
