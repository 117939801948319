import React, { useEffect, useState } from "react";
import { Row, Col, Button, FloatingLabel, Form, Stack, Container, Spinner } from "react-bootstrap";
import { User } from "../../../types/user";
import SupportCard from "./SupportCard";
import EditSupport from "./EditSupport";
import { fetchUserCaseSupports, fetchUsers, postSupportsData } from "../../../services/httpClient";
import { useParams } from "react-router-dom";
import ToastMsg from "../../ui/ToastMsg";

interface SupportUsersState {
  editUser: boolean;
  setEditUser: any;
  currentUser: User | undefined;
  setCurrentUser: any;
  addSupport: boolean;
  setAddSupport: any;
  getData: boolean;
  myCase: boolean;
}

const SupportUsers = ({
  editUser,
  setEditUser,
  currentUser,
  setCurrentUser,
  addSupport,
  setAddSupport,
  getData,
  myCase,
}: SupportUsersState) => {
  const { id } = useParams();
  const [support, setSupport] = useState<User[]>([]);
  const [possibleSupport, setPossibleSupport] = useState<User[]>([]);
  const [selectedSupport, setSelectedSupport] = useState<string>();
  const [releaseDoc, setReleaseDoc] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getSupport();
    }
  }, [getData]);

  const getSupport = () => {
    fetchUserCaseSupports(id)
      .then((res: any) => {
        const users = res[0].data;
        setSupport(users);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onClickAddSupport = () => {
    setAddSupport(true);
    var userList: any[] = [];

    fetchUsers()
      .then((res: any) => {
        res[0].data.map((i: any) => {
          if (i.role === "support") {
            userList.push(i);
          }
        });

        userList = userList.filter((item1) => !support.some((item2) => item1.id === item2.user_id));
        setPossibleSupport(userList);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const onClickCancelAddSupport = () => {
    setAddSupport(false);
  };

  const onClickSaveSupport = () => {
    const supportPerson = possibleSupport.filter((obj) => {
      const fullname = obj.firstname + " " + obj.lastname;
      return fullname === selectedSupport;
    });
    postSupportsData(id, supportPerson[0].id).then((res: any) => {
      setAddSupport(false);
      getSupport();
    });
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <Row>
            <Col>
              <h4 className="mb-0 app-header-text">Support Member(s)</h4>
            </Col>
          </Row>
          <Row>
            {support.map((user: User) => (
              <Col key={user.id} md={6} lg={4} xl={3} className="mx-1 mt-3 card-members">
                <SupportCard
                  user={user}
                  setEditMember={setEditUser}
                  currentMember={currentUser}
                  setCurrentMember={setCurrentUser}
                  setRelease={setReleaseDoc}
                  setAddSupport={setAddSupport}
                />
              </Col>
            ))}
          </Row>

          {editUser === true && (
            <EditSupport
              user={currentUser}
              fetchSupports={getSupport}
              setEditMember={setEditUser}
              setCurrentMember={setCurrentUser}
              release={releaseDoc}
              setRelease={setReleaseDoc}
              setShowToast={setShowToast}
              setToastText={setToastText}
              setToastBody={setToastBody}
              myCase={myCase}
            />
          )}

          {editUser !== true && (
            <Row>
              <Col className="col-3">
                {myCase && (
                  <Button
                    className="app-tertiary-color button"
                    onClick={() => onClickAddSupport()}
                    disabled={addSupport}
                  >
                    Add Support
                  </Button>
                )}
              </Col>
            </Row>
          )}

          {addSupport === true && (
            <Row className="align-items-center">
              <Col className="col-5">
                <FloatingLabel controlId="floatingInput" label="Support Users">
                  <Form.Select
                    className="decorated"
                    value={selectedSupport}
                    onChange={(e: any) => setSelectedSupport(e.target.value)}
                  >
                    <option key={0}>Select from the following</option>
                    {possibleSupport.map((i: any) => (
                      <option key={i.id} value={i.firstname + " " + i.lastname}>
                        {i.firstname} {i.lastname} - {i.organization}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col className="col-3">
                <Stack direction="horizontal" gap={3}>
                  <Button className="app-tertiary-color button" onClick={() => onClickSaveSupport()}>
                    Add
                  </Button>
                  <div className="vr" />
                  <Button className="app-primary-color button" onClick={() => onClickCancelAddSupport()}>
                    Cancel
                  </Button>
                </Stack>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default SupportUsers;
