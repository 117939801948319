import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Container, Row, Spinner } from "react-bootstrap";
import CaseCard from "./CaseCard";
import SearchBar from "../ui/SearchBar";
import { fetchUserCases } from "../../services/httpClient";
import { Case } from "../../types/case";
import MyCasesToday from "./MyCasesToday";
import useApp from "../../utils/useApp";
import { trackNavigationEvent } from "../../utils/analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const MyCases = () => {
  const { auth, showMenu, viewAllCases, setViewAllCases }: any = useApp();
  const [allCases, setAllCases] = useState<Case[]>([]);
  const [myCases, setMyCases] = useState<Case[]>([]);
  const [otherCases, setOtherCases] = useState<Case[]>([]);

  const [filteredMyCases, setFilteredMyCases] = useState<Case[]>([]);
  const [filteredOtherCases, setFilteredOtherCases] = useState<Case[]>([]);

  const [assessFilterClicked, setAssessFilterClicked] = useState<boolean>(false);
  const [msgsFilterClicked, setMsgsFilterClicked] = useState<boolean>(false);
  const [nextFilterClicked, setNextFilterClicked] = useState<boolean>(false);
  const casesFiltered = assessFilterClicked || msgsFilterClicked || nextFilterClicked ? true : false;
  const [searchText, setSearchText] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUserCases();
  }, []);

  const getUserCases = () => {
    setIsLoading(true);

    fetchUserCases()
      .then((res: any) => {
        var cases = res[0].data;
        var activecases = cases.filter((c: any) => c.casestatus === "active");
        setAllCases(activecases);

        if (auth?.profile?.role === "support") {
          const mycases = activecases.filter((c: any) => c.support[0]?.user_id === auth.profile.id);
          setMyCases(mycases);
          setFilteredMyCases(mycases);
        } else {
          const mycases = activecases.filter(
            (c: any) => c.primaryCM?.user_id === auth.profile.id || c.secondaryCM?.user_id === auth.profile.id
          );
          setMyCases(mycases);

          const othercases = activecases.filter((c: any) => !mycases.some((ci: any) => ci.id === c.id));
          setOtherCases(othercases);

          setFilteredMyCases(mycases);
          setFilteredOtherCases(othercases);
        }

        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    var myfCases = myCases;
    var otherfCases = otherCases;

    if (assessFilterClicked && msgsFilterClicked && nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.assessment_due && c.new_messages && c.task_review;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.assessment_due && c.new_messages && c.task_review;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else if (assessFilterClicked && msgsFilterClicked && !nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.assessment_due && c.new_messages;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.assessment_due && c.new_messages;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else if (!assessFilterClicked && msgsFilterClicked && nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.new_messages && c.task_review;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.new_messages && c.task_review;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else if (assessFilterClicked && !msgsFilterClicked && nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.assessment_due && c.task_review;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.assessment_due && c.task_review;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else if (assessFilterClicked && !msgsFilterClicked && !nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.assessment_due;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.assessment_due;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else if (!assessFilterClicked && msgsFilterClicked && !nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.new_messages;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.new_messages;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else if (!assessFilterClicked && !msgsFilterClicked && nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.task_review;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.task_review;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else {
      setFilteredMyCases(myCases);
      setFilteredOtherCases(otherCases);
    }
  }, [assessFilterClicked, msgsFilterClicked, nextFilterClicked]);

  const onClickClearFilters = () => {
    setFilteredMyCases(myCases);
    setFilteredOtherCases(otherCases);

    setAssessFilterClicked(false);
    setMsgsFilterClicked(false);
    setNextFilterClicked(false);
    setSearchText("");
  };

  const onClickViewCases = () => {
    if (viewAllCases) {
      setViewAllCases(false);
      setFilteredMyCases(myCases);
      trackNavigationEvent("switch_view", "view_my_cases");
    } else {
      setViewAllCases(true);
      setFilteredMyCases(myCases);
      setFilteredOtherCases(otherCases);
      trackNavigationEvent("switch_view", "view_all_cases");
    }

    setAssessFilterClicked(false);
    setMsgsFilterClicked(false);
    setNextFilterClicked(false);
    setSearchText("");
  };

  return (
    <>
      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu custom-container" : "lg div-main custom-container"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "lg div-main-menu custom-container" : "lg div-main custom-container"}>
          {auth?.profile?.role !== "support" && (
            <Row>
              <Col className="col-6">
                <h2 className="hero-heading app-header-text">
                  {viewAllCases ? "All Cases (" + allCases.length + ")" : "My Cases (" + myCases.length + ")"}
                </h2>
              </Col>
              <Col className="text-end col-6">
                <Button
                  className="app-primary-color button"
                  onClick={auth?.profile?.role !== "support" ? (e) => onClickViewCases() : () => null}
                >
                  {viewAllCases ? "View My Cases" : "View All Cases"}
                </Button>
              </Col>
            </Row>
          )}
          <Row className="mt-3 mb-4">
            <Col></Col>
            <Col className="col-9">
              <SearchBar
                cssClass="search"
                placeholder="Search Case Name"
                type="case"
                objMy={casesFiltered ? filteredMyCases : myCases}
                setFilteredObjMy={setFilteredMyCases}
                objOther={casesFiltered ? filteredOtherCases : otherCases}
                setFilteredObjOther={setFilteredOtherCases}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            </Col>
            <Col></Col>
          </Row>
          {auth?.profile?.role !== "support" && (
            <MyCasesToday
              cases={viewAllCases ? allCases : myCases}
              assessFilter={assessFilterClicked}
              setAssessFilter={setAssessFilterClicked}
              msgsFilter={msgsFilterClicked}
              setMsgsFilter={setMsgsFilterClicked}
              nextFilter={nextFilterClicked}
              setNextFilter={setNextFilterClicked}
            />
          )}
          <Row className="mt-3">
            {assessFilterClicked && (
              <Col className="col-12 text-center">
                <Badge className="app-tertiary-color-inverse" bg="none">
                  Assessment Due
                </Badge>
                <Badge className="app-tertiary-color ms-1" bg="none" onClick={onClickClearFilters}>
                  <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                </Badge>
              </Col>
            )}
            {msgsFilterClicked && (
              <Col className="col-12 text-center">
                <Badge className="app-tertiary-color-inverse" bg="none">
                  Unread Messages
                </Badge>
                <Badge className="app-tertiary-color ms-1" bg="none" onClick={onClickClearFilters}>
                  <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                </Badge>
              </Col>
            )}
            {nextFilterClicked && (
              <Col className="col-12 text-center">
                <Badge className="app-tertiary-color-inverse" bg="none">
                  Next Task Review
                </Badge>
                <Badge className="app-tertiary-color ms-1" bg="none" onClick={onClickClearFilters}>
                  <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                </Badge>
              </Col>
            )}
          </Row>
          <Row>
            <Col></Col>
            <Col className="col-9">
              <hr className="mt-3" />
            </Col>
            <Col></Col>
          </Row>
          {viewAllCases && filteredOtherCases.length > 0 && (
            <>
              <Row>
                <Col></Col>
                <Col className="col-9">
                  <h4 className="hero-heading app-header-text"> {"Other Cases (" + filteredOtherCases.length + ")"}</h4>
                </Col>
                <Col></Col>
              </Row>
              <Row className="justify-content-center mb-5">
                <Container className="card-container">
                  {filteredOtherCases.map((c: any) => (
                    <CaseCard
                      key={c.id}
                      myCase={c}
                      assessFilter={assessFilterClicked}
                      msgsFilter={msgsFilterClicked}
                      nextFilter={nextFilterClicked}
                    />
                  ))}
                </Container>
              </Row>
            </>
          )}

          {viewAllCases && filteredMyCases.length > 0 && (
            <>
              <Row>
                <Col></Col>
                <Col className="col-9">
                  <h4 className="hero-heading app-header-text"> {"My Cases (" + filteredMyCases.length + ")"}</h4>
                </Col>
                <Col></Col>
              </Row>
            </>
          )}
          <Row className="justify-content-center mb-5 pb-5">
            <Container className="card-container">
              {filteredMyCases.map((c: any) => (
                <CaseCard
                  key={c.id}
                  myCase={c}
                  assessFilter={assessFilterClicked}
                  msgsFilter={msgsFilterClicked}
                  nextFilter={nextFilterClicked}
                />
              ))}
            </Container>
          </Row>
        </Container>
      )}
    </>
  );
};

export default MyCases;
