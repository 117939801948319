import React, { useEffect, useRef } from "react";
import { Form, ListGroup, ListGroupItem, Row } from "react-bootstrap";

interface SenderState {
  message: any;
  addRef: any;
}

const SenderMessage = ({ message, addRef }: SenderState) => {
  const msgRef = useRef<null | HTMLDivElement>();

  useEffect(() => {
    if (addRef) {
      if (msgRef.current) {
        msgRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
  }, []);

  return (
    <>
      <ListGroupItem key={message.id} className="sender-row message p-0">
        <ListGroup className="sender-list align-items-end">
          <ListGroupItem className="message p-0">
            <Form.Label className="text-wrap sender mb-0">{message.body}</Form.Label>
            <p className="text-end m-0 pe-2 sender-text">{message.display_time}</p>
          </ListGroupItem>
        </ListGroup>
      </ListGroupItem>
      {addRef && <Row ref={msgRef} style={{ width: "80px", height: 0 }}></Row>}
    </>
  );
};

export default SenderMessage;
