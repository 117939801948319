import React, { useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { unsavedChanges } from "../../App";

interface MeetingNoteState {
  participant: any;
  index: number;
  participants: any[];
  setParticipants: any;
  setEnableSave: any;
  meetingDate: string;
}

const GroupMeetingIndivNote = ({
  participant,
  index,
  participants,
  setParticipants,
  setEnableSave,
  meetingDate,
}: MeetingNoteState) => {
  const [note, setNote] = useState<string>("");
  const [isPresent, setIsPresent] = useState<boolean>(true);
  const [disableNote, setDisableNote] = useState<boolean>(false);

  const updateNote = (e: any) => {
    participant.note = e.target.value;
    participants[index] = participant;
    unsavedChanges.value = true;
    setNote(e.target.value);
    setParticipants(participants);
    if (meetingDate) {
      setEnableSave(true);
    }
  };

  const updateAttendance = () => {
    participant.is_present = !isPresent;
    participants[index] = participant;
    unsavedChanges.value = true;

    setIsPresent(!isPresent);

    if (participant.is_present === true) {
      setDisableNote(false);
    } else {
      setDisableNote(true);
    }

    setParticipants(participants);
  };

  return (
    <Row>
      <Col className="col-9">
        <FloatingLabel
          controlId="floatingInput"
          label={participant.firstname + " " + participant.lastname + " Note"}
          className="sm mb-2 mt-1"
        >
          <Form.Control
            type="text"
            placeholder="Note"
            value={note}
            onChange={(e) => updateNote(e)}
            disabled={disableNote}
          />
        </FloatingLabel>
      </Col>
      <Col>
        <Form.Group key={`default-checkbox`} className="mb-1">
          <label style={{ display: "flex" }}>
            <Form.Check
              onChange={updateAttendance}
              type="checkbox"
              id={`default-checkbox`}
              checked={isPresent}
              key={"Present"}
              className="me-2"
            />
            {"Present"}
          </label>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default GroupMeetingIndivNote;
