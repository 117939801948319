import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchSupportsRelease } from "../../../services/httpClient";
import { User } from "../../../types/user";

interface SupportCardState {
  user: User;
  setEditMember: any;
  currentMember: User | undefined;
  setCurrentMember: any;
  setRelease: any;
  setAddSupport: any;
}

const SupportCard = ({
  user,
  setEditMember,
  currentMember,
  setCurrentMember,
  setRelease,
  setAddSupport,
}: SupportCardState) => {
  const { id } = useParams();

  const handleOnClick = () => {
    fetchSupportsRelease(id, user.id).then((res: any) => {
      setRelease(res[0].data);

      if (currentMember?.id === user?.id) {
        setEditMember(false);
        setCurrentMember(undefined);
      } else {
        setEditMember(true);
        setCurrentMember(user);
      }
      setAddSupport(false);
    });
  };

  return (
    <Card className="bg-light mb-3" onClick={handleOnClick}>
      <Card.Body>
        <Row>
          <Col className="col-10">
            <h5 className={currentMember?.firstname === user?.firstname ? "app-orange-text" : "app-header-text"}>
              {user.firstname} {user.preferredname ? "'" + user.preferredname + "'" : ""} {user.lastname}
            </h5>
          </Col>
          {user.is_authorized === 1 && (
            <Col className="col-2 text-end">
              <FontAwesomeIcon icon={faCheck} className="me-3" />
            </Col>
          )}
        </Row>
        <Row>
          <h6 className="text-muted mb-1">
            {user.supporttype ? user.supporttype[0].toUpperCase() + user.supporttype.substring(1) : ""}
          </h6>
        </Row>
        <Row>
          <p className="pt-0 pb-0 mb-0">{user.organization}</p>
        </Row>
        <Row>
          <p className="pt-0 pb-0 mb-0">
            <small>{user.email}</small>
          </p>
          <p className="pt-0 pb-0 mb-0">
            <small>
              {user.phonenumber ? user.phonenumber.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, "+$1 ($2) $3-$4") : ""}
            </small>
          </p>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SupportCard;
