import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, FloatingLabel, Alert, Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { logout, verifyTwoFactor } from "../../services/httpClient";
import useApp from "../../utils/useApp";

const Authenticate = () => {
  const { auth, setAuth }: any = useApp();
  const [code, setCode] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [attempts, setAttempts] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  let navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    verifyTwoFactor(code)
      .then((res: any) => {
        setAuth({ ...auth, twofactor: true });
        console.log(res);
        navigate("/");
      })
      .catch((err: any) => {
        var _attempts = attempts + 1;
        setAttempts(_attempts);
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err?.response?.status === 418) {
          setErrMsg("Invalid Code. Attempt " + _attempts + "/3");
          if (_attempts === 3) {
            setTimeout(() => {
              handleLogout();
            }, 1000);
          }
        } else {
          setErrMsg("Login Failed");
        }
      });
  };

  const handleLogout = () => {
    logout()
      .then((res: any) => {
        localStorage.removeItem("auth");
        navigate("/login");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <div className="h-100 app-primary-alt-color align-content-center">
      <Row className="w-100 pb-5 d-flex align-item-center justify-content-center m-0">
        <Col xs={10} sm={8} md={6} lg={5} xl={4} xxl={4} className="pb-5">
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <Card className="shadow-sm rounded-lg d-block">
            <Image
              src="/AndGo_Logo_Color_Horizontal.png"
              fluid
              className="pt-5 px-5 pb-5"
              onClick={() => handleLogout()}
            />
            <Form onSubmit={handleSubmit}>
              <h6 className="text-center text-secondary mb-3 mx-3">
                Open your two-step verification app to get your verification code.
              </h6>
              <FloatingLabel label="Code" className="mx-3 mb-3 app-header-text">
                <Form.Control
                  type="text"
                  placeholder="Code"
                  id="user"
                  onChange={(e) => {
                    setCode(e.target.value);
                    setErrMsg("");
                  }}
                  value={code}
                  ref={inputRef}
                  required
                />
              </FloatingLabel>
              <div className="d-grid w-100">
                <Button className="button app-tertiary-color text-center mx-3 p-3" type="submit">
                  Authenticate
                </Button>
              </div>
              <Alert key="light" variant="light" className="p-2 text-center">
                <Alert.Link onClick={handleLogout}>Back to login</Alert.Link>
              </Alert>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Authenticate;
