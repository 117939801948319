import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

interface TranscriptState {
  transcript: any;
}

const FormattedTranscript = ({ transcript }: TranscriptState) => {
  const [formatted, setFormatted] = useState<any[]>([]);

  useEffect(() => {
    setFormatted(JSON.parse(transcript));
  }, [transcript]);

  return (
    <div style={{ height: "300px", overflowY: "auto", overflowX: "hidden" }}>
      {formatted.map((t: any) => (
        <Row>
          <Col className="col-1">
            <h6 key={t.speaker}>{t.speaker}:</h6>
          </Col>
          <Col className="col-10">
            <p>{t.line}</p>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default FormattedTranscript;
