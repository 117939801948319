import { faCheckCircle, faRefresh, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Badge, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { deleteTask, patchTask } from "../../../services/httpClient";
import { Task } from "../../../types/task";

interface TaskItemState {
  task: Task;
  goalArchived: boolean | number;
  goalCompleted: boolean | number;
  fetchGoals?: any;
  goalId?: string;
  setShowToast?: any;
  setToastText?: any;
  setToastBody?: any;
  inNextTasks?: boolean;
  myCase: boolean | undefined;
}

const TaskItem = ({
  task,
  goalArchived,
  goalCompleted,
  fetchGoals,
  goalId,
  setShowToast,
  setToastText,
  setToastBody,
  inNextTasks = false,
  myCase,
}: TaskItemState) => {
  const { id } = useParams();
  const [next, setNext] = useState<boolean>();

  useEffect(() => {
    setNext(task.is_next ? true : false);
  }, []);

  const handleCompletedClick = (taskId: any) => {
    if (goalArchived !== 1 && goalCompleted !== 1) {
      //if completed image marked true then update completed to true and reviewed to true
      //if completed image marked false then leave completed and make reviewed false
      var taskChanges = {};
      if (task.is_cm_reviewed !== 1) {
        taskChanges = {
          is_cm_reviewed: true,
          is_completed: task.is_completed === 1 ? true : false,
        };
      } else {
        taskChanges = {
          is_cm_reviewed: false,
          is_completed: false,
        };
      }

      patchTask(taskChanges, id, goalId, taskId).then((res: any) => {
        fetchGoals();
        setShowToast(true);
        if (task.is_cm_reviewed !== 1) {
          setToastText("Task Completed");
        } else {
          setToastText("Task Not Completed");
        }
        setToastBody(task.name);
      });
    }
  };

  const handleNextClick = (taskId: any) => {
    var toastText = "";
    var taskChanges = {};
    if (next === true) {
      toastText = "Next Task Removed";
      taskChanges = {
        is_next: false,
        is_completed: false,
      };
    } else {
      toastText = "Next Task Created";
      taskChanges = {
        is_next: true,
      };
    }

    const currentNext = next;
    setNext(!currentNext);

    patchTask(taskChanges, id, goalId, taskId)
      .then((res: any) => {
        fetchGoals();
        setShowToast(true);
        setToastText(toastText);
        setToastBody(task.name);
      })
      .catch((err: any) => {
        setNext(currentNext);
      });
  };

  const handleDeleteClick = (taskId: any) => {
    if (!task.is_completed) {
      deleteTask(id, goalId, taskId).then((res: any) => {
        fetchGoals();
        setShowToast(true);
        setToastText("Task Deleted");
        setToastBody(task.name);
      });
    }
  };

  const handleReturnClick = (taskId: any) => {
    const taskChanges = {
      is_next: true,
      is_completed: false,
    };

    patchTask(taskChanges, id, goalId, taskId).then((res: any) => {
      fetchGoals();
      setShowToast(true);
      setToastText("Return Task");
      setToastBody(task.name);
    });
  };

  return (
    <Row>
      <Col className={task.is_cm_reviewed ? "text-decoration-line-through app-green-text" : ""}>{task.name}</Col>
      {inNextTasks === true && (
        <Col className="text-end">
          {task.is_completed && task.is_cm_reviewed !== 1 ? (
            <Badge className="app-tertiary-color-inverse mx-3" bg="none">
              Needs Review
            </Badge>
          ) : (
            ""
          )}
          {task.age > 0 ? (
            <Badge className="app-tertiary-color-inverse me-3" bg="none">
              {task.age} Day(s)
            </Badge>
          ) : (
            ""
          )}
        </Col>
      )}
      {inNextTasks !== true && (
        <Col className="text-end">
          {task.is_completed && task.is_cm_reviewed !== 1 ? (
            <>
              <Badge className="me-3 app-tertiary-color" bg="none">
                Needs Review
              </Badge>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Complete Task</Tooltip>}
              >
                <FontAwesomeIcon
                  className={task.is_cm_reviewed ? "app-green-text cursor-pointer" : "text-muted me-3 cursor-pointer"}
                  icon={faCheckCircle}
                  onClick={() => handleCompletedClick(task.id)}
                />
              </OverlayTrigger>
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Return Task</Tooltip>}>
                <FontAwesomeIcon
                  className="text-muted cursor-pointer"
                  icon={faRefresh}
                  onClick={() => handleReturnClick(task.id)}
                />
              </OverlayTrigger>
            </>
          ) : (
            myCase && (
              <>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Complete Task</Tooltip>}
                >
                  <FontAwesomeIcon
                    className={task.is_cm_reviewed ? "app-green-text cursor-pointer" : "text-muted me-3 cursor-pointer"}
                    icon={faCheckCircle}
                    onClick={() => handleCompletedClick(task.id)}
                  />
                </OverlayTrigger>
                {goalArchived !== 1 && goalCompleted !== 1 && task.is_cm_reviewed !== 1 && (
                  <>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip>Next Task</Tooltip>}
                    >
                      <FontAwesomeIcon
                        className={next ? "app-orange-text me-3 cursor-pointer" : "text-muted me-3 cursor-pointer"}
                        icon={faStar}
                        onClick={() => handleNextClick(task.id)}
                      />
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip>Delete Task</Tooltip>}
                    >
                      <FontAwesomeIcon
                        className={task.is_completed ? "text-muted" : "text-muted cursor-pointer"}
                        icon={faTrash}
                        onClick={() => handleDeleteClick(task.id)}
                      />
                    </OverlayTrigger>
                  </>
                )}
              </>
            )
          )}
        </Col>
      )}
    </Row>
  );
};

export default TaskItem;
