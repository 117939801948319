import React, { useState } from "react";
import { Button, Card, Col, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deleteAnnouncement, patchAnnouncement, postAnnouncement } from "../../services/httpClient";
import { Announcement } from "../../types/announcement";

interface AnnounceTabState {
  type: string;
  announcements: Announcement[];
  getAnnouncements: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  setIsLoading: any;
}

const AnnouncementTab = ({
  type,
  announcements,
  getAnnouncements,
  setShowToast,
  setToastText,
  setToastBody,
  setIsLoading,
}: AnnounceTabState) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [annToDelete, setAnnToDelete] = useState<Announcement>();
  const currentDate = new Date();
  const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

  let navigate = useNavigate();

  const newAnnouncement = () => {
    navigate(`/announcements/announcement`);
  };

  const editAnnouncement = (e: any) => {
    navigate(`/announcements/announcement/${e}`);
  };

  const onClickLink = (e: any) => {
    window.open(e.event_url, "_blank");
  };

  const onClickCopy = (e: any) => {
    const tempDate = new Date();

    const newAnn = {
      name: e.name,
      description: e.description,
      event_at: tempDate,
      event_url: e.event_url,
      is_published: 0,
    };

    postAnnouncement(newAnn)
      .then((res: any) => {
        setShowToast(true);
        setToastText("Announcement Draft Created");
        const toast = e.name + "";
        setToastBody(toast);
        getAnnouncements();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const onClickDelete = (e: any) => {
    setIsDeleting(true);
    setAnnToDelete(e);
  };

  const onClickDeleteFinal = () => {
    setIsLoading(true);

    deleteAnnouncement(annToDelete?.id)
      .then((res: any) => {
        setIsDeleting(false);
        setAnnToDelete(undefined);
        setShowToast(true);
        setToastText("Announcement Deleted");
        setToastBody(annToDelete?.name);
        getAnnouncements();
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onClickCancelDelete = () => {
    setIsDeleting(false);
    setAnnToDelete(undefined);
  };

  const renderDelete = () => {
    return (
      <Card className="bg-light">
        <Card.Body>
          <Stack direction="horizontal" gap={3}>
            <Stack style={{ justifyContent: "center" }}>
              <Card.Subtitle>Are you sure you want to delete {annToDelete?.name}?</Card.Subtitle>
            </Stack>
            <Button className="app-danger-color button" onClick={() => onClickDeleteFinal()}>
              Confirm Delete
            </Button>
            <div className="vr" />
            <Button className="app-primary-color button" onClick={() => onClickCancelDelete()}>
              Cancel
            </Button>
          </Stack>
        </Card.Body>
      </Card>
    );
  };

  const onClickPublish = (e: any) => {
    setIsLoading(true);

    const updatedAnn = {
      name: e.name,
      is_published: 1,
    };

    patchAnnouncement(updatedAnn, e.id)
      .then((res: any) => {
        setShowToast(true);
        setToastText("Announcement Published");
        const toast = e.name + "";
        setToastBody(toast);
        getAnnouncements();
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };

  //used in delete tab
  // const onClickRestore = (e: any) => {
  //   const updatedAnn = {
  //     name: e.name,
  //     deleted_at: null,
  //     deleted_by: null,
  //   };

  //   patchAnnouncement(updatedAnn, e.id)
  //     .then((res: any) => {
  //       setShowToast(true);
  //       setToastText("Announcement Updated");
  //       const toast = e.name + "";
  //       setToastBody(toast);
  //       getAnnouncements();
  //     })
  //     .catch((err: any) => {
  //       console.log(err);
  //     });
  // };

  return (
    <Row>
      <Col>
        <Stack gap={3}>
          {isDeleting ? (
            <>{renderDelete()}</>
          ) : (
            <>
              <Row>
                <Col className="text-end col-12">
                  <Button className="app-primary-color button" onClick={newAnnouncement}>
                    Create Announcement
                  </Button>
                </Col>
              </Row>
              {announcements.map((item: any, index: any) => (
                <Card key={index} className={type === "working" ? "bg-light" : ""}>
                  <Card.Header>
                    <Row>
                      <Col>
                        <Card.Title className="mb-0 p-1 app-header-text">{item.name}</Card.Title>
                      </Col>
                      <Col>
                        <Card.Subtitle className={"text-muted text-end mt-auto pt-1"}>{item?.event_at}</Card.Subtitle>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="card-body m-0 p-2">{item?.description}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {item?.event_url && (
                          <Button
                            className="app-primary-color mt-auto mb-2 button-small ms-2 button"
                            onClick={(e) => onClickLink(item)}
                          >
                            Event Link
                          </Button>
                        )}
                      </Col>
                      <Col className="col">
                        <Stack direction="horizontal" gap={3} className="justify-content-end">
                          {type === "drafts" && (
                            <>
                              <Button
                                className="app-tertiary-color mt-auto mb-2 button"
                                onClick={(e) => onClickPublish(item)}
                                disabled={new Date(`${item?.event_at}T00:00:00`) < currentDateOnly ? true : false}
                              >
                                Publish
                              </Button>
                              <div className="vr mb-1" />

                              <Button
                                className="app-secondary-color mt-auto mb-2 button"
                                onClick={(e) => editAnnouncement(item.id)}
                              >
                                Edit
                              </Button>
                              <div className="vr mb-1" />
                              <Button
                                className="mt-auto mb-2 app-danger-color button"
                                onClick={(e) => onClickDelete(item)}
                              >
                                Delete
                              </Button>
                            </>
                          )}
                          {type === "expired" && (
                            <Button
                              className="app-secondary-color mt-auto mb-2 button"
                              onClick={(e) => onClickCopy(item)}
                            >
                              Copy to Drafts
                            </Button>
                          )}
                          {/* {type === "deleted" && (
                            <Button
                              className="app-secondary-color mt-auto mb-2 button"
                              onClick={(e) => onClickRestore(item)}
                            >
                              Restore
                            </Button>
                          )} */}
                        </Stack>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))}
            </>
          )}
        </Stack>
      </Col>
    </Row>
  );
};

export default AnnouncementTab;
