import React from "react";
import { Card, Col, Row, Image, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PasswordResetSent = () => {
  let navigate = useNavigate();

  const handleHome = () => {
    navigate("/");
  };

  return (
    <div className="h-100 app-primary-alt-color align-content-center">
      <Row className="w-100 pb-5 d-flex align-item-center justify-content-center m-0">
        <Col xs={10} sm={8} md={6} lg={5} xl={4} xxl={4} className="pb-5">
          <Card className="shadow-sm rounded-lg d-block">
            <Image
              src="/AndGo_Logo_Color_Horizontal.png"
              fluid
              className="pt-5 px-5 pb-5 cursor-pointer"
              onClick={() => handleHome()}
            />
            <h4 className="text-center app-header-text">Reset Password Sent</h4>
            <h6 className="text-secondary text-center mb-3">Please check your email for further instructions.</h6>
            <Alert key="light" variant="light" className="p-2 text-center">
              <Alert.Link onClick={handleHome}>Back to login</Alert.Link>
            </Alert>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PasswordResetSent;
