import React from "react";
import { Col, Row } from "react-bootstrap";
import Select from "./Select";

interface TimeDurationProps {
  hours: number;
  setHours: any;
  minutes: number;
  setMinutes: any;
  setSaveIsEnabled: any;
  setUserToSave: any;
}

const TimeDuration = ({ hours, setHours, minutes, setMinutes, setSaveIsEnabled, setUserToSave }: TimeDurationProps) => {
  const updateHour = (e: any) => {
    setHours(e.target.value);
    setSaveIsEnabled(true);
    setUserToSave(true);
  };

  const updateMin = (e: any) => {
    if (e.target.value < 60) {
      setMinutes(e.target.value);
    } else {
      const hours = Math.floor(e.target.value / 60);
      const mins = e.target.value % 60;

      setHours(hours);
      setMinutes(mins);
    }
    setSaveIsEnabled(true);
    setUserToSave(true);
  };

  const possibleHours: string[] = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  const possibleMins: string[] = ["0", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];

  return (
    <Row className="mt-3">
      <Col className="col-2">
        <Select
          label="Visit Hour"
          value={hours}
          options={possibleHours}
          onUpdate={(e: any) => updateHour(e)}
          showFirst={false}
        />
      </Col>
      <Col className="col-2">
        <Select
          label="Visit Min"
          value={minutes}
          options={possibleMins}
          onUpdate={(e: any) => updateMin(e)}
          showFirst={false}
        />
      </Col>
    </Row>
  );
};

export default TimeDuration;
