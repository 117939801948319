import React from "react";
import { Col, ListGroup, Row, Tab } from "react-bootstrap";

interface LikertState {
  value: any;
  setValue: any;
  beginText: string;
  endText: string;
  readOnly: boolean;
}

const LikertScale = ({ value, setValue, beginText, endText, readOnly = false }: LikertState) => {
  const onClickScale = (e: any) => {
    if (readOnly === false) {
      setValue(e);
    }
  };

  const LikertItem = ({ val, currentValue, readOnly }: any) => {
    return (
      <ListGroup.Item
        key={val}
        className="text-center"
        action
        href={"#link" + val}
        onClick={() => onClickScale(val)}
        active={val == currentValue ? true : false}
        disabled={readOnly}
      >
        {val}
      </ListGroup.Item>

      // href={"#link" + val}
    );
  };

  return (
    <Tab.Container>
      <Row className="gx-0">
        <Col className="col-12">
          <ListGroup horizontal>
            <LikertItem val={0} currentValue={value} readOnly={readOnly} />
            <LikertItem val={1} currentValue={value} readOnly={readOnly} />
            <LikertItem val={2} currentValue={value} readOnly={readOnly} />
            <LikertItem val={3} currentValue={value} readOnly={readOnly} />
            <LikertItem val={4} currentValue={value} readOnly={readOnly} />
            <LikertItem val={5} currentValue={value} readOnly={readOnly} />
            <LikertItem val={6} currentValue={value} readOnly={readOnly} />
            <LikertItem val={7} currentValue={value} readOnly={readOnly} />
            <LikertItem val={8} currentValue={value} readOnly={readOnly} />
            <LikertItem val={9} currentValue={value} readOnly={readOnly} />
            <LikertItem val={10} currentValue={value} readOnly={readOnly} />
          </ListGroup>
        </Col>
      </Row>
      <Row className="pt-2 pb-2 gx-0">
        <Col className="text-start text-muted">
          <h6>{beginText}</h6>
        </Col>
        <Col className="text-end text-muted">
          <h6>{endText}</h6>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default LikertScale;
