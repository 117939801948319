import React, { useEffect, useRef } from "react";
import { Form, ListGroup, ListGroupItem, Row } from "react-bootstrap";

interface RecipientState {
  message: any;
  addRef: any;
}

const RecipientMessage = ({ message, addRef }: RecipientState) => {
  const msgRef = useRef<null | HTMLDivElement>();

  useEffect(() => {
    if (addRef) {
      if (msgRef.current) {
        msgRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
  }, []);

  return (
    <>
      <ListGroupItem key={message.id} className="message p-0">
        <ListGroup className="receiver-list">
          <ListGroupItem className="message p-0">
            <p className="ps-2 m-0 receiver-text">
              {message.user_firstname} {message.user_lastname}
            </p>
            <Form.Label slot="start" className="text-wrap receiver mb-0">
              {message.body}
            </Form.Label>
            <p className="ps-2 m-0 receiver-text">{message.display_time}</p>
          </ListGroupItem>
        </ListGroup>
      </ListGroupItem>
      {addRef && <Row ref={msgRef} style={{ width: "80px", height: 0 }}></Row>}
    </>
  );
};

export default RecipientMessage;
