import React from "react";
import { Button, Card, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { deleteSupports } from "../../../services/httpClient";

interface RemoveState {
  setIsRemoving: any;
  user: any;
  fetchSupports: any;
  setEditMember: any;
  setCurrentMember: any;
}

const ConfirmRemove = ({ setIsRemoving, user, fetchSupports, setEditMember, setCurrentMember }: RemoveState) => {
  const { id } = useParams();

  const onClickRemoveFinal = () => {
    deleteSupports(id, user?.id).then((res: any) => {
      setIsRemoving(false);
      setEditMember(false);
      setCurrentMember(undefined);
      fetchSupports();
    });
  };

  const onClickCancelRemove = () => {
    setIsRemoving(false);
  };

  return (
    <Card className="bg-light mb-3">
      <Card.Body>
        <Stack direction="horizontal" gap={3}>
          <Stack style={{ justifyContent: "center" }}>
            <Card.Subtitle>Are you sure you want to remove {user?.firstname}?</Card.Subtitle>
          </Stack>
          <Button className="app-danger-color button" onClick={() => onClickRemoveFinal()}>
            Confirm Remove
          </Button>
          <div className="vr" />
          <Button className="app-primary-color button" onClick={() => onClickCancelRemove()}>
            Cancel
          </Button>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default ConfirmRemove;
