import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Case } from "../../types/case";
import { deleteCase, fetchCases } from "../../services/httpClient";
import { Button, Card, Col, Container, Row, Spinner, Stack } from "react-bootstrap";

interface CaseTabState {
  cases: Case[];
  setCases: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const CaseTab = ({ cases, setCases, setShowToast, setToastText, setToastBody }: CaseTabState) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [caseToDelete, setCaseToDelete] = useState<Case>();

  let navigate = useNavigate();

  const newCase = () => {
    navigate(`/cases/case`);
  };

  const editCase = (e: any) => {
    navigate(`/cases/case/${e}`);
  };

  const onClickDelete = (e: any) => {
    setIsDeleting(true);
    setCaseToDelete(e);
  };

  const onClickDeleteFinal = () => {
    setIsLoading(true);
    setIsDeleting(false);
    const caseName: string = caseToDelete?.name ? caseToDelete.name : "";

    deleteCase(caseToDelete?.id)
      .then((res: any) => {
        setIsDeleting(false);
        setCaseToDelete(undefined);
        fetchCases()
          .then((res: any) => {
            const cases = res[0].data;
            setCases(cases);
            setShowToast(true);
            setToastText("Case Deleted");
            setToastBody(caseName);
            setIsLoading(false);
          })
          .catch((err: any) => {
            console.log(err);
            setIsLoading(false);
          });
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onClickCancelDelete = () => {
    setIsDeleting(false);
    setCaseToDelete(undefined);
  };

  const renderDelete = () => {
    return (
      <Card className="bg-light">
        <Card.Body>
          <Stack direction="horizontal" gap={3}>
            <Stack style={{ justifyContent: "center" }}>
              <Card.Subtitle>Are you sure you want to delete {caseToDelete?.name}?</Card.Subtitle>
            </Stack>
            <Button className="app-danger-color button" onClick={() => onClickDeleteFinal()}>
              Confirm Delete
            </Button>
            <div className="vr" />
            <Button className="app-primary-color button" onClick={() => onClickCancelDelete()}>
              Cancel
            </Button>
          </Stack>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Row>
      <Col>
        <Stack gap={3}>
          {isDeleting ? (
            <>{renderDelete()}</>
          ) : isLoading ? (
            <Container className="div-main">
              <Button size="sm" className="spinner-button spinner-button-main">
                <Spinner animation="border" style={{ color: "#F6893D" }} />
              </Button>
            </Container>
          ) : (
            <>
              <Row>
                <Col className="text-end col-12">
                  <Button className="app-primary-color button" onClick={newCase}>
                    Create Case
                  </Button>
                </Col>
              </Row>
              {cases.map((item: any, index: any) => (
                <Card key={index} className="bg-light">
                  <Card.Body>
                    <Stack direction="horizontal" gap={3}>
                      <Stack className="cursor-pointer" onClick={(e) => editCase(item.id)}>
                        {item.disabled_at ? (
                          <Card.Subtitle className="text-muted">{item.name}</Card.Subtitle>
                        ) : (
                          <Card.Subtitle>{item.name}</Card.Subtitle>
                        )}
                        <Card.Subtitle className="text-muted mt-1">
                          {item.primaryCM === null && "Case Manager Unassigned"}
                        </Card.Subtitle>
                      </Stack>
                      <Button className="app-secondary-color button" onClick={(e) => editCase(item.id)}>
                        Edit
                      </Button>
                      <div className="vr" />
                      <Button className="app-danger-color button" onClick={(e) => onClickDelete(item)}>
                        Delete
                      </Button>
                    </Stack>
                  </Card.Body>
                </Card>
              ))}
            </>
          )}
        </Stack>
      </Col>
    </Row>
  );
};

export default CaseTab;
