import React from "react";
import { Col, ListGroup, Row, Tab } from "react-bootstrap";

interface ContinuumUIState {
  type: string;
  value: any;
  setValue: any;
  readOnly: boolean;
  inCrisisText: string;
  atRiskText: string;
  safeText: string;
  stableText: string;
  thrivingText: string;
}

const Continuum = ({
  type,
  value,
  setValue,
  readOnly = false,
  inCrisisText,
  atRiskText,
  safeText,
  stableText,
  thrivingText,
}: ContinuumUIState) => {
  const onClickScale = (e: any) => {
    if (readOnly === false) {
      setValue(e);
    }
  };

  const SelectItem = ({ val, text, currentValue, readOnly }: any) => {
    return (
      <ListGroup.Item
        key={val}
        className="text-center select-item px-1"
        action
        href={"#link" + val}
        onClick={() => onClickScale(val)}
        active={val === currentValue ? true : false}
        disabled={readOnly}
      >
        {text}
      </ListGroup.Item>
    );
  };

  return (
    <Tab.Container>
      <Row className="mt-3">
        <h6>{type}</h6>
      </Row>
      <Row className="gx-0">
        <Col className="col-12">
          <ListGroup horizontal>
            <SelectItem val="In-Crisis" text={inCrisisText} currentValue={value} readOnly={readOnly} />
            <SelectItem val="At-Risk" text={atRiskText} currentValue={value} readOnly={readOnly} />
            <SelectItem val="Safe" text={safeText} currentValue={value} readOnly={readOnly} />
            <SelectItem val="Stable" text={stableText} currentValue={value} readOnly={readOnly} />
            <SelectItem val="Thriving" text={thrivingText} currentValue={value} readOnly={readOnly} />
          </ListGroup>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default Continuum;
