import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, FloatingLabel, Spinner, Card, Alert, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { sendPasswordReset } from "../../services/httpClient";

const SendPasswordReset = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  let navigate = useNavigate();

  const handleHome = () => {
    navigate("/");
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    sendPasswordReset(email)
      .then(() => {
        navigate("/reset-sent");
        setIsLoading(false);
      })
      .catch((err: any) => {
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err?.response?.status === 400) {
          setErrMsg("Email Does Not Exist");
        } else {
          setErrMsg("Reset Failed");
        }
        setIsLoading(false);
      });
  };

  return (
    <div className="h-100 app-primary-alt-color align-content-center">
      {isLoading ? (
        <Row className="w-100 pb-5 d-flex align-item-center justify-content-center m-0">
          <Col xs={10} sm={8} md={6} lg={5} xl={4} xxl={4} className="pb-5 text-center">
            <Button size="sm" className="spinner-button">
              <Spinner animation="border" style={{ color: "#F6893D" }} />
            </Button>
          </Col>
        </Row>
      ) : (
        <Row className="w-100 pb-5 d-flex align-item-center justify-content-center m-0">
          <Col xs={10} sm={8} md={6} lg={5} xl={4} xxl={4} className="pb-5">
            <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
              {errMsg}
            </p>
            <Card className="shadow-sm rounded-lg d-block">
              <Image
                src="/AndGo_Logo_Color_Horizontal.png"
                fluid
                className="pt-5 px-5 pb-5 cursor-pointer"
                onClick={() => handleHome()}
              />
              <Form onSubmit={handleSubmit}>
                <h4 className="text-center app-header-text">Reset Password</h4>
                <h6 className="text-secondary text-center mb-3 mx-3">
                  We will send you an email to reset your password.
                </h6>
                <FloatingLabel label="Email Address" className="mx-3 mb-3 app-header-text">
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    id="user"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    ref={inputRef}
                    required
                  />
                </FloatingLabel>
                <div className="d-grid w-100">
                  <Button className="button app-tertiary-color text-center mx-3 p-3" type="submit">
                    Send Email
                  </Button>
                </div>
                <Alert key="light" variant="light" className="p-2 text-center">
                  <Alert.Link onClick={handleHome}>Back to login</Alert.Link>
                </Alert>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SendPasswordReset;
