import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Col, Form, Row, FloatingLabel, Card, Image, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/httpClient";
import { isAndroid, isIOS } from "react-device-detect";

const PasswordReset = ({ mode }: any) => {
  const [resetCode] = useSearchParams();
  const [emailPhone, setEmailPhone] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [passwordSaved, setPasswordSaved] = useState<boolean>(false);
  const [ruleLength, setRuleLength] = useState(false);
  const [ruleUpper, setRuleUpper] = useState(false);
  const [ruleLower, setRuleLower] = useState(false);
  const [ruleNumber, setRuleNumber] = useState(false);
  const [ruleSymbol, setRuleSymbol] = useState(false);
  const [ruleMatch, setRuleMatch] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  let navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!ruleMatch) {
      setErrMsg("Password Fields Must Match");
    } else if (!ruleLength || !ruleUpper || !ruleLower || !ruleNumber || !ruleSymbol) {
      setErrMsg("Password Does Not Match a Rule");
    } else {
      resetPassword(resetCode.get("resetcode")!, emailPhone, newPassword).then(() => {
        setPasswordSaved(true);
        setErrMsg("");
      });
    }
  };

  const handleLogin = (e: any) => {
    e.preventDefault();

    if (isIOS) {
      window.location.href = "selflyft://";
    } else if (isAndroid) {
      window.location.href = "selflyft://";
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    var currentTestValue = false;

    if (newPassword.length >= 8) {
      setRuleLength(true);
    } else {
      setRuleLength(false);
    }

    currentTestValue = /[A-Z]/.test(newPassword);
    if (currentTestValue) {
      setRuleUpper(true);
    } else {
      setRuleUpper(false);
    }

    currentTestValue = /[a-z]/.test(newPassword);
    if (currentTestValue) {
      setRuleLower(true);
    } else {
      setRuleLower(false);
    }

    currentTestValue = /[0-9]/.test(newPassword);
    if (currentTestValue) {
      setRuleNumber(true);
    } else {
      setRuleNumber(false);
    }

    currentTestValue = /[^\w\s]|[_]/.test(newPassword);
    if (currentTestValue) {
      setRuleSymbol(true);
    } else {
      setRuleSymbol(false);
    }
  }, [newPassword]);

  useEffect(() => {
    if (newPassword === confirmPassword && newPassword.length > 0) {
      setRuleMatch(true);
    } else {
      setRuleMatch(false);
    }
  }, [newPassword, confirmPassword]);

  return (
    <div className="h-100 app-primary-alt-color align-content-center">
      <Row className="w-100 pb-5 d-flex align-item-center justify-content-center m-0">
        <Col xs={10} sm={8} md={6} lg={5} xl={4} xxl={4} className="pb-5">
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <Card className="shadow-sm rounded-lg d-block">
            <Image src="/AndGo_Logo_Color_Horizontal.png" fluid className="pt-5 px-5 pb-5" />
            {passwordSaved ? (
              <>
                <h4 className="text-center app-header-text">Password Changed!</h4>
                <Alert key="light" variant="light" className="p-2 text-center">
                  <Alert.Link onClick={handleLogin}>Back to login</Alert.Link>
                </Alert>
              </>
            ) : (
              <Form onSubmit={handleSubmit}>
                <h4 className="text-center app-header-text">Reset Password</h4>
                <FloatingLabel
                  label={mode === "mobile" ? "Email or Phone Number" : "Email Address"}
                  className="mx-3 mb-3 app-header-text"
                >
                  <Form.Control
                    type="text"
                    placeholder={mode === "mobile" ? "Email or Phone Number" : "Email Address"}
                    id="email"
                    onChange={(e) => setEmailPhone(e.target.value)}
                    ref={inputRef}
                    required
                  />
                </FloatingLabel>
                <FloatingLabel label="New Password" className="mx-3 mb-3 app-header-text">
                  <Form.Control
                    type="password"
                    placeholder="New Password"
                    id="newPassword"
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </FloatingLabel>
                <FloatingLabel label="Confirm Password" className="mx-3 mb-2 app-header-text">
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    id="confirmPassword"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </FloatingLabel>
                <div className="ms-3">
                  <Form.Text className={ruleLength ? "password-valid" : "password-invalid"}>
                    Password length of at least 8 characters
                  </Form.Text>
                  <br />
                  <Form.Text className={ruleUpper ? "password-valid" : "password-invalid"}>
                    Password includes at least 1 uppercase character
                  </Form.Text>
                  <br />
                  <Form.Text className={ruleLower ? "password-valid" : "password-invalid"}>
                    Password includes at least 1 lowercase character
                  </Form.Text>
                  <br />
                  <Form.Text className={ruleNumber ? "password-valid" : "password-invalid"}>
                    Password includes at least 1 number
                  </Form.Text>
                  <br />
                  <Form.Text className={ruleSymbol ? "password-valid" : "password-invalid"}>
                    Password includes at least 1 symbol
                  </Form.Text>
                  <br />
                  <Form.Text className={ruleMatch ? "password-valid" : "password-invalid"}>Passwords match</Form.Text>
                </div>
                <div className="d-grid w-100 my-3">
                  <Button className="button app-tertiary-color text-center mx-3 p-3" type="submit">
                    Save Password
                  </Button>
                </div>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PasswordReset;
