import React, { createContext, useEffect, useState } from "react";
import { act } from "react-dom/test-utils";

const AppContext = createContext({});

export const AppContextProvider = ({ children }: any) => {
  const authLocal = JSON.parse(localStorage.getItem("auth") || "{}");
  const menuLocal = localStorage.getItem("menu") || "false";
  const viewAllLocal = localStorage.getItem("viewall") || "false";

  const [auth, setAuth] = useState(authLocal);
  const [showMenu, setShowMenu] = useState<boolean>(menuLocal === "true" ? true : false);
  const [activePage, setActivePage] = useState<string>("");
  const [viewAllCases, setViewAllCases] = useState<boolean>(viewAllLocal === "true" ? true : false);

  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(auth));
  }, [auth]);

  useEffect(() => {
    localStorage.setItem("menu", showMenu ? "true" : "false");
  }, [showMenu]);

  useEffect(() => {
    localStorage.setItem("viewall", viewAllCases ? "true" : "false");
  }, [viewAllCases]);

  return (
    <AppContext.Provider
      value={{ auth, setAuth, showMenu, setShowMenu, viewAllCases, setViewAllCases, activePage, setActivePage }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
