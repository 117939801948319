import React from "react";
import { Col, Row } from "react-bootstrap";
import SelectObject from "../ui/SelectObject";

const WellBeingQuestions = ({ question, setQuestion }: any) => {
  const questions = [
    {
      key: "one",
      name: "Overall, how satisfied are you with life as a whole these days?",
    },
    {
      key: "two",
      name: "In general, how happy or unhappy do you usually feel?",
    },
    {
      key: "three",
      name: "In general, how would you rate your physical health?",
    },
    {
      key: "four",
      name: "How would you rate your overall mental health?",
    },
    {
      key: "five",
      name: "Overall, to what extent do you feel the things you do in your life are worthwhile?",
    },
    {
      key: "six",
      name: "I understand my purpose in life.",
    },
    {
      key: "seven",
      name: "I always act to promote good in all circumstances, even in difficult and challenging situations.",
    },
    {
      key: "eight",
      name: "I am always able to give up some happiness now for greater happiness later.",
    },
    {
      key: "nine",
      name: "I am content with my friendships and relationships.",
    },
    {
      key: "ten",
      name: "My relationships are as satisfying as I would want them to be.",
    },
    {
      key: "eleven",
      name: "How often do you worry about being able to meet normal monthly living expenses?",
    },
    {
      key: "twelve",
      name: "How often do you worry about safety, food, or housing?",
    },
  ];

  return (
    <Row>
      <Col></Col>
      <Col className="col-8">
        <SelectObject
          label="Question"
          value={question}
          options={questions}
          onUpdate={(e: any) => setQuestion(e.target.value)}
        />
      </Col>
      <Col></Col>
    </Row>
  );
};

export default WellBeingQuestions;
