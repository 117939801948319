import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faFileText,
  faFile,
  faClipboardQuestion,
  faBullseye,
  faUserFriends,
  faCompress,
  faExpand,
  faChartColumn,
  faUsers,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Container, Nav, OverlayTrigger, Row, Spinner, Tab, Tooltip } from "react-bootstrap";
import PageTitle from "../ui/PageTitle";
import Assessments from "./assessments/Assessments";
import Documents from "./documents/Documents";
import Goals from "./goals/Goals";
import Messages from "./messages/Messages";
import Notes from "./notes/Notes";
import SupportUsers from "./support/SupportUsers";
import MyCaseTab from "./MyCaseTab";
import { useLocation, useParams } from "react-router-dom";
import { fetchUserCase, patchUserCase } from "../../services/httpClient";
import { User } from "../../types/user";
import CaseReports from "./reports/CaseReports";
import HouseholdUsers from "./household/HouseholdUsers";
import Groups from "./groups/Groups";
import useApp from "../../utils/useApp";
import { unsavedChanges, currentUrl } from "../../App";
import DatePicker from "../ui/DatePicker";
import ToastMsg from "../ui/ToastMsg";
import { trackNavigationEvent } from "../../utils/analytics";

const MyCase = () => {
  const location = useLocation();
  currentUrl.value = location.pathname;
  const { id } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const { auth, showMenu }: any = useApp();
  const [navKey, setNavKey] = useState<string>("household");
  //set active states
  const [activeHousehold, setActiveHousehold] = useState(false);
  const [activeNotes, setActiveNotes] = useState(false);
  const [activeMessages, setActiveMessages] = useState(false);
  const [activeGoals, setActiveGoals] = useState(false);
  const [activeAssessment, setActiveAssessment] = useState(false);
  const [activeGroups, setActiveGroups] = useState(false);
  const [activeSupport, setActiveSupport] = useState(false);
  const [activeDocuments, setActiveDocuments] = useState(false);
  const [activeReports, setActiveReports] = useState(false);
  //get active tab data states
  const [getHouseholdData, setGetHouseholdData] = useState<boolean>(false);
  const [getNotesData, setGetNotesData] = useState(false);
  const [getMessagesData, setGetMessagesData] = useState(false);
  const [getGoalsData, setGetGoalsData] = useState(false);
  const [getAssessmentData, setGetAssessmentData] = useState(false);
  const [getGroupsData, setGetGroupsData] = useState(false);
  const [getSupportData, setGetSupportData] = useState(false);
  const [getDocumentsData, setGetDocumentsData] = useState(false);
  const [getReportsData, setGetReportsData] = useState(false);

  const [toggleMenu, setToggleMenu] = useState(false);
  const [width, setWidth] = useState(useWindowWidth());
  const [name, setName] = useState<string>();
  const [meetingDate, setMeetingDate] = useState<string>();
  const [addressOne, setAddressOne] = useState<string>("");
  const [addressTwo, setAddressTwo] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [primaryCM, setPrimaryCM] = useState<any>();
  const [secondaryCM, setSecondaryCM] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [myCase, setMyCase] = useState<boolean>(false);
  const [newMessage, setNewMessage] = useState<boolean>(false);
  const [userToSave, setUserToSave] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");
  const [errMsg, setErrMsg] = useState("");

  //household states

  //note states
  const [createNote, setCreateNote] = useState<boolean>(false);
  const [viewNotes, setViewNotes] = useState<boolean>(true);
  const [editNote, setEditNote] = useState<boolean>(false);

  //messages states
  const [currentConversation, setCurrentConversation] = useState<any>({
    id: 0,
    name: "",
    participants: [auth?.profile.id],
  });

  //support states
  const [editUser, setEditUser] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User>();
  const [addSupport, setAddSupport] = useState<boolean>(false);

  //assessment states
  const [viewAssessments, setViewAssessments] = useState(true);
  const [createAssessmentOne, setCreateAssessmentOne] = useState<boolean>(false);
  const [createAssessmentTwo, setCreateAssessmentTwo] = useState<boolean>(false);
  const [viewAssessmentOne, setViewAssessmentOne] = useState<boolean>(false);
  const [viewAssessmentTwo, setViewAssessmentTwo] = useState<boolean>(false);

  //goal states
  const [viewGoals, setViewGoals] = useState<boolean>(true);
  const [viewCompleted, setViewCompleted] = useState<boolean>(false);
  const [viewArchive, setViewArchive] = useState<boolean>(false);
  const [createGoal, setCreateGoal] = useState<boolean>(false);

  //group states

  useEffect(() => {
    const navKey = queryParams.get("key");
    if (navKey) {
      setNavKey(navKey);
      onNavClick(navKey);
    } else {
      onNavClick("household");
    }

    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    setIsLoading(true);

    fetchUserCase(id)
      .then((res: any) => {
        if (
          auth?.profile?.id === res[0].data?.primaryCM?.user_id ||
          auth?.profile?.id === res[0].data?.secondaryCM?.user_id
        ) {
          setMyCase(true);
        }

        setName(res[0].data.name);
        setMeetingDate(res[0].data.next_meeting_at);
        setAddressOne(res[0].data.address_line_one);
        setAddressTwo(res[0].data.address_line_two);
        setCity(res[0].data.city);
        setState(res[0].data.state);
        setZip(res[0].data.zip);
        setPrimaryCM(res[0].data?.primaryCM);
        setSecondaryCM(res[0].data?.secondaryCM);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (width) {
      if (width <= 576) {
        setToggleMenu(true);
      } else if (width <= 992) {
        setToggleMenu(false);
      } else {
        setToggleMenu(true);
      }
    } else {
      setToggleMenu(true);
    }
  }, [width]);

  useEffect(() => {
    if (userToSave) {
      unsavedChanges.value = true;
    } else {
      unsavedChanges.value = false;
    }
  }, [userToSave]);

  const onNavClick = (route: string | null) => {
    if (route) {
      trackNavigationEvent("switch_view", "mycase_" + route);
    }

    switch (route) {
      case "household":
        //set active tab
        setActiveHousehold(true);
        setActiveNotes(false);
        setActiveMessages(false);
        setActiveGoals(false);
        setActiveAssessment(false);
        setActiveGroups(false);
        setActiveSupport(false);
        setActiveDocuments(false);
        setActiveReports(false);

        //get active tab data
        setGetHouseholdData(true);
        setGetNotesData(false);
        setGetMessagesData(false);
        setGetGoalsData(false);
        setGetAssessmentData(false);
        setGetGroupsData(false);
        setGetSupportData(false);
        setGetDocumentsData(false);
        setGetReportsData(false);
        break;
      case "notes":
        //set active tab
        setActiveHousehold(false);
        setActiveNotes(true);
        setActiveMessages(false);
        setActiveGoals(false);
        setActiveAssessment(false);
        setActiveGroups(false);
        setActiveSupport(false);
        setActiveDocuments(false);
        setActiveReports(false);

        //get active tab data
        setGetHouseholdData(false);
        setGetNotesData(true);
        setGetMessagesData(false);
        setGetGoalsData(false);
        setGetAssessmentData(false);
        setGetGroupsData(false);
        setGetSupportData(false);
        setGetDocumentsData(false);
        setGetReportsData(false);

        //note states
        setCreateNote(false);
        setViewNotes(true);
        setEditNote(false);
        break;
      case "messages":
        //set active tab
        setActiveHousehold(false);
        setActiveNotes(false);
        setActiveMessages(true);
        setActiveGoals(false);
        setActiveAssessment(false);
        setActiveGroups(false);
        setActiveSupport(false);
        setActiveDocuments(false);
        setActiveReports(false);

        //get active tab data
        setGetHouseholdData(false);
        setGetNotesData(false);
        setGetMessagesData(true);
        setGetGoalsData(false);
        setGetAssessmentData(false);
        setGetGroupsData(false);
        setGetSupportData(false);
        setGetDocumentsData(false);
        setGetReportsData(false);
        break;
      case "goals":
        //set active tab
        setActiveHousehold(false);
        setActiveNotes(false);
        setActiveMessages(false);
        setActiveGoals(true);
        setActiveAssessment(false);
        setActiveGroups(false);
        setActiveSupport(false);
        setActiveDocuments(false);
        setActiveReports(false);

        //get active tab data
        setGetHouseholdData(false);
        setGetNotesData(false);
        setGetMessagesData(false);
        setGetGoalsData(true);
        setGetAssessmentData(false);
        setGetGroupsData(false);
        setGetSupportData(false);
        setGetDocumentsData(false);
        setGetReportsData(false);

        //goal states
        setViewGoals(true);
        setViewCompleted(false);
        setViewArchive(false);
        setCreateGoal(false);
        break;
      case "assessment":
        //set active tab
        setActiveHousehold(false);
        setActiveNotes(false);
        setActiveMessages(false);
        setActiveGoals(false);
        setActiveAssessment(true);
        setActiveGroups(false);
        setActiveSupport(false);
        setActiveDocuments(false);
        setActiveReports(false);

        //get active tab data
        setGetHouseholdData(false);
        setGetNotesData(false);
        setGetMessagesData(false);
        setGetGoalsData(false);
        setGetAssessmentData(true);
        setGetGroupsData(false);
        setGetSupportData(false);
        setGetDocumentsData(false);
        setGetReportsData(false);

        //assessment states
        setViewAssessments(true);
        setCreateAssessmentOne(false);
        setCreateAssessmentTwo(false);
        setViewAssessmentOne(false);
        setViewAssessmentTwo(false);
        break;
      case "groups":
        //set active tab
        setActiveHousehold(false);
        setActiveNotes(false);
        setActiveMessages(false);
        setActiveGoals(false);
        setActiveAssessment(false);
        setActiveGroups(true);
        setActiveSupport(false);
        setActiveDocuments(false);
        setActiveReports(false);

        //get active tab data
        setGetHouseholdData(false);
        setGetNotesData(false);
        setGetMessagesData(false);
        setGetGoalsData(false);
        setGetAssessmentData(false);
        setGetGroupsData(true);
        setGetSupportData(false);
        setGetDocumentsData(false);
        setGetReportsData(false);
        break;
      case "support":
        //set active tab
        setActiveHousehold(false);
        setActiveNotes(false);
        setActiveMessages(false);
        setActiveGoals(false);
        setActiveAssessment(false);
        setActiveGroups(false);
        setActiveSupport(true);
        setActiveDocuments(false);
        setActiveReports(false);

        //get active tab data
        setGetHouseholdData(false);
        setGetNotesData(false);
        setGetMessagesData(false);
        setGetGoalsData(false);
        setGetAssessmentData(false);
        setGetGroupsData(false);
        setGetSupportData(true);
        setGetDocumentsData(false);
        setGetReportsData(false);

        //support states
        setEditUser(false);
        setCurrentUser(undefined);
        setAddSupport(false);
        break;
      case "documents":
        //set active tab
        setActiveHousehold(false);
        setActiveNotes(false);
        setActiveMessages(false);
        setActiveGoals(false);
        setActiveAssessment(false);
        setActiveGroups(false);
        setActiveSupport(false);
        setActiveDocuments(true);
        setActiveReports(false);

        //get active tab data
        setGetHouseholdData(false);
        setGetNotesData(false);
        setGetMessagesData(false);
        setGetGoalsData(false);
        setGetAssessmentData(false);
        setGetGroupsData(false);
        setGetSupportData(false);
        setGetDocumentsData(true);
        setGetReportsData(false);
        break;
      case "reports":
        //set active tab
        setActiveHousehold(false);
        setActiveNotes(false);
        setActiveMessages(false);
        setActiveGoals(false);
        setActiveAssessment(false);
        setActiveGroups(false);
        setActiveSupport(false);
        setActiveDocuments(false);
        setActiveReports(true);

        //get active tab data
        setGetHouseholdData(false);
        setGetNotesData(false);
        setGetMessagesData(false);
        setGetGoalsData(false);
        setGetAssessmentData(false);
        setGetGroupsData(false);
        setGetSupportData(false);
        setGetDocumentsData(false);
        setGetReportsData(true);
        break;
      default:
        setActiveHousehold(false);
        setActiveNotes(false);
        setActiveDocuments(false);
        setActiveSupport(false);
        setActiveAssessment(false);
        setActiveGoals(false);
        setActiveMessages(false);
        setActiveReports(false);
        setActiveGroups(false);
        setGetHouseholdData(false);
        setGetNotesData(false);
        setGetMessagesData(false);
        setGetGoalsData(false);
        setGetAssessmentData(false);
        setGetGroupsData(false);
        setGetSupportData(false);
        setGetDocumentsData(false);
        setGetReportsData(false);
        break;
    }
  };

  function useWindowWidth() {
    const [windowSize, setWindowSize] = useState<number>();

    useEffect(() => {
      function handleResize() {
        setWindowSize(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  }

  const onUpdateMeetingDate = (e: any) => {
    const updatedCase = {
      next_meeting_at: e,
    };

    patchUserCase(updatedCase, id)
      .then((res: any) => {
        setShowToast(true);
        setToastText("Case Updated");
        setToastBody("Meeting Date");
      })
      .catch((err: any) => {
        setErrMsg(err?.response?.data?.msg);
      });
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container className="div-main">
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "md div-main-menu custom-container" : "md div-main custom-container"}>
          <Row className="justify-content-start">
            <Tab.Container defaultActiveKey={navKey}>
              <Col sm={toggleMenu ? 3 : 1}>
                <Nav
                  variant="pills"
                  className={
                    showMenu
                      ? "flex-column sticky-top mycase-menu-open-sticky"
                      : "flex-column sticky-top mycase-menu-sticky"
                  }
                >
                  <Nav.Item>
                    <PageTitle title={name} type={toggleMenu ? "page" : "section"} />
                  </Nav.Item>
                  <Nav.Item className={toggleMenu ? "d-block" : "d-grid"}>
                    <Nav.Link
                      eventKey="household"
                      className={activeHousehold ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("household")}
                      disabled={userToSave ? true : false}
                    >
                      <>
                        <FontAwesomeIcon icon={faUserFriends} className={toggleMenu ? "me-2" : "me-0"} />
                        {toggleMenu ? "Household" : ""}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={toggleMenu ? "d-block" : "d-grid"}>
                    <Nav.Link
                      eventKey="notes"
                      className={activeNotes ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("notes")}
                      disabled={userToSave ? true : false}
                    >
                      <>
                        <FontAwesomeIcon icon={faFileText} className={toggleMenu ? "me-2" : "me-0"} />
                        {toggleMenu ? "Notes" : ""}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={toggleMenu ? "d-block" : "d-grid"}>
                    <Nav.Link
                      eventKey="messages"
                      className={activeMessages ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("messages")}
                      disabled={userToSave ? true : false}
                    >
                      <>
                        <FontAwesomeIcon
                          icon={faComment}
                          className={toggleMenu ? "me-2" : newMessage ? "me-0 app-orange-text" : "me-0"}
                        />
                        {toggleMenu ? "Messages" : ""}
                        {newMessage && toggleMenu && !activeMessages && (
                          <Badge bg="tertiary" className="app-tertiary-color ms-2">
                            New
                          </Badge>
                        )}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={toggleMenu ? "d-block" : "d-grid"}>
                    <Nav.Link
                      eventKey="goals"
                      className={activeGoals ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("goals")}
                      disabled={userToSave ? true : false}
                    >
                      <>
                        <FontAwesomeIcon icon={faBullseye} className={toggleMenu ? "me-2" : "me-0"} />
                        {toggleMenu ? "Goals" : ""}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={toggleMenu ? "d-block" : "d-grid"}>
                    <Nav.Link
                      eventKey="assessment"
                      className={activeAssessment ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("assessment")}
                      disabled={userToSave ? true : false}
                    >
                      <>
                        <FontAwesomeIcon icon={faClipboardQuestion} className={toggleMenu ? "me-2" : "me-0"} />
                        {toggleMenu ? "Assessments" : ""}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={toggleMenu ? "d-block" : "d-grid"}>
                    <Nav.Link
                      eventKey="groups"
                      className={activeGroups ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("groups")}
                      disabled={userToSave ? true : false}
                    >
                      <>
                        <FontAwesomeIcon icon={faUsers} className={toggleMenu ? "me-2" : "me-0"} />
                        {toggleMenu ? "Groups" : ""}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={toggleMenu ? "d-block" : "d-grid"}>
                    <Nav.Link
                      eventKey="support"
                      className={activeSupport ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("support")}
                      disabled={userToSave ? true : false}
                    >
                      <>
                        <FontAwesomeIcon icon={faUserTie} className={toggleMenu ? "me-2" : "me-0"} />
                        {toggleMenu ? "Support" : ""}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={toggleMenu ? "d-block" : "d-grid"}>
                    <Nav.Link
                      eventKey="documents"
                      className={activeDocuments ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("documents")}
                      disabled={userToSave ? true : false}
                    >
                      <>
                        <FontAwesomeIcon icon={faFile} className={toggleMenu ? "me-2" : "me-0"} />
                        {toggleMenu ? "Documents" : ""}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={toggleMenu ? "d-block" : "d-grid"}>
                    <Nav.Link
                      eventKey="reports"
                      className={activeReports ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("reports")}
                      disabled={userToSave ? true : false}
                    >
                      <>
                        <FontAwesomeIcon icon={faChartColumn} className={toggleMenu ? "me-2" : "me-0"} />
                        {toggleMenu ? "Reports" : ""}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip>Collapse/Expand</Tooltip>}
                    >
                      <Button
                        onClick={() => setToggleMenu(!toggleMenu)}
                        className="button app-tertiary-color mb-2 mt-2"
                      >
                        {toggleMenu ? (
                          <FontAwesomeIcon icon={faCompress} />
                        ) : (
                          <FontAwesomeIcon icon={faExpand} className="me-0" />
                        )}
                      </Button>
                    </OverlayTrigger>
                  </Nav.Item>
                </Nav>
                {toggleMenu && (
                  <Col
                    className={
                      showMenu
                        ? "flex-column sticky-top mycase-date-open-sticky mt-3"
                        : "flex-column sticky-top mycase-date-sticky mt-3"
                    }
                  >
                    <Card className="bg-light">
                      <Card.Body>
                        <Col className="px-3 py-1">
                          <DatePicker
                            label="Next Meeting"
                            currentValue={meetingDate}
                            setCurrentValue={setMeetingDate}
                            displayOnly={true}
                            onUpdate={(e: any) => onUpdateMeetingDate(e)}
                          />
                        </Col>
                        <div className="px-3 py-2">
                          {addressOne && addressOne}
                          {addressOne && <br />}
                          {addressTwo && addressTwo}
                          {addressTwo && <br />}
                          {city && state && city + ", " + state + "  " + zip}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              </Col>
              <Col sm={toggleMenu ? 9 : 11} className="mt-2">
                <Tab.Content>
                  <Tab.Pane eventKey="household">
                    <MyCaseTab
                      component={
                        <HouseholdUsers
                          myCase={myCase}
                          userToSave={userToSave}
                          setUserToSave={setUserToSave}
                          showModal={showModal}
                          setShowModal={setShowModal}
                          getData={getHouseholdData}
                        />
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="notes">
                    <MyCaseTab
                      component={
                        <Notes
                          createNote={createNote}
                          setCreateNote={setCreateNote}
                          viewNotes={viewNotes}
                          setViewNotes={setViewNotes}
                          editNote={editNote}
                          setEditNote={setEditNote}
                          myCase={myCase}
                          setUserToSave={setUserToSave}
                          showModal={showModal}
                          setShowModal={setShowModal}
                          getData={getNotesData}
                        />
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="messages">
                    <MyCaseTab
                      component={
                        <Messages
                          currentConversation={currentConversation}
                          setCurrentConversation={setCurrentConversation}
                          setNewMessage={setNewMessage}
                          primaryCM={primaryCM}
                          secondaryCM={secondaryCM}
                          getData={getMessagesData}
                          myCase={myCase}
                        />
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="goals">
                    <MyCaseTab
                      component={
                        <Goals
                          viewGoals={viewGoals}
                          setViewGoals={setViewGoals}
                          viewCompleted={viewCompleted}
                          setViewCompleted={setViewCompleted}
                          viewArchive={viewArchive}
                          setViewArchive={setViewArchive}
                          createGoal={createGoal}
                          setCreateGoal={setCreateGoal}
                          getData={getGoalsData}
                          myCase={myCase}
                        />
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="assessment">
                    <MyCaseTab
                      component={
                        <Assessments
                          viewAssessments={viewAssessments}
                          setViewAssessments={setViewAssessments}
                          createAssessmentOne={createAssessmentOne}
                          setCreateAssessmentOne={setCreateAssessmentOne}
                          createAssessmentTwo={createAssessmentTwo}
                          setCreateAssessmentTwo={setCreateAssessmentTwo}
                          viewAssessmentOne={viewAssessmentOne}
                          setViewAssessmentOne={setViewAssessmentOne}
                          viewAssessmentTwo={viewAssessmentTwo}
                          setViewAssessmentTwo={setViewAssessmentTwo}
                          getData={getAssessmentData}
                          myCase={myCase}
                        />
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="groups">
                    <MyCaseTab component={<Groups getData={getGroupsData} />} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="support">
                    <MyCaseTab
                      component={
                        <SupportUsers
                          editUser={editUser}
                          setEditUser={setEditUser}
                          currentUser={currentUser}
                          setCurrentUser={setCurrentUser}
                          addSupport={addSupport}
                          setAddSupport={setAddSupport}
                          getData={getSupportData}
                          myCase={myCase}
                        />
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="documents">
                    <MyCaseTab
                      component={<Documents getData={getDocumentsData} myCase={myCase} setUserToSave={setUserToSave} />}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="reports">
                    <MyCaseTab component={<CaseReports getData={getReportsData} />} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Row>
        </Container>
      )}
    </>
  );
};

export default MyCase;
