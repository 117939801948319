import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faClose,
  faFileText,
  faPlay,
  faStop,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, OverlayTrigger, Tooltip, ButtonGroup, Row, Col } from "react-bootstrap";
import FormattedTranscript from "./FormattedTranscript";
import { fetchNoteRecordingData } from "../../../services/httpClient";
import { trackFeatureEvent } from "../../../utils/analytics";

interface RecordingItemState {
  caseId: string | undefined;
  note: any;
  recording: any;
}

const RecordingItem = ({ caseId, note, recording }: RecordingItemState) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioRef, setAudioRef] = useState<HTMLAudioElement>();
  const [transcript, setTranscript] = useState<any[]>([]);
  const [summary, setSummary] = useState<string>("");
  const [showTranscript, setShowTranscript] = useState<boolean>(false);
  const [showSummary, setShowSummary] = useState<boolean>(true);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isRecordingPlayable, setIsRecordingPlayable] = useState(false);
  const [recordingData, setRecordingData] = useState();

  var currentAudioRef = audioRef;

  useEffect(() => {
    setSummary(recording?.transcript_summary);
    setTranscript(recording?.transcript_text);

    const interval = setInterval(() => {
      setIsVisible((prev) => !prev);
    }, 1500);

    fetchNoteRecordingData(caseId, note?.id, recording?.id)
      .then((res: any) => {
        const rec = res[0].data;
        setRecordingData(rec?.data);
        setIsRecordingPlayable(true);
      })
      .catch((err: any) => {});

    return () => clearInterval(interval);
  }, []);

  const playRecording = () => {
    setIsPlaying(true);
    const base64Data = recordingData;
    currentAudioRef = new Audio(`data:audio/aac;base64,${base64Data}`);
    setAudioRef(currentAudioRef);
    currentAudioRef.oncanplaythrough = () => currentAudioRef?.play();
    currentAudioRef.load();

    currentAudioRef.onended = function () {
      setIsPlaying(false);
    };

    trackFeatureEvent("button_click", "play_recording");
  };

  const pauseRecording = () => {
    setIsPlaying(false);
    currentAudioRef?.pause();
  };

  const viewTranscript = () => {
    setShowTranscript(true);
    setShowSummary(false);

    trackFeatureEvent("button_click", "view_transcript");
  };

  const closeTranscript = () => {
    setShowTranscript(false);
    setShowSummary(true);
  };

  return (
    <>
      <Row className="mb-3">
        <Col>
          <Card>
            <Card.Body>
              {showSummary &&
                (recording?.transcript_summary ? (
                  summary
                ) : (
                  <Row className="text-center">
                    <Col>
                      <h6 className={isVisible ? "app-orange-text fade-in-out" : " app-orange-text fade-in-out hidden"}>
                        Creating Summary <FontAwesomeIcon icon={faWandMagicSparkles} />
                      </h6>
                    </Col>
                  </Row>
                ))}
              {showTranscript &&
                (recording?.transcript_text ? (
                  <>
                    <FormattedTranscript transcript={transcript} />
                  </>
                ) : (
                  <Row className="text-center">
                    <Col>
                      <h6 className={isVisible ? "app-orange-text fade-in-out" : " app-orange-text fade-in-out hidden"}>
                        Processing Transcript <FontAwesomeIcon icon={faFileText} />
                      </h6>
                    </Col>
                  </Row>
                ))}
            </Card.Body>
            <Card.Footer className="text-end border-0 bg-white">
              <ButtonGroup className="justify-content-end">
                {menuOpen &&
                  (showTranscript ? (
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Close</Tooltip>}>
                      <Button className="me-3 app-primary-color button" onClick={() => closeTranscript()}>
                        <FontAwesomeIcon icon={faClose} />
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip>Transcript</Tooltip>}
                    >
                      <Button className="me-3 app-secondary-color button" onClick={() => viewTranscript()}>
                        <FontAwesomeIcon icon={faFileText} />
                      </Button>
                    </OverlayTrigger>
                  ))}

                {menuOpen &&
                  (isPlaying ? (
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Stop</Tooltip>}>
                      <Button className="me-3 app-primary-color button" onClick={() => pauseRecording()}>
                        <FontAwesomeIcon icon={faStop} />
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Play</Tooltip>}>
                      <Button
                        className="me-3 app-tertiary-color button"
                        onClick={() => playRecording()}
                        disabled={isRecordingPlayable ? false : true}
                      >
                        <FontAwesomeIcon icon={faPlay} />
                      </Button>
                    </OverlayTrigger>
                  ))}

                {menuOpen ? (
                  <Button className="app-primary-color button" onClick={() => setMenuOpen(false)}>
                    <FontAwesomeIcon icon={faCircleChevronRight} />
                  </Button>
                ) : (
                  <Button className="app-primary-color button" onClick={() => setMenuOpen(true)}>
                    <FontAwesomeIcon icon={faCircleChevronLeft} />
                  </Button>
                )}
              </ButtonGroup>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RecordingItem;
