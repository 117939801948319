import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import MainHeader from "./MainHeader";
import useApp from "../../utils/useApp";

const RequireAuth2fa = () => {
  const { auth }: any = useApp();
  const location = useLocation();

  return auth?.profile && auth?.twofactor ? (
    <>
      <MainHeader />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth2fa;
