import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { faRightFromBracket, faBars, faClose, faHouse } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/httpClient";
import MainHeaderMenu from "./MainHeaderMenu";
import useApp from "../../utils/useApp";
import { unsavedChanges } from "../../App";
import SaveChangesModal from "./SaveChangesModal";

const MainHeader = () => {
  const { auth, showMenu, setShowMenu, activePage, setActivePage }: any = useApp();
  const [activeMyCases, setActiveMyCases] = useState<boolean>(false);
  const [activeUsers, setActiveUsers] = useState<boolean>(false);
  const [activeCases, setActiveCases] = useState<boolean>(false);
  const [activeGroups, setActiveGroups] = useState<boolean>(false);
  const [activeAnnouncements, setActiveAnnouncements] = useState<boolean>(false);
  const [activeReports, setActiveReports] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pendingRoute, setPendingRoute] = useState<string>("");

  let navigate = useNavigate();

  useEffect(() => {
    setShowMenu(false);
    navigate("/my-cases");
  }, []);

  const handleHome = () => {
    if (unsavedChanges.value !== true) {
      setActivePage("/my-cases");
      navigate("/my-cases");
    } else {
      setShowModal(true);
      setPendingRoute("/my-cases");
    }
  };

  const handleOpenCloseMenu = () => {
    setShowMenu(!showMenu);
  };

  const routeChange = (route: string) => {
    if (unsavedChanges.value !== true) {
      var tempRoute = route;
      if (route.startsWith("/users")) {
        tempRoute = "/users";
      }

      setActivePage(tempRoute.split("/"[1]));

      switch (tempRoute) {
        case "/my-cases":
          setActivePage("/my-cases");
          break;
        case "/users":
          setActivePage("/users");
          break;
        case "/cases":
          setActivePage("/cases");
          break;
        case "/groups":
          setActivePage("/groups");
          break;
        case "/announcements":
          setActivePage("/announcements");
          break;
        case "/reports":
          setActivePage("/reports");
          break;
        default:
          setActivePage("");
          break;
      }
      navigate(route);
    }
  };

  const handleLogout = () => {
    logout()
      .then((res: any) => {
        localStorage.removeItem("auth");
        navigate("/login");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleProfile = () => {
    if (unsavedChanges.value !== true) {
      setActivePage("/users");
      navigate("/users/user/" + auth?.profile.id + "?profile=true");
    } else {
      setShowModal(true);
      setPendingRoute("/users/user/" + auth?.profile.id + "?profile=true");
    }
  };

  //menu selection
  useEffect(() => {
    const basePageUrl = activePage.split("/")[1];

    switch (basePageUrl) {
      case "my-cases":
        setActiveMyCases(true);
        setActiveUsers(false);
        setActiveCases(false);
        setActiveGroups(false);
        setActiveAnnouncements(false);
        setActiveReports(false);
        break;
      case "users":
        setActiveMyCases(false);
        setActiveUsers(true);
        setActiveCases(false);
        setActiveGroups(false);
        setActiveAnnouncements(false);
        setActiveReports(false);
        break;
      case "cases":
        setActiveMyCases(false);
        setActiveUsers(false);
        setActiveCases(true);
        setActiveGroups(false);
        setActiveAnnouncements(false);
        setActiveReports(false);
        break;
      case "groups":
        setActiveMyCases(false);
        setActiveUsers(false);
        setActiveCases(false);
        setActiveGroups(true);
        setActiveAnnouncements(false);
        setActiveReports(false);
        break;
      case "announcements":
        setActiveMyCases(false);
        setActiveUsers(false);
        setActiveCases(false);
        setActiveGroups(false);
        setActiveAnnouncements(true);
        setActiveReports(false);
        break;
      case "reports":
        setActiveMyCases(false);
        setActiveUsers(false);
        setActiveCases(false);
        setActiveGroups(false);
        setActiveAnnouncements(false);
        setActiveReports(true);
        break;
      default:
        setActiveMyCases(false);
        setActiveUsers(false);
        setActiveCases(false);
        setActiveGroups(false);
        setActiveAnnouncements(false);
        setActiveReports(false);
        break;
    }
  }, [activePage]);

  return (
    <>
      <Navbar variant="dark" className="nav nav-header">
        <Container className="custom-container">
          <div>
            <Stack direction="horizontal">
              <OverlayTrigger
                placement="bottom-start"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>1.2.0</Tooltip>}
              >
                <Navbar.Brand className="cursor-pointer" onClick={() => handleHome()}>
                  <img src="/AndGo_Logo_White_Horizontal.png" width="220" height="35" alt="And Go" />
                </Navbar.Brand>
              </OverlayTrigger>
            </Stack>
          </div>
          <div>
            <Stack direction="horizontal">
              {auth?.profile?.role !== "support" ? (
                <Button className={"bg-transparent border-0 button-menu"} onClick={() => handleOpenCloseMenu()}>
                  {showMenu ? (
                    <FontAwesomeIcon icon={faClose} className="me-2" />
                  ) : (
                    <FontAwesomeIcon icon={faBars} className="me-2" />
                  )}
                  Menu
                </Button>
              ) : (
                <Button className={"bg-transparent border-0 button-menu"} onClick={() => handleHome()}>
                  <FontAwesomeIcon icon={faHouse} className="me-2" />
                  My Cases
                </Button>
              )}
              <Nav.Link className="" onClick={handleProfile}>
                Welcome {auth?.profile.preferredname ? auth?.profile.preferredname : auth?.profile.firstname}!
              </Nav.Link>
              <Nav.Link className="app-light-text" onClick={() => handleLogout()}>
                <FontAwesomeIcon icon={faRightFromBracket} className="me-2" />
                Sign Out
              </Nav.Link>
            </Stack>
          </div>
        </Container>
      </Navbar>
      <Navbar className="nav-base"></Navbar>
      {showMenu && (
        <MainHeaderMenu
          activeMyCases={activeMyCases}
          activeUsers={activeUsers}
          activeCases={activeCases}
          activeGroups={activeGroups}
          activeAnnouncements={activeAnnouncements}
          activeReports={activeReports}
          routeChange={routeChange}
        />
      )}
      <SaveChangesModal showModal={showModal} setShowModal={setShowModal} path={pendingRoute} />
    </>
  );
};

export default MainHeader;
