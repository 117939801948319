import React, { useEffect, useState } from "react";
import { User } from "../../../types/user";
import { fetchUserCaseGuardians, fetchUserCaseMembers } from "../../../services/httpClient";
import { useParams } from "react-router-dom";
import ToastMsg from "../../ui/ToastMsg";
import PageTitle from "../../ui/PageTitle";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import HouseholdCard from "./HouseholdCard";
import EditGuardian from "./EditGuardian";
import EditMember from "./EditMember";
import SaveChangesModal from "../../ui/SaveChangesModal";

interface HouseholdUsersState {
  myCase: boolean;
  userToSave: boolean;
  setUserToSave: any;
  showModal: boolean;
  setShowModal: any;
  getData: boolean;
}

const HouseholdUsers = ({
  myCase,
  userToSave,
  setUserToSave,
  showModal,
  setShowModal,
  getData,
}: HouseholdUsersState) => {
  const { id } = useParams();
  const [guardians, setGuardians] = useState<User[]>([]);
  const [children, setChildren] = useState<User[]>([]);
  const [household, setHousehold] = useState<User[]>([]);
  const [currentUser, setCurrentUser] = useState<User>();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");
  const [createGuardian, setCreateGuardian] = useState<boolean>(false);
  const [createMember, setCreateMember] = useState<boolean>(false);
  const [editGuardian, setEditGuardian] = useState<boolean>(false);
  const [editChild, setEditChild] = useState<boolean>(false);
  const [editHousehold, setEditHousehold] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getHousehold();
    }
  }, [getData]);

  const getHousehold = () => {
    fetchUserCaseGuardians(id)
      .then((res: any) => {
        const g = res[0].data;
        setGuardians(g);

        fetchUserCaseMembers(id)
          .then((res: any) => {
            const m = res[0].data;
            const c = m.filter((u: any) => u.relationship === "child");
            const h = m.filter((u: any) => u.is_guardian !== 1 && u.relationship !== "child");
            setChildren(c);
            setHousehold(h);
            setIsLoading(false);
          })
          .catch((err: any) => {
            console.log(err);
            setIsLoading(false);
          });
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onLeave = () => {
    resetHouseholdTab();
  };

  const onClickDoneEdit = () => {
    if (userToSave) {
      setShowModal(true);
    } else {
      resetHouseholdTab();
    }
  };

  const showCreateGuardian = (e: any) => {
    if (userToSave) {
      setShowModal(true);
    } else {
      setCreateGuardian(true);
      setCreateMember(false);
      setEditGuardian(false);
      setEditChild(false);
      setEditHousehold(false);
      setCurrentUser(undefined);
    }
  };

  const showCreateMember = (e: any) => {
    if (userToSave) {
      setShowModal(true);
    } else {
      setCreateGuardian(false);
      setCreateMember(true);
      setEditGuardian(false);
      setEditChild(false);
      setEditHousehold(false);
      setCurrentUser(undefined);
    }
  };

  const resetHouseholdTab = () => {
    setCreateGuardian(false);
    setCreateMember(false);
    setEditGuardian(false);
    setEditChild(false);
    setEditHousehold(false);
    setCurrentUser(undefined);
    setUserToSave(false);
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          {createGuardian ? (
            <>
              <EditGuardian
                fetchHousehold={getHousehold}
                resetHouseholdTab={resetHouseholdTab}
                setUserToSave={setUserToSave}
                onClickDoneEdit={onClickDoneEdit}
                setShowToast={setShowToast}
                setToastText={setToastText}
                setToastBody={setToastBody}
                myCase={myCase}
              />
              <PageTitle title="Guardian(s)" type="section" />
              <Row>
                {guardians.map((u: User) => (
                  <Col key={u.id} md={6} lg={4} xl={3} className="mx-1 mt-1 mb-2 card-members">
                    <HouseholdCard
                      user={u}
                      setEditGuardian={setEditGuardian}
                      setEditChild={setEditChild}
                      setEditHousehold={setEditHousehold}
                      setCreateGuardian={setCreateGuardian}
                      setCreateMember={setCreateMember}
                      currentUser={currentUser}
                      setCurrentUser={setCurrentUser}
                      userToSave={userToSave}
                      setShowModal={setShowModal}
                    />
                  </Col>
                ))}
              </Row>
              {editGuardian && (
                <EditGuardian
                  user={currentUser}
                  fetchHousehold={getHousehold}
                  resetHouseholdTab={resetHouseholdTab}
                  type="Guardian"
                  setUserToSave={setUserToSave}
                  onClickDoneEdit={onClickDoneEdit}
                  setShowToast={setShowToast}
                  setToastText={setToastText}
                  setToastBody={setToastBody}
                  myCase={myCase}
                />
              )}
            </>
          ) : createMember ? (
            <>
              <EditMember
                fetchHousehold={getHousehold}
                resetHouseholdTab={resetHouseholdTab}
                setUserToSave={setUserToSave}
                onClickDoneEdit={onClickDoneEdit}
                setShowToast={setShowToast}
                setToastText={setToastText}
                setToastBody={setToastBody}
                myCase={myCase}
              />
              <PageTitle title="Children" type="section" />
              <Row>
                {children.map((u: User) => (
                  <Col key={u.id} md={6} lg={4} xl={3} className="mx-1 mt-1 mb-2 card-members">
                    <HouseholdCard
                      user={u}
                      setEditGuardian={setEditGuardian}
                      setEditChild={setEditChild}
                      setEditHousehold={setEditHousehold}
                      setCreateGuardian={setCreateGuardian}
                      setCreateMember={setCreateMember}
                      currentUser={currentUser}
                      setCurrentUser={setCurrentUser}
                      userToSave={userToSave}
                      setShowModal={setShowModal}
                    />
                  </Col>
                ))}
              </Row>
              {editChild && (
                <EditMember
                  user={currentUser}
                  fetchHousehold={getHousehold}
                  resetHouseholdTab={resetHouseholdTab}
                  type="Child"
                  setUserToSave={setUserToSave}
                  onClickDoneEdit={onClickDoneEdit}
                  setShowToast={setShowToast}
                  setToastText={setToastText}
                  setToastBody={setToastBody}
                  myCase={myCase}
                />
              )}
              <PageTitle title="Other Household" type="section" />
              <Row>
                {household.map((u: User) => (
                  <Col key={u.id} md={6} lg={4} xl={3} className="mx-1 mt-1 mb-2 card-members">
                    <HouseholdCard
                      user={u}
                      setEditGuardian={setEditGuardian}
                      setEditChild={setEditChild}
                      setEditHousehold={setEditHousehold}
                      setCreateGuardian={setCreateGuardian}
                      setCreateMember={setCreateMember}
                      currentUser={currentUser}
                      setCurrentUser={setCurrentUser}
                      userToSave={userToSave}
                      setShowModal={setShowModal}
                    />
                  </Col>
                ))}
              </Row>
              {editHousehold && (
                <EditMember
                  user={currentUser}
                  fetchHousehold={getHousehold}
                  resetHouseholdTab={resetHouseholdTab}
                  type="Household Member"
                  setUserToSave={setUserToSave}
                  onClickDoneEdit={onClickDoneEdit}
                  setShowToast={setShowToast}
                  setToastText={setToastText}
                  setToastBody={setToastBody}
                  myCase={myCase}
                />
              )}
            </>
          ) : (
            <>
              {myCase ? (
                <PageTitle
                  title="Guardian(s)"
                  buttonTitle="Create Guardian"
                  onButtonClick={() => showCreateGuardian(true)}
                  type="section"
                />
              ) : (
                <PageTitle title="Guardian(s)" type="section" />
              )}
              <Row>
                {guardians.map((u: User) => (
                  <Col key={u.id} md={6} lg={4} xl={3} className="mx-1 mt-1 mb-2 card-members">
                    <HouseholdCard
                      user={u}
                      setEditGuardian={setEditGuardian}
                      setEditChild={setEditChild}
                      setEditHousehold={setEditHousehold}
                      setCreateGuardian={setCreateGuardian}
                      setCreateMember={setCreateMember}
                      currentUser={currentUser}
                      setCurrentUser={setCurrentUser}
                      userToSave={userToSave}
                      setShowModal={setShowModal}
                    />
                  </Col>
                ))}
              </Row>
              {editGuardian && (
                <EditGuardian
                  user={currentUser}
                  fetchHousehold={getHousehold}
                  resetHouseholdTab={resetHouseholdTab}
                  type="Guardian"
                  setUserToSave={setUserToSave}
                  onClickDoneEdit={onClickDoneEdit}
                  setShowToast={setShowToast}
                  setToastText={setToastText}
                  setToastBody={setToastBody}
                  myCase={myCase}
                />
              )}
              {myCase ? (
                <PageTitle
                  title="Children"
                  buttonTitle="Create Member"
                  onButtonClick={() => showCreateMember(true)}
                  type="section"
                />
              ) : (
                <PageTitle title="Children" type="section" />
              )}
              <Row>
                {children.map((u: User) => (
                  <Col key={u.id} md={6} lg={4} xl={3} className="mx-1 mt-1 mb-2 card-members">
                    <HouseholdCard
                      user={u}
                      setEditGuardian={setEditGuardian}
                      setEditChild={setEditChild}
                      setEditHousehold={setEditHousehold}
                      setCreateGuardian={setCreateGuardian}
                      setCreateMember={setCreateMember}
                      currentUser={currentUser}
                      setCurrentUser={setCurrentUser}
                      userToSave={userToSave}
                      setShowModal={setShowModal}
                    />
                  </Col>
                ))}
              </Row>
              {editChild && (
                <EditMember
                  user={currentUser}
                  fetchHousehold={getHousehold}
                  resetHouseholdTab={resetHouseholdTab}
                  type="Child"
                  setUserToSave={setUserToSave}
                  onClickDoneEdit={onClickDoneEdit}
                  setShowToast={setShowToast}
                  setToastText={setToastText}
                  setToastBody={setToastBody}
                  myCase={myCase}
                />
              )}
              <PageTitle title="Other Household" type="section" />
              <Row>
                {household.map((u: User) => (
                  <Col key={u.id} md={6} lg={4} xl={3} className="mx-1 mt-1 mb-2 card-members">
                    <HouseholdCard
                      user={u}
                      setEditGuardian={setEditGuardian}
                      setEditChild={setEditChild}
                      setEditHousehold={setEditHousehold}
                      setCreateGuardian={setCreateGuardian}
                      setCreateMember={setCreateMember}
                      currentUser={currentUser}
                      setCurrentUser={setCurrentUser}
                      userToSave={userToSave}
                      setShowModal={setShowModal}
                    />
                  </Col>
                ))}
              </Row>
              {editHousehold && (
                <EditMember
                  user={currentUser}
                  fetchHousehold={getHousehold}
                  resetHouseholdTab={resetHouseholdTab}
                  type="Household Member"
                  setUserToSave={setUserToSave}
                  onClickDoneEdit={onClickDoneEdit}
                  setShowToast={setShowToast}
                  setToastText={setToastText}
                  setToastBody={setToastBody}
                  myCase={myCase}
                />
              )}
            </>
          )}
          <SaveChangesModal showModal={showModal} setShowModal={setShowModal} onLeave={() => onLeave()} />
        </>
      )}
    </>
  );
};

export default HouseholdUsers;
