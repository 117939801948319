import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

interface TextAreaState {
  label: string;
  value: any;
  onUpdate: any;
  rows?: number;
  disabled?: boolean;
}

const Textarea = ({ label, value, onUpdate, rows = 3, disabled = false }: TextAreaState) => {
  return (
    <FloatingLabel controlId="floatingInput" label={label} className="form-floating-label-textarea">
      <Form.Control
        as="textarea"
        type="textarea"
        value={value}
        onChange={onUpdate}
        style={{ height: "auto" }}
        disabled={disabled}
        rows={rows}
      />
    </FloatingLabel>
  );
};

export default Textarea;
