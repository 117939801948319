import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Col, Row } from "react-bootstrap";

ChartJS.register(ArcElement, Tooltip, Legend);

export const ContinuumPieChart = ({ data, reportTitle }: any) => {
  const chartData = {
    labels: data.map((item: any) => item.area),
    datasets: [
      {
        label: "# Cases",
        data: data.map((item: any) => item.numberCases),
        backgroundColor: ["#ed2f2f", "#DA7E39", "#B2BEB5", "#5A7082", "#5D82A0"],
        borderColor: ["#ed2f2f", "#DA7E39", "#B2BEB5", "#5A7082", "#5D82A0"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: reportTitle,
      },
    },
  };

  return (
    <Row>
      <Col></Col>
      <Col className="col-8">
        <Pie data={chartData} options={options} />
      </Col>
      <Col></Col>
    </Row>
  );
};
