import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import { SingleCardMetric } from "../report-ui/SingleCardMetric";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong, faDownload, faRefresh } from "@fortawesome/free-solid-svg-icons";
import ContinuumCategoryPicker from "../report-ui/ContinuumCategoryPicker";
import { fetchReportContinuum } from "../../services/httpClient";
import html2canvas from "html2canvas";
import { CSVLink } from "react-csv";

const ContinuumTab = ({ buildPDF, canvas, setCanvas }: any) => {
  const [contData, setContData] = useState<any[]>();
  const [inCrisis, setInCrisis] = useState<any>();
  const [atRisk, setAtRisk] = useState<any>();
  const [contTableData, setContTableData] = useState<any>();
  const [filteredTableData, setFilteredTableData] = useState<any>();

  const [crisisFilter, setCrisisFilter] = useState<boolean>(false);
  const [riskFilter, setRiskFilter] = useState<boolean>(false);
  const [reportRunning, setReportRunning] = useState<boolean>(false);

  const contHeaders = [
    { label: "ID", key: "id" },
    { label: "Assessment Name", key: "name" },
    { label: "Case ID", key: "case_id" },
    { label: "Case Name", key: "case_name" },
    { label: "Completed", key: "created_at" },
    { label: "Category", key: "key" },
    { label: "Status", key: "value" },
  ];

  useEffect(() => {
    getContinuumData();
  }, []);

  useEffect(() => {
    if (reportRunning) {
      buildPDF();
      setReportRunning(false);
    }
  }, [canvas]);

  const getContinuumData = () => {
    fetchReportContinuum()
      .then((res: any) => {
        const cont = res[0].data;
        setContData(cont);

        const crisis = cont?.filter((c: any) => c.value === "In-Crisis");
        setInCrisis(crisis);
        const risk = cont?.filter((c: any) => c.value === "At-Risk");
        setAtRisk(risk);
        const tdata = cont?.filter((c: any) => c.value === "At-Risk" || c.value === "In-Crisis");
        setContTableData(tdata);
        setFilteredTableData(tdata);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const downloadReport = async () => {
    setReportRunning(true);

    const reportSection = document.getElementById("continuum-section");
    const reportTitle = document.getElementById("continuum-title");
    const pieChart = document.getElementById("continuum-pie");
    const downloadButton = document.getElementById("download-continuum-button");
    const refreshButton = document.getElementById("refresh-continuum-button");

    if (reportSection) {
      if (reportTitle) {
        reportTitle.style.visibility = "hidden";
        reportTitle.style.display = "none";
      }
      if (pieChart) {
        pieChart.style.visibility = "hidden";
        pieChart.style.display = "none";
      }
      if (downloadButton) downloadButton.style.visibility = "hidden";
      if (refreshButton) refreshButton.style.visibility = "hidden";

      const canvas = await html2canvas(reportSection);
      setCanvas(canvas);

      if (reportTitle) {
        reportTitle.style.visibility = "visible";
        reportTitle.style.display = "flex";
      }
      if (pieChart) {
        pieChart.style.visibility = "visible";
        pieChart.style.display = "flex";
      }
      if (downloadButton) downloadButton.style.visibility = "visible";
      if (refreshButton) refreshButton.style.visibility = "visible";
    } else {
      console.error("Report section not found");
      setReportRunning(false);
    }
  };

  const handleInCrisisClick = () => {
    const filtered = contTableData?.filter((c: any) => c.value === "In-Crisis");
    setFilteredTableData(filtered);
    setCrisisFilter(true);
    setRiskFilter(false);
  };

  const handleAtRiskClick = () => {
    const filtered = contTableData?.filter((c: any) => c.value === "At-Risk");
    setFilteredTableData(filtered);
    setCrisisFilter(false);
    setRiskFilter(true);
  };

  const handleRefreshClick = () => {
    setFilteredTableData(contTableData);
    setCrisisFilter(false);
    setRiskFilter(false);
  };

  return (
    <>
      <Container id="continuum-section" className="lg vstack gap-3">
        <Row id="continuum-title">
          <Col className="col-8">
            <h4 className="mb-0 app-header-text">Continuum Assessment</h4>
          </Col>
          <Col className="text-end col-4">
            <Button className="app-primary-color button" onClick={() => downloadReport()}>
              <FontAwesomeIcon icon={faDownload} />
              {" Report"}
            </Button>
          </Col>
        </Row>
        <Card className="bg-light mb-3">
          <Card.Body>
            <Row className="gx-0">
              <Tab.Container>
                <Row className="gx-0">
                  <Col className="text-start">
                    <h5 className="app-header-text">
                      <FontAwesomeIcon icon={faArrowLeftLong} className="ms-3" /> Dependence
                    </h5>
                  </Col>
                  <Col className="text-end">
                    <h5 className="app-header-text">
                      Independence <FontAwesomeIcon icon={faArrowRightLong} className="me-3" />
                    </h5>
                  </Col>
                </Row>
                <Row className="gx-0">
                  <Col className="col-12">
                    <ListGroup horizontal>
                      <ListGroup.Item key="0" className="text-center app-secondary-color m-1" action>
                        In-Crisis
                      </ListGroup.Item>
                      <ListGroup.Item key="1" className="text-center app-secondary-color m-1" action>
                        At-Risk
                      </ListGroup.Item>
                      <ListGroup.Item key="2" className="text-center app-secondary-color m-1" action>
                        Safe
                      </ListGroup.Item>
                      <ListGroup.Item key="3" className="text-center app-secondary-color m-1" action>
                        Stable
                      </ListGroup.Item>
                      <ListGroup.Item key="4" className="text-center app-secondary-color m-1" action>
                        Thriving
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              </Tab.Container>
            </Row>
          </Card.Body>
        </Card>
        <Row id="continuum-pie">
          <ContinuumCategoryPicker isAdmin={true} data={contData} />
        </Row>
        {contTableData?.length > 0 && (
          <>
            <Row>
              <Col className="mb-1 col-4">
                <SingleCardMetric
                  title="In-Crisis"
                  total={inCrisis?.length}
                  onHandleClick={handleInCrisisClick}
                  cursor={true}
                  active={crisisFilter}
                />
              </Col>
              <Col className="mb-1 col-4">
                <SingleCardMetric
                  title="At-Risk"
                  total={atRisk?.length}
                  onHandleClick={handleAtRiskClick}
                  cursor={true}
                  active={riskFilter}
                />
              </Col>
              <Col className="col-1 d-flex flex-column text-end justify-content-end align-items-end">
                <CSVLink
                  id="download-continuum-button"
                  data={contTableData}
                  headers={contHeaders}
                  filename={"continuum.csv"}
                  className="btn button app-tertiary-color"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faDownload} />
                </CSVLink>
              </Col>
              <Col className="col-1 d-flex flex-column text-end justify-content-end align-items-end">
                <Button
                  id="refresh-continuum-button"
                  className="btn button app-primary-color"
                  onClick={handleRefreshClick}
                >
                  <FontAwesomeIcon icon={faRefresh} />
                </Button>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col className="col-8">
                <Table striped>
                  <thead>
                    <tr>
                      <th>Case Name</th>
                      <th>Category</th>
                      <th>Status</th>
                      <th>Completed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTableData?.map((d: any) => (
                      <tr key={d.field_value_id}>
                        <td>{d.case_name}</td>
                        <td className="first-letter">{d.key}</td>
                        <td>{d.value}</td>
                        <td>{d.created_at}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
        {/* <Row>
          <Col className="mb-3 col-4">
            <ContinuumPieChart data={incomePieData} reportTitle={"Income"} />
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default ContinuumTab;
