import { faClose, faPaperPlane, faSmile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, ListGroup, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { postNewMessage } from "../../../services/httpClient";
import RecipientMessage from "./RecipientMessage";
import SenderMessage from "./SenderMessage";
import useApp from "../../../utils/useApp";
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";

interface CurrentConversationState {
  conversation: any;
  messages: any[];
  getMessages: any;
  currentIndex: number;
  myCase: boolean;
  showEmojiPicker: boolean;
  setShowEmojiPicker: any;
}

const CurrentConversation = ({
  conversation,
  messages,
  getMessages,
  currentIndex,
  myCase,
  showEmojiPicker,
  setShowEmojiPicker,
}: CurrentConversationState) => {
  const { id } = useParams();
  const { auth }: any = useApp();
  const [messageValue, setMessageValue] = useState("");
  const [viewedMsgs, setViewedMsgs] = useState<any[]>([]);
  const [isMessageSending, setIsMessageSending] = useState<boolean>(false);
  const txtRef = useRef<HTMLInputElement>(null);
  const msgRef = useRef<null | HTMLDivElement>();

  useEffect(() => {
    handleScroll(msgRef);
    if (txtRef.current != null) {
      txtRef.current.focus();
    }
  }, []);

  const handleScroll = async (ref: any) => {
    if (ref.current) {
      await getMessages(conversation);
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  const handleScrollToTop = (e: React.UIEvent<HTMLElement>) => {
    if (e.currentTarget.scrollTop === 0) {
      if (messages) {
        getMessages(conversation, messages[0].id);
      }
    }

    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight) {
      getMessages(conversation);
    }
  };

  useEffect(() => {
    var prevDate: any = null;
    var currentDate: any = null;
    var workingMsgs: any[] = [];
    messages.forEach((obj: any, index: any, array: any) => {
      currentDate = obj?.display_date;

      if (prevDate !== currentDate) {
        // Insert a new object
        workingMsgs.push({ id: "title", text: currentDate });
      }

      workingMsgs.push(obj);
      prevDate = currentDate;
    });
    setViewedMsgs(workingMsgs);
  }, [messages]);

  const updateMessage = (e: any) => {
    if (e?.emoji) {
      setMessageValue(messageValue + e.emoji);
    } else {
      setMessageValue(e.target.value);
    }
  };

  useEffect(() => {
    if (txtRef && txtRef.current) {
      txtRef.current.style.height = "0px";
      const taHeight = txtRef.current.scrollHeight;
      txtRef.current.style.height = taHeight + "px";
    }
  }, [messageValue]);

  const onClickMessageSubmit = (e: any) => {
    e?.preventDefault();
    onClickSendMessage();
  };

  const onClickSendMessage = () => {
    setIsMessageSending(true);

    const messageBody = {
      body: messageValue,
    };

    postNewMessage(messageBody, id, conversation.id)
      .then((res: any) => {
        setMessageValue("");
        getMessages(conversation);
        handleScroll(msgRef);
        setIsMessageSending(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsMessageSending(false);
      });
  };

  return (
    <>
      <div className="message-header">
        <Row className="justify-content-center pt-3">
          <Col className="col-10 text-center">
            <h6 className="app-orange-text mb-0">{conversation.name}</h6>
            <p className="small-text">{conversation.recipients}</p>
            <hr className="m-0" />
          </Col>
        </Row>
      </div>
      <div className="message-body align-items-end d-grid" onScroll={handleScrollToTop}>
        <ListGroup className="p-2">
          {viewedMsgs?.map((m: any, index: number) =>
            m?.id === "title" ? (
              <p className="text-center p-3 m-0 small-text">{m?.text}</p>
            ) : m.user_id === auth?.profile?.id ? (
              <>
                <SenderMessage key={index} message={m} addRef={index + 1 === currentIndex ? true : false} />
              </>
            ) : (
              <>
                <RecipientMessage key={index} message={m} addRef={index + 1 === currentIndex ? true : false} />
              </>
            )
          )}
          <Row ref={msgRef} style={{ width: "80px", height: 0 }}></Row>
        </ListGroup>
      </div>
      <div className="position-absolute bottom-0 pb-5 mb-4">
        <EmojiPicker open={showEmojiPicker} onEmojiClick={(e) => updateMessage(e)} emojiStyle={EmojiStyle.NATIVE} />
      </div>
      <div className="message-footer app-primary-color">
        {myCase && (
          <Form onSubmit={(e: any) => onClickMessageSubmit(e)}>
            <Form.Group as={Col} className="p-2 col-12">
              <InputGroup>
                <Button onClick={() => setShowEmojiPicker(!showEmojiPicker)} className="app-primary-color">
                  {showEmojiPicker ? <FontAwesomeIcon icon={faClose} /> : <FontAwesomeIcon icon={faSmile} />}
                </Button>
                <Form.Control
                  ref={txtRef}
                  className="message-input"
                  placeholder="Enter a message..."
                  value={messageValue}
                  onChange={(e: any) => updateMessage(e)}
                  onFocus={(e: any) => handleScroll(msgRef)}
                />
                <Button className="app-primary-color" onClick={() => onClickSendMessage()}>
                  {isMessageSending ? (
                    <Spinner size="sm" style={{ color: "#FFFFFF" }} />
                  ) : (
                    <>
                      <FontAwesomeIcon className="me-1" icon={faPaperPlane} />
                      Send
                    </>
                  )}
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
        )}
      </div>
    </>
  );
};

export default CurrentConversation;
