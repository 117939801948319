import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface DatePickerState {
  label: string;
  currentValue: any;
  setCurrentValue: any;
  onUpdate?: any;
  disabled?: boolean;
  required?: boolean;
  displayOnly?: boolean;
  hasMaxDate?: boolean;
}

const DatePicker = ({
  label,
  currentValue,
  setCurrentValue,
  onUpdate = null,
  disabled = false,
  required = false,
  displayOnly = false,
  hasMaxDate = false,
}: DatePickerState) => {
  const dateInputRef = useRef(null);

  const handleChange = (e: any) => {
    setCurrentValue(e.target.value);
    if (onUpdate) {
      onUpdate(e.target.value);
    }
  };

  const handleBlur = (e: any) => {
    const enteredDate = e.target.value;
    const maxDate = getMaxDate();

    if (enteredDate) {
      const parsedDate = new Date(enteredDate);
      if (
        isNaN(parsedDate.getTime()) ||
        parsedDate.getFullYear() < 1900 ||
        (hasMaxDate && parsedDate > new Date(maxDate))
      ) {
        setCurrentValue("");
      }
    }
  };

  const getMaxDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getMinDate = () => {
    const today = new Date();
    const year = today.getFullYear() - 100;
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="form-floating">
      <label className="date-label">
        {label}{" "}
        {required && (
          <FontAwesomeIcon className="app-tertiary-color-inverse" icon={faAsterisk} style={{ fontSize: 11 }} />
        )}
      </label>
      {required ? (
        <OverlayTrigger placement="top" delay={{ show: 150, hide: 250 }} overlay={<Tooltip>Required</Tooltip>}>
          <input
            id="floatingInput"
            className={displayOnly ? "border-0" : "form-control date-picker"}
            type="date"
            value={currentValue}
            onChange={(e: any) => handleChange(e)}
            ref={dateInputRef}
            disabled={disabled}
            min={getMinDate()}
            max={hasMaxDate ? getMaxDate() : ""}
            pattern="\d{4}-\d{2}-\d{2}"
            onBlur={(e: any) => handleBlur(e)}
          />
        </OverlayTrigger>
      ) : (
        <input
          id="floatingInput"
          className={displayOnly ? "border-0 form-control date-picker" : "form-control date-picker"}
          type="date"
          value={currentValue}
          onChange={(e: any) => handleChange(e)}
          ref={dateInputRef}
          disabled={disabled}
          min={getMinDate()}
          max={hasMaxDate ? getMaxDate() : ""}
          pattern="\d{4}-\d{2}-\d{2}"
          onBlur={(e: any) => handleBlur(e)}
        />
      )}
    </div>
  );
};

export default DatePicker;
