import React from "react";
import { Card, Row } from "react-bootstrap";

export const SingleCardMetric = ({ title, total, onHandleClick = null, cursor = false, active = false }: any) => {
  return (
    <Card
      className={
        active
          ? "bg-light cursor-pointer button button-today-filter"
          : cursor
          ? "app-light-color cursor-pointer button"
          : "bg-light"
      }
      onClick={onHandleClick}
    >
      <Card.Body>
        <Row className="text-center">
          <h5 className="app-orange-text p-0">{title}</h5>
        </Row>
        <Row className="text-center">
          <h1 className="text-muted">{total}</h1>
        </Row>
      </Card.Body>
    </Card>
  );
};
