import React, { useEffect, useRef } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faTimes } from "@fortawesome/free-solid-svg-icons";
import { trackDataEvent } from "../../utils/analytics";

interface SearchBarState {
  cssClass: any;
  placeholder: string;
  objMy: any;
  setFilteredObjMy: any;
  objOther: any;
  setFilteredObjOther: any;
  type: string;
  searchText: string;
  setSearchText: any;
}

const SearchBar = ({
  cssClass,
  placeholder = "Search",
  objMy,
  setFilteredObjMy,
  objOther,
  setFilteredObjOther,
  type,
  searchText,
  setSearchText,
}: SearchBarState) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const onSearch = (text: any) => {
    setSearchText(text);
    const lowerText = text.toLowerCase();
    var myFiltered;
    var otherFiltered;
    // if (type === "user") {
    //   filtered = obj.filter((item: any) => {
    //     var textToSearch = item.firstname + " " + item.lastname;
    //     return textToSearch.toLowerCase().match(lowerText);
    //   });
    // } else if (type === "case") {

    myFiltered = objMy.filter((item: any) => {
      return item.name.toLowerCase().match(lowerText);
    });
    setFilteredObjMy(myFiltered);
    console.log(myFiltered);

    otherFiltered = objOther.filter((item: any) => {
      return item.name.toLowerCase().match(lowerText);
    });
    setFilteredObjOther(otherFiltered);
    console.log(otherFiltered);

    trackDataEvent("filter", "mycase_search");
  };

  return (
    <Form className={cssClass}>
      <Form.Group as={Col}>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </InputGroup.Text>
          <Form.Control
            type="text"
            value={searchText}
            placeholder={placeholder}
            onChange={(e: any) => onSearch(e.target.value)}
            ref={inputRef}
          />
          <InputGroup.Text onClick={() => onSearch("")} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faTimes} />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
    </Form>
  );
};

export default SearchBar;
