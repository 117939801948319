import React, { useEffect } from "react";
import { Form, FloatingLabel } from "react-bootstrap";

const InputValidUrl = ({ label, url, setUrl, isValid, setIsValid }: any) => {
  useEffect(() => {
    if (url) {
      try {
        const urlObject = new URL(url);
        const tldPattern = /\.[a-z]{2,6}$/i;
        const hasValidTld = tldPattern.test(urlObject.hostname);
        setIsValid(hasValidTld);
      } catch {
        setIsValid(false);
      }
    } else {
      setIsValid(true);
    }
  }, []);

  const handleChange = (event: any) => {
    let inputUrl = event.target.value.trim();
    setUrl(inputUrl);

    if (inputUrl === "") {
      setIsValid(true);
    } else {
      validateUrl(inputUrl);
    }
  };

  const handleBlur = () => {
    if (url && !/^https?:\/\//i.test(url)) {
      const prefixedUrl = `https://${url}`;
      setUrl(prefixedUrl);
      validateUrl(prefixedUrl);
    }
  };

  const validateUrl = (inputUrl: any) => {
    try {
      const urlObject = new URL(inputUrl);
      const tldPattern = /\.[a-z]{2,6}$/i;
      const hasValidTld = tldPattern.test(urlObject.hostname);
      setIsValid(hasValidTld);
    } catch {
      setIsValid(false);
    }
  };

  return (
    <FloatingLabel controlId="floatingInput" label={label} className="col-6 sm px-0">
      <Form.Control
        type="text"
        value={url}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="https://example.com"
      />
    </FloatingLabel>
  );
};

export default InputValidUrl;
