import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SingleCardMetric } from "../report-ui/SingleCardMetric";
import { Case } from "../../types/case";
import { trackDataEvent } from "../../utils/analytics";

interface MyCasesTodayState {
  cases: Case[];
  assessFilter: boolean;
  setAssessFilter: any;
  msgsFilter: boolean;
  setMsgsFilter: any;
  nextFilter: boolean;
  setNextFilter: any;
}

const MyCasesToday = ({
  cases,
  assessFilter,
  setAssessFilter,
  msgsFilter,
  setMsgsFilter,
  nextFilter,
  setNextFilter,
}: MyCasesTodayState) => {
  const [assessmentsDue, setAssessmentsDue] = useState<Case[]>([]);
  const [unreadMessages, setUnreadMessages] = useState<Case[]>([]);
  const [nextThing, setNextThing] = useState<Case[]>([]);

  useEffect(() => {
    var workingCases = [];
    workingCases = cases.filter((c: any) => {
      return c.assessment_due;
    });
    setAssessmentsDue(workingCases);

    workingCases = cases.filter((c: any) => {
      return c.new_messages;
    });
    setUnreadMessages(workingCases);

    workingCases = cases.filter((c: any) => {
      return c.task_review;
    });
    setNextThing(workingCases);
  }, [cases]);

  const setAssessmentFilter = () => {
    setAssessFilter(true);
    setMsgsFilter(false);
    setNextFilter(false);

    trackDataEvent("filter", "mycase_assessment");
  };

  const setUnreadFilter = () => {
    setMsgsFilter(true);
    setAssessFilter(false);
    setNextFilter(false);

    trackDataEvent("filter", "mycase_unread");
  };

  const setNextThingFilter = () => {
    setNextFilter(true);
    setAssessFilter(false);
    setMsgsFilter(false);

    trackDataEvent("filter", "mycase_review");
  };

  return (
    <>
      <Row className="mt-3 mb-2">
        <Col></Col>
        <Col className="col-3">
          <SingleCardMetric
            title="Assessment Due"
            total={assessmentsDue.length}
            onHandleClick={() => setAssessmentFilter()}
            cursor={true}
            active={assessFilter}
          />
        </Col>
        <Col className="col-3">
          <SingleCardMetric
            title="Unread Messages"
            total={unreadMessages.length}
            onHandleClick={() => setUnreadFilter()}
            cursor={true}
            active={msgsFilter}
          />
        </Col>
        <Col className="col-3">
          <SingleCardMetric
            title="Next Task Review"
            total={nextThing.length}
            onHandleClick={() => setNextThingFilter()}
            cursor={true}
            active={nextFilter}
          />
        </Col>
        <Col></Col>
      </Row>
    </>
  );
};

export default MyCasesToday;
