import React, { useEffect, useState } from "react";
import { Button, Container, Spinner } from "react-bootstrap";
import { fetchNoteRecordings } from "../../../services/httpClient";
import { useParams } from "react-router-dom";
import RecordingItem from "./RecordingItem";
import { Note } from "../../../types/note";

interface ViewRecordingsState {
  note?: Note;
  editNote?: boolean;
}

const ViewRecordings = ({ note, editNote }: ViewRecordingsState) => {
  const { id } = useParams();
  const [recordings, setRecordings] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadRecordings();
  }, []);

  const loadRecordings = () => {
    setIsLoading(true);

    fetchNoteRecordings(id, note?.id)
      .then((res: any) => {
        const rec = res[0].data;
        setRecordings(rec);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main mt-1 mb-5">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          {editNote === true && recordings.length > 0 && <h5 className="pt-3 px-3 app-header-text">Summary</h5>}
          {recordings?.map((r: any) => (
            <RecordingItem caseId={id} note={note} recording={r} />
          ))}
        </>
      )}
    </>
  );
};

export default ViewRecordings;
