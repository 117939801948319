import React, { useEffect, useState } from "react";
import { fetchCases, fetchUsers, patchCaseData } from "../../services/httpClient";
import { Card, Col, Container, Row, Stack } from "react-bootstrap";
import SelectObject from "../ui/SelectObject";
import { Case } from "../../types/case";

interface CaseAssignTabState {
  cases: Case[];
  setCases: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const CaseAssignmentTab = ({ cases, setCases, setShowToast, setToastText, setToastBody }: CaseAssignTabState) => {
  const [cmUsers, setCmUsers] = useState<any[]>([]);

  useEffect(() => {
    fetchUsers()
      .then((res: any) => {
        const users = res[0].data;
        var filteredUsers = users.filter((i: any) => i.role === "admin" || i.role === "case_manager");
        filteredUsers = filteredUsers.map((i: any) => {
          return {
            id: i.id,
            key: i.id,
            name: i.firstname + " " + i.lastname,
          };
        });
        setCmUsers(filteredUsers);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const updatePrimaryCM = (e: any, caseId: any, secondaryCM: any) => {
    const newCM = {
      primaryCM: String(e.target.value),
      secondaryCM: secondaryCM,
    };

    patchCaseData(newCM, caseId)
      .then((res: any) => {
        setShowToast(true);
        setToastText("Case Updated");
        setToastBody("Primary Assigned");

        fetchCases().then((res: any) => {
          const c = res[0].data;
          setCases(c);
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const updateSecondaryCM = (e: any, caseId: any, primaryCM: any) => {
    const newCM = {
      primaryCM: primaryCM,
      secondaryCM: String(e.target.value),
    };

    patchCaseData(newCM, caseId)
      .then((res: any) => {
        setShowToast(true);
        setToastText("Case Updated");
        setToastBody("Secondary Assigned");

        fetchCases().then((res: any) => {
          const c = res[0].data;
          setCases(c);
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <Container className="lg">
      <Row>
        <Col>
          <Stack gap={3}>
            {cases.map((item: any, index: any) => (
              <Card key={index} className="bg-light">
                <Card.Body>
                  <Row>
                    <Col className="col-lg-6 col-md-4 col-sm-12">
                      {item.disabled_at ? (
                        <Card.Subtitle className="text-muted">{item.name}</Card.Subtitle>
                      ) : (
                        <Card.Subtitle>{item.name}</Card.Subtitle>
                      )}
                      <Card.Subtitle className="text-muted pt-2 first-letter">{item.casestatus}</Card.Subtitle>
                    </Col>
                    <Col className="col-lg-3 col-md-4 col-sm-6">
                      <SelectObject
                        label="Primary CM"
                        value={item?.primaryCM?.user_id}
                        options={cmUsers}
                        onUpdate={(e: any) => updatePrimaryCM(e, item.id, item?.secondaryCM?.user_id)}
                      />
                    </Col>
                    <Col className="col-lg-3 col-md-4 col-sm-6">
                      <SelectObject
                        label="Secondary CM"
                        value={item?.secondaryCM?.user_id}
                        options={cmUsers}
                        onUpdate={(e: any) => updateSecondaryCM(e, item.id, item?.primaryCM?.user_id)}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default CaseAssignmentTab;
