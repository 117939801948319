import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, Col, Form, ListGroup, ListGroupItem, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { deleteTask, postGoal, postTask } from "../../../services/httpClient";
import { Task } from "../../../types/task";
import Input from "../../ui/Input";

interface CreateGoalState {
  fetchGoals: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const CreateGoal = ({ fetchGoals, setShowToast, setToastText, setToastBody }: CreateGoalState) => {
  const { id } = useParams();

  const [goalId, setGoalId] = useState<string>("");
  const [goalName, setGoalName] = useState<string>("");
  const [task, setTask] = useState<string>("");
  const [tasks, setTasks] = useState<Task[]>([]);

  const [showTasks, setShowTasks] = useState<boolean>(false);
  const [allowSaveGoal, setAllowSaveGoal] = useState<boolean>(false);
  const [goalSaved, setGoalSaved] = useState<boolean>(false);

  const updateGoalName = (e: any) => {
    setGoalName(e.target.value);
    setAllowSaveGoal(true);
  };

  const onClickGoalSubmit = (e: any) => {
    e?.preventDefault();
    onClickSaveGoal();
  };

  const onClickSaveGoal = () => {
    const newGoal = {
      name: goalName,
      is_completed: false,
      is_archived: false,
    };

    postGoal(newGoal, id).then((res: any) => {
      setGoalId(res[0].data.goal_id);
      setShowTasks(true);
      setGoalSaved(true);
      fetchGoals();
      setShowToast(true);
      setToastText("Goal Created");
      setToastBody(goalName);
    });
  };

  const updateTask = (e: any) => {
    setTask(e.target.value);
  };

  const onClickTaskSubmit = (e: any) => {
    e?.preventDefault();
    onClickSaveTask();
  };

  const onClickSaveTask = () => {
    const newTask = {
      name: task,
      is_next: false,
      is_completed: false,
      is_cm_reviewed: false,
      is_archived: false,
    };

    postTask(newTask, id, goalId).then((res: any) => {
      const newTaskId = {
        id: res[0].data.task_id,
        name: task,
        is_next: false,
        is_completed: false,
        is_cm_reviewed: false,
        is_archived: false,
      };
      setTasks([...tasks, newTaskId]);
      setTask("");
      fetchGoals();
      setShowToast(true);
      setToastText("Task Created");
      setToastBody(task);
    });
  };

  const onClickRemoveTask = (t: Task) => {
    deleteTask(id, goalId, t.id).then((res: any) => {
      setTasks((oldValues) => {
        return oldValues.filter((task) => task.name !== t.name);
      });
      setShowToast(true);
      setToastText("Task Deleted");
      setToastBody(t.name);
      fetchGoals();
    });
  };

  const onClickStartOver = () => {
    setGoalId("");
    setGoalName("");
    setTask("");
    setTasks([]);
    setShowTasks(false);
    setAllowSaveGoal(false);
    setGoalSaved(false);
  };

  return (
    <>
      <Row>
        <Card className="bg-light mb-3">
          <Card.Body>
            <Row>
              <h6>Goal</h6>
            </Row>
            <Form onSubmit={(e: any) => onClickGoalSubmit(e)}>
              <Row className="mb-2">
                <Input
                  label="Enter New Goal"
                  type="textarea"
                  value={goalName}
                  onUpdate={(e: any) => updateGoalName(e)}
                  disabled={goalSaved}
                />
              </Row>
              {!goalSaved && (
                <Row>
                  <Col className="pe-0 pb-3 pt-1">
                    <Button
                      className="button app-secondary-color button"
                      onClick={() => onClickSaveGoal()}
                      disabled={!allowSaveGoal}
                    >
                      Save Goal
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
            {showTasks && (
              <>
                <Row>
                  <h6>Tasks</h6>
                </Row>
                <Row className="mb-0">
                  <ListGroup className="pe-0">
                    {tasks.map((t: Task) => (
                      <ListGroupItem key={t.id} className="mb-2">
                        <Row>
                          <Col className="col-11">
                            <Form.Control type="text" value={t.name} readOnly />
                          </Col>
                          <Col className=" align-self-center p-0">
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip>Delete Task</Tooltip>}
                            >
                              <FontAwesomeIcon
                                className="text-muted"
                                icon={faTrash}
                                onClick={(e) => onClickRemoveTask(t)}
                              />
                            </OverlayTrigger>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Row>
                <Form onSubmit={(e: any) => onClickTaskSubmit(e)}>
                  <Row>
                    <Col className="col-10 p-0">
                      <Input label="Enter New Task" type="text" value={task} onUpdate={(e: any) => updateTask(e)} />
                    </Col>
                    <Col className="text-start align-self-center">
                      <Button className="button app-secondary-color" onClick={() => onClickSaveTask()}>
                        Save Task
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            )}
          </Card.Body>
        </Card>
      </Row>
      <Row>
        <Col className="text-center">
          <Button className="button app-primary-color" onClick={() => onClickStartOver()}>
            Create Another Goal
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CreateGoal;
