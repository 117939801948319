import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { unsavedChanges } from "../../App";
import useApp from "../../utils/useApp";

interface SaveChangesModalState {
  showModal: boolean;
  setShowModal: any;
  path?: string | null;
  onLeave?: any;
}

const SaveChangesModal = ({ showModal, setShowModal, path = null, onLeave = null }: SaveChangesModalState) => {
  let navigate = useNavigate();
  const { setActivePage }: any = useApp();

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleLeave = () => {
    unsavedChanges.value = false;
    setShowModal(false);
    if (path) {
      setActivePage(path);
      navigate(path);
    } else {
      onLeave();
    }
  };

  return (
    <Modal show={showModal} onHide={handleCancel}>
      <Modal.Header className="border-0">
        <Modal.Title>Unsaved Changes</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-0">Are you sure you want to leave?</Modal.Body>
      <Modal.Footer className="border-0">
        <Button className="app-primary-color button" onClick={handleCancel}>
          Cancel
        </Button>
        <Button className={"ms-2 app-tertiary-color button"} onClick={handleLeave}>
          Leave
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveChangesModal;
