import React, { useCallback, useEffect } from "react";
import { Col, Form, Button, Row, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";

interface FileUploadUIState {
  header: string;
  selectedFile: any;
  setSelectedFile: any;
  uploadIsEnabled: boolean;
  setUploadIsEnabled: any;
  uploadFileData: any;
}

const FileUploadUI = ({
  header,
  selectedFile,
  setSelectedFile,
  uploadIsEnabled,
  setUploadIsEnabled,
  uploadFileData,
}: FileUploadUIState) => {
  const onSelectFile = (e: any) => {
    setSelectedFile({ file: e.target.files[0] });
  };

  useEffect(() => {
    if (selectedFile) {
      setUploadIsEnabled(true);
    }
  }, [selectedFile]);

  const uploadFile = () => {
    //get selected file data to upload
    var reader = new FileReader();
    reader.readAsDataURL(selectedFile.file);
    reader.onload = function () {
      if (reader.result) {
        uploadFileData(reader.result.toString().split(",")[1]);
      }
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        setSelectedFile({ file: file });
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Col>
      <Card className="file-card text-center mx-auto mb-4">
        <Card.Header>
          <Form.Label column>{header}</Form.Label>
        </Card.Header>
        <Card.Body>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag and Drop Any File Here</p>
            <p>or</p>
            <Button className="mb-2 app-primary-color-inverse" onClick={onSelectFile}>
              Choose a File
            </Button>
          </div>
          <Row>
            <Col></Col>
            <Col className="col-6">
              {uploadIsEnabled && (
                <Button className="mt-2 mb-1 app-secondary-color w-100 button" onClick={() => uploadFile()}>
                  <FontAwesomeIcon icon={faUpload} /> {selectedFile?.file?.name}
                </Button>
              )}
            </Col>
            <Col></Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default FileUploadUI;
