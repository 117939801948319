import { faCaretRight, faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, ListGroupItem, Row } from "react-bootstrap";

interface ConversationListItemState {
  conversation: any;
  currentConversation: any;
  onClick: any;
}

const ConversationListItem = ({ conversation, currentConversation, onClick }: ConversationListItemState) => {
  return (
    <ListGroupItem
      action
      key={conversation?.id}
      className="m-0 mb-1 message-item cursor-pointer"
      onClick={() => onClick(conversation)}
    >
      <Row>
        <Col className="col-10">
          <h6
            className={
              conversation?.id === currentConversation?.id ? "mb-0 small-text app-orange-text" : "mb-0 small-text"
            }
          >
            {conversation?.name}
          </h6>
          <p className="small-text">{conversation?.recipients}</p>
        </Col>
        <Col className="col-2 text-end align-self-center">
          {conversation?.new_messages && conversation?.id !== currentConversation?.id ? (
            <FontAwesomeIcon className="app-orange-text" icon={faMessage} />
          ) : (
            <FontAwesomeIcon
              icon={faCaretRight}
              className={conversation?.id === currentConversation?.id ? "app-orange-text" : ""}
            />
          )}
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default ConversationListItem;
