import {
  faBook,
  faBuilding,
  faCar,
  faChild,
  faFaceSmile,
  faHeartPulse,
  faHelmetSafety,
  faHouseChimneyWindow,
  faMoneyCheck,
  faSeedling,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { ContinuumPieChart } from "./ContinuumPieChart";
import { ContinuumLineChart } from "./ContinuumLineChart";
import { fetchReportContinuum, fetchUserCaseReportContinuum } from "../../services/httpClient";
import { useParams } from "react-router-dom";

const ContinuumCategoryPicker = ({ isAdmin = false }: any) => {
  const { id } = useParams();

  const [activeIncome, setActiveIncome] = useState<boolean>(false);
  const [activeEducation, setActiveEducation] = useState<boolean>(false);
  const [activeEmployment, setActiveEmployment] = useState<boolean>(false);
  const [activeChildcare, setActiveChildcare] = useState<boolean>(false);
  const [activeHealth, setActiveHealth] = useState<boolean>(false);
  const [activeNutrition, setActiveNutrition] = useState<boolean>(false);
  const [activeHousing, setActiveHousing] = useState<boolean>(false);
  const [activeTransportation, setActiveTransportation] = useState<boolean>(false);
  const [activeSupport, setActiveSupport] = useState<boolean>(false);
  const [activeFamily, setActiveFamily] = useState<boolean>(false);

  const [incomeData, setIncomeData] = useState<any[]>([]);
  const [educationData, setEducationData] = useState<any[]>([]);
  const [employmentData, setEmploymentData] = useState<any[]>([]);
  const [childcareData, setChildcareData] = useState<any[]>([]);
  const [healthData, setHealthData] = useState<any[]>([]);
  const [nutritionData, setNutritionData] = useState<any[]>([]);
  const [housingData, setHousingData] = useState<any[]>([]);
  const [transportationData, setTransportationData] = useState<any[]>([]);
  const [supportData, setSupportata] = useState<any[]>([]);
  const [familyData, setFamilyData] = useState<any[]>([]);

  useEffect(() => {
    setActiveIncome(true);
    setActiveEducation(false);
    setActiveEmployment(false);
    setActiveChildcare(false);
    setActiveHealth(false);
    setActiveNutrition(false);
    setActiveHousing(false);
    setActiveTransportation(false);
    setActiveSupport(false);
    setActiveFamily(false);

    if (isAdmin) {
      getContinuumData();
    } else {
      getCaseContinuumData();
    }
  }, []);

  const getCaseContinuumData = () => {
    fetchUserCaseReportContinuum(id).then((res: any) => {
      const cont = res[0].data;
      calculatePieData(cont);
    });
  };

  const getContinuumData = () => {
    fetchReportContinuum().then((res: any) => {
      const cont = res[0].data;
      calculatePieData(cont);
    });
  };

  const calculatePieData = (data: any) => {
    const income = calculateCategory("income", data);
    setIncomeData(income);

    const education = calculateCategory("education", data);
    setEducationData(education);

    const employment = calculateCategory("employment", data);
    setEmploymentData(employment);

    const childcare = calculateCategory("childcare", data);
    setChildcareData(childcare);

    const healthcare = calculateCategory("healthcare", data);
    setHealthData(healthcare);

    const nutrition = calculateCategory("nutrition", data);
    setNutritionData(nutrition);

    const housing = calculateCategory("housing", data);
    setHousingData(housing);

    const transportation = calculateCategory("transportation", data);
    setTransportationData(transportation);

    const support = calculateCategory("support", data);
    setSupportata(support);

    const family = calculateCategory("family", data);
    setFamilyData(family);
  };

  const calculateCategory = (category: string, data: any) => {
    const cat = data?.filter((d: any) => d.key === category);

    if (isAdmin) {
      const crisis = cat?.filter((i: any) => i.value === "In-Crisis");
      const risk = cat?.filter((i: any) => i.value === "At-Risk");
      const safe = cat?.filter((i: any) => i.value === "Safe");
      const stable = cat?.filter((i: any) => i.value == "Stable");
      const thriving = cat?.filter((i: any) => i.value === "Thriving");

      return [
        {
          id: 0,
          area: "In-Crisis",
          numberCases: crisis?.length,
        },
        {
          id: 1,
          area: "At-Risk",
          numberCases: risk?.length,
        },
        {
          id: 2,
          area: "Safe",
          numberCases: safe?.length,
        },
        {
          id: 3,
          area: "Stable",
          numberCases: stable?.length,
        },
        {
          id: 4,
          area: "Thriving",
          numberCases: thriving?.length,
        },
      ];
    } else {
      var chartData: any = [];

      cat.map((c: any) => {
        chartData.push({
          date: c.created_at,
          score: c.value,
        });
      });

      return chartData;
    }
  };

  const onNavClick = (route: string) => {
    switch (route) {
      case "income":
        setActiveIncome(true);
        setActiveEducation(false);
        setActiveEmployment(false);
        setActiveChildcare(false);
        setActiveHealth(false);
        setActiveNutrition(false);
        setActiveHousing(false);
        setActiveTransportation(false);
        setActiveSupport(false);
        setActiveFamily(false);
        break;
      case "education":
        setActiveIncome(false);
        setActiveEducation(true);
        setActiveEmployment(false);
        setActiveChildcare(false);
        setActiveHealth(false);
        setActiveNutrition(false);
        setActiveHousing(false);
        setActiveTransportation(false);
        setActiveSupport(false);
        setActiveFamily(false);
        break;
      case "employment":
        setActiveIncome(false);
        setActiveEducation(false);
        setActiveEmployment(true);
        setActiveChildcare(false);
        setActiveHealth(false);
        setActiveNutrition(false);
        setActiveHousing(false);
        setActiveTransportation(false);
        setActiveSupport(false);
        setActiveFamily(false);
        break;
      case "childcare":
        setActiveIncome(false);
        setActiveEducation(false);
        setActiveEmployment(false);
        setActiveChildcare(true);
        setActiveHealth(false);
        setActiveNutrition(false);
        setActiveHousing(false);
        setActiveTransportation(false);
        setActiveSupport(false);
        setActiveFamily(false);
        break;
      case "health":
        setActiveIncome(false);
        setActiveEducation(false);
        setActiveEmployment(false);
        setActiveChildcare(false);
        setActiveHealth(true);
        setActiveNutrition(false);
        setActiveHousing(false);
        setActiveTransportation(false);
        setActiveSupport(false);
        setActiveFamily(false);
        break;
      case "nutrition":
        setActiveIncome(false);
        setActiveEducation(false);
        setActiveEmployment(false);
        setActiveChildcare(false);
        setActiveHealth(false);
        setActiveNutrition(true);
        setActiveHousing(false);
        setActiveTransportation(false);
        setActiveSupport(false);
        setActiveFamily(false);
        break;
      case "housing":
        setActiveIncome(false);
        setActiveEducation(false);
        setActiveEmployment(false);
        setActiveChildcare(false);
        setActiveHealth(false);
        setActiveNutrition(false);
        setActiveHousing(true);
        setActiveTransportation(false);
        setActiveSupport(false);
        setActiveFamily(false);
        break;
      case "transportation":
        setActiveIncome(false);
        setActiveEducation(false);
        setActiveEmployment(false);
        setActiveChildcare(false);
        setActiveHealth(false);
        setActiveNutrition(false);
        setActiveHousing(false);
        setActiveTransportation(true);
        setActiveSupport(false);
        setActiveFamily(false);
        break;
      case "support":
        setActiveIncome(false);
        setActiveEducation(false);
        setActiveEmployment(false);
        setActiveChildcare(false);
        setActiveHealth(false);
        setActiveNutrition(false);
        setActiveHousing(false);
        setActiveTransportation(false);
        setActiveSupport(true);
        setActiveFamily(false);
        break;
      case "family":
        setActiveIncome(false);
        setActiveEducation(false);
        setActiveEmployment(false);
        setActiveChildcare(false);
        setActiveHealth(false);
        setActiveNutrition(false);
        setActiveHousing(false);
        setActiveTransportation(false);
        setActiveSupport(false);
        setActiveFamily(true);
        break;
      default:
        setActiveIncome(true);
        setActiveEducation(false);
        setActiveEmployment(false);
        setActiveChildcare(false);
        setActiveHealth(false);
        setActiveNutrition(false);
        setActiveHousing(false);
        setActiveTransportation(false);
        setActiveSupport(false);
        setActiveFamily(false);
        break;
    }
  };

  return (
    <Row className="justify-content-start mb-3">
      <Col className="col-12">
        <Tab.Container defaultActiveKey="income">
          <Row>
            <Col sm={4}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="income"
                    className={activeIncome ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("income")}
                  >
                    <>
                      <FontAwesomeIcon icon={faMoneyCheck} className={"me-2"} />
                      {"Income"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="education"
                    className={activeEducation ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("education")}
                  >
                    <>
                      <FontAwesomeIcon icon={faBook} className={"me-2"} />
                      {"Education"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="employment"
                    className={activeEmployment ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("employment")}
                  >
                    <>
                      <FontAwesomeIcon icon={faBuilding} className={"me-2"} />
                      {"Employment"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="childcare"
                    className={activeChildcare ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("childcare")}
                  >
                    <FontAwesomeIcon icon={faChild} className={"me-2"} />
                    {"Childcare"}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="health"
                    className={activeHealth ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("health")}
                  >
                    <>
                      <FontAwesomeIcon icon={faHeartPulse} className={"me-2"} />
                      {"Health Care & Health Status"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="nutrition"
                    className={activeNutrition ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("nutrition")}
                  >
                    <>
                      <FontAwesomeIcon icon={faSeedling} className={"me-2"} />
                      {"Nutrition"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="housing"
                    className={activeHousing ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("housing")}
                  >
                    <>
                      <FontAwesomeIcon icon={faHouseChimneyWindow} className={"me-2"} />
                      {"Housing"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="transportation"
                    className={activeTransportation ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("transportation")}
                  >
                    <>
                      <FontAwesomeIcon icon={faCar} className={"me-2"} />
                      {"Transportation"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="support"
                    className={activeSupport ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("support")}
                  >
                    <>
                      <FontAwesomeIcon icon={faHelmetSafety} className={"me-2"} />
                      {"Support Systems"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="family"
                    className={activeFamily ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("family")}
                  >
                    <>
                      <FontAwesomeIcon icon={faFaceSmile} className={"me-2"} />
                      {"Family Interactions"}
                    </>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={8}>
              <Tab.Content>
                <Tab.Pane eventKey="income">
                  {isAdmin ? (
                    <ContinuumPieChart data={incomeData} reportTitle={"Income"} />
                  ) : (
                    <ContinuumLineChart assessData={incomeData} titleText={"Income Trend"} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="education">
                  {isAdmin ? (
                    <ContinuumPieChart data={educationData} reportTitle={"Education"} />
                  ) : (
                    <ContinuumLineChart assessData={educationData} titleText={"Education Trend"} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="employment">
                  {isAdmin ? (
                    <ContinuumPieChart data={employmentData} reportTitle={"Employment"} />
                  ) : (
                    <ContinuumLineChart assessData={employmentData} titleText={"Employment Trend"} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="childcare">
                  {isAdmin ? (
                    <ContinuumPieChart data={childcareData} reportTitle={"Childcare"} />
                  ) : (
                    <ContinuumLineChart assessData={childcareData} titleText={"Childcare Trend"} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="health">
                  {isAdmin ? (
                    <ContinuumPieChart data={healthData} reportTitle={"Health Care & Health Status"} />
                  ) : (
                    <ContinuumLineChart assessData={healthData} titleText={"Health Care & Health Status Trend"} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="nutrition">
                  {isAdmin ? (
                    <ContinuumPieChart data={nutritionData} reportTitle={"Nutrition"} />
                  ) : (
                    <ContinuumLineChart assessData={nutritionData} titleText={"Nutrition Trend"} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="housing">
                  {isAdmin ? (
                    <ContinuumPieChart data={housingData} reportTitle={"Housing"} />
                  ) : (
                    <ContinuumLineChart assessData={housingData} titleText={"Housing Trend"} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="transportation">
                  {isAdmin ? (
                    <ContinuumPieChart data={transportationData} reportTitle={"Transportation"} />
                  ) : (
                    <ContinuumLineChart assessData={transportationData} titleText={"Transportation Trend"} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="support">
                  {isAdmin ? (
                    <ContinuumPieChart data={supportData} reportTitle={"Support Systems"} />
                  ) : (
                    <ContinuumLineChart assessData={supportData} titleText={"Support Systems Trend"} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="family">
                  {isAdmin ? (
                    <ContinuumPieChart data={familyData} reportTitle={"Family Interactions"} />
                  ) : (
                    <ContinuumLineChart assessData={familyData} titleText={"Family Interactions Trend"} />
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Col>
    </Row>
  );
};

export default ContinuumCategoryPicker;
