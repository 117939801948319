import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { WellBeingLineChart } from "../report-ui/WellBeingLineChart";
import ReportTimeInterval from "../report-ui/ReportTimeInterval";
import { fetchReportWellBeing } from "../../services/httpClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2canvas from "html2canvas";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const WellBeingTab = ({ buildPDF, canvas, setCanvas }: any) => {
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [dateInterval, setDateInterval] = useState<number>(10000);

  const [assessData, setAssessData] = useState();
  const [satisfaction, setSatisfaction] = useState<any>();
  const [health, setHealth] = useState<any>();
  const [purpose, setPurpose] = useState<any>();
  const [virtue, setVirtue] = useState<any>();
  const [social, setSocial] = useState<any>();
  const [stability, setStability] = useState<any>();
  const [trend, setTrend] = useState<any>();

  const [reportRunning, setReportRunning] = useState<boolean>(false);

  useEffect(() => {
    const start = new Date();
    start.setDate(start.getDate() - dateInterval);
    setStartDate(start.toISOString().slice(0, 10));
    setEndDate(new Date().toISOString().slice(0, 10));
    getWellBeingData(start.toISOString().slice(0, 10), new Date().toISOString().slice(0, 10));
  }, []);

  useEffect(() => {
    if (reportRunning) {
      buildPDF();
      setReportRunning(false);
    }
  }, [canvas]);

  const getWellBeingData = (start: string, end: string) => {
    fetchReportWellBeing(start, end)
      .then((res: any) => {
        const well = res[0].data;
        setAssessData(well);

        calculateCategories(well);
        calculateWellBeingTrend(well);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const calculateCategories = (data: any) => {
    var runningValue = 0;
    //Happiness and Life Satisfaction
    var working = data.filter((i: any) => i.category === "Happiness and Life Satisfaction");
    if (working?.length > 0) {
      working?.map((w: any) => {
        runningValue = w?.value + runningValue;
      });
      var value = runningValue / working?.length;
      setSatisfaction(parseFloat(value.toFixed(1)));
    } else setSatisfaction("-");

    runningValue = 0;
    //Mental and Physical Health
    working = data.filter((i: any) => i.category === "Mental and Physical Health");
    if (working?.length > 0) {
      working?.map((w: any) => {
        runningValue = w?.value + runningValue;
      });
      value = runningValue / working?.length;
      setHealth(parseFloat(value.toFixed(1)));
    } else setHealth("-");

    runningValue = 0;
    //Meaning and Purpose
    working = data.filter((i: any) => i.category === "Meaning and Purpose");
    if (working?.length > 0) {
      working?.map((w: any) => {
        runningValue = w?.value + runningValue;
      });
      value = runningValue / working?.length;
      setPurpose(parseFloat(value.toFixed(1)));
    } else setPurpose("-");

    runningValue = 0;
    //Character and Virtue
    working = data.filter((i: any) => i.category === "Character and Virtue");
    if (working?.length > 0) {
      working?.map((w: any) => {
        runningValue = w?.value + runningValue;
      });
      value = runningValue / working?.length;
      setVirtue(parseFloat(value.toFixed(1)));
    } else setVirtue("-");

    runningValue = 0;
    //Close Social Relationships
    working = data.filter((i: any) => i.category === "Close Social Relationships");
    if (working?.length > 0) {
      working?.map((w: any) => {
        runningValue = w?.value + runningValue;
      });
      value = runningValue / working?.length;
      setSocial(parseFloat(value.toFixed(1)));
    } else setSocial("-");

    runningValue = 0;
    //Financial and Material Stability
    working = data.filter((i: any) => i.category === "Financial and Material Stability");
    if (working?.length > 0) {
      working?.map((w: any) => {
        runningValue = w?.value + runningValue;
      });
      value = runningValue / working?.length;
      setStability(parseFloat(value.toFixed(1)));
    } else setStability("-");
  };

  const calculateWellBeingTrend = (data: any[]) => {
    const groupedData: { [date: string]: { sum: number; count: number } } = data.reduce((acc: any, obj: any) => {
      const date = obj.created_at;
      const value = obj.value;
      if (!acc[date]) {
        acc[date] = { sum: 0, count: 0 };
      }
      acc[date].sum += value;
      acc[date].count++;
      return acc;
    }, {});

    // Calculate average for each group
    const averagedData = Object.entries(groupedData).map(([date, { sum, count }]) => ({
      date,
      score: sum / count,
    }));

    setTrend(averagedData);
    //return averagedData;
  };

  const downloadReport = async () => {
    setReportRunning(true);
    const reportSection = document.getElementById("wellbeing-section");
    const reportTitle = document.getElementById("wellbeing-title");

    if (reportSection) {
      if (reportTitle) {
        reportTitle.style.visibility = "hidden";
        reportTitle.style.display = "none";
      }

      const images = reportSection.getElementsByTagName("img");
      for (let i = 0; i < images.length; i++) {
        images[i].crossOrigin = "anonymous";
      }

      const canvas = await html2canvas(reportSection, { useCORS: true });
      setCanvas(canvas);

      if (reportTitle) {
        reportTitle.style.visibility = "visible";
        reportTitle.style.display = "flex";
      }
    } else {
      console.error("Report section not found");
      setReportRunning(false);
    }
  };

  return (
    <>
      <Container id="wellbeing-section" className="lg vstack gap-3">
        <Row id="wellbeing-title">
          <Col className="col-8">
            <h4 className="mb-0 app-header-text">Well Being Assessment</h4>
          </Col>
          <Col className="text-end col-4">
            <Button className="app-primary-color button" onClick={() => downloadReport()}>
              <FontAwesomeIcon icon={faDownload} />
              {" Report"}
            </Button>
          </Col>
        </Row>
        <ReportTimeInterval
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          dateInterval={dateInterval}
          setDateInterval={setDateInterval}
          intervalOne={90}
          intervalOneText={"Last 3 Months"}
          intervalTwo={180}
          intervalTwoText={"Last 6 Months"}
          intervalThree={365}
          intervalThreeText={"Last Year"}
          intervalFour={10000}
          intervalFourText={"All Time"}
          onClickRefresh={getWellBeingData}
          hideDates={true}
        />
        <Row className="justify-content-center gap-3 mt-3">
          <Col className="col-5">
            <Card className="bg-light">
              <Card.Body>
                <Row className="text-center">
                  <Col className="col-9 d-flex align-items-center">
                    <h5 className="app-orange-text">{"Happiness and Life Satisfaction"}</h5>
                  </Col>
                  <Col className="col-3 d-flex align-items-center">
                    <h2 className="text-muted text-end">{satisfaction}</h2>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-5">
            <Card className="bg-light">
              <Card.Body>
                <Row className="text-center">
                  <Col className="col-9 d-flex align-items-center">
                    <h5 className="app-orange-text">{"Mental and Physical Health"}</h5>
                  </Col>
                  <Col className="col-3 d-flex align-items-center">
                    <h2 className="text-muted text-end">{health}</h2>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-5">
            <Card className="bg-light">
              <Card.Body>
                <Row className="text-center">
                  <Col className="col-9 d-flex align-items-center">
                    <h5 className="app-orange-text">{"Meaning and Purpose"}</h5>
                  </Col>
                  <Col className="col-3 d-flex align-items-center">
                    <h2 className="text-muted text-end">{purpose}</h2>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-5">
            <Card className="bg-light">
              <Card.Body>
                <Row className="text-center">
                  <Col className="col-9 d-flex align-items-center">
                    <h5 className="app-orange-text">{"Character and Virtue"}</h5>
                  </Col>
                  <Col className="col-3 d-flex align-items-center">
                    <h2 className="text-muted text-end">{virtue}</h2>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-5">
            <Card className="bg-light">
              <Card.Body>
                <Row className="text-center">
                  <Col className="col-9 d-flex align-items-center">
                    <h5 className="app-orange-text">{"Close Social Relationships"}</h5>
                  </Col>
                  <Col className="col-3 d-flex align-items-center">
                    <h2 className="text-muted text-end">{social}</h2>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-5">
            <Card className="bg-light">
              <Card.Body>
                <Row className="text-center">
                  <Col className="col-9 d-flex align-items-center">
                    <h5 className="app-orange-text">{"Financial and Material Stability"}</h5>
                  </Col>
                  <Col className="col-3 d-flex align-items-center">
                    <h2 className="text-muted text-end">{stability}</h2>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col></Col>
          <Col className="col-8">
            <WellBeingLineChart assessData={trend} />
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </>
  );
};

export default WellBeingTab;
