import React, { useEffect, useState } from "react";
import { Col, Form, ListGroupItem, Row, Stack } from "react-bootstrap";
import useApp from "../../../utils/useApp";
import { User } from "../../../types/user";

interface CreateGroupUserState {
  user: User;
  checkedUsers: User[];
  setCheckedUsers: any;
}

const CreateGroupUser = ({ user, checkedUsers, setCheckedUsers }: CreateGroupUserState) => {
  const { auth }: any = useApp();
  const [currentUser, setCurrentUser] = useState(user);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedDisabled, setIsCheckedDisabled] = useState(false);

  useEffect(() => {
    //check if user is checked by default
    if (auth.profile.role === "support" && auth.profile.id === user.user_id) {
      setIsChecked(true);
      setIsCheckedDisabled(true);
    }
    if (user.name === "Primary Case Manager" || user.name === "Secondary Case Manager") {
      setIsChecked(true);
      setIsCheckedDisabled(true);
    }

    //update user object for easier display
    if (user.id) {
      setCurrentUser({
        ...user,
        user_name: user.firstname + " " + user.lastname,
        type: user.role.charAt(0).toUpperCase() + user.role.slice(1),
      });
    } else {
      setCurrentUser({ ...user, type: user.name });
    }
  }, []);

  const onClickChecked = () => {
    if (!isChecked) {
      setCheckedUsers([...checkedUsers, user]);
    } else {
      setCheckedUsers(
        checkedUsers.filter((c: any) => {
          return c.user_id !== user.user_id;
        })
      );
    }

    setIsChecked(!isChecked);
  };

  return (
    <ListGroupItem onClick={() => onClickChecked()} disabled={isCheckedDisabled}>
      <Row>
        <Col className="col-10">
          <Stack>
            <p className="m-0">{currentUser.user_name} </p>
            <p className="text-muted p-0 m-0">{currentUser.type}</p>
          </Stack>
        </Col>
        <Col className="col-2 d-flex align-items-center justify-content-end">
          <Form.Check
            className="text-end"
            checked={isChecked}
            onChange={() => onClickChecked()}
            disabled={isCheckedDisabled}
          />
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default CreateGroupUser;
