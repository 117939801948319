import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./components/authentication/Login";
import RequireAuth from "./components/ui/RequireAuth";
import SendPasswordReset from "./components/authentication/SendPasswordReset";
import PasswordResetSent from "./components/authentication/PasswordResetSent";
import PasswordReset from "./components/authentication/PasswordReset";
import MyCases from "./components/my-cases/MyCases";
import Users from "./components/users/Users";
import Cases from "./components/cases/Cases";
import MyCase from "./components/my-cases/MyCase";
import EditUser from "./components/users/EditUser";
import EditCase from "./components/cases/EditCase";
import Reports from "./components/reports/Reports";
import MyCaseSupport from "./components/my-cases/MyCaseSupport";
import Authenticate from "./components/authentication/Authenticate";
import RequireAuth2fa from "./components/ui/RequireAuth2fa";
import TwoFactorSetup from "./components/authentication/TwoFactorSetup";
import InternalLogin from "./components/authentication/InternalLogin";
import Announcements from "./components/announcements/Announcements";
import EditAnnouncement from "./components/announcements/EditAnnouncement";
import EditGroup from "./components/groups/EditGroup";
import GroupMeeting from "./components/groups/GroupMeeting";
import AllGroups from "./components/groups/AllGroups";
import { signal } from "@preact/signals-react";
import useApp from "./utils/useApp";

export const unsavedChanges = signal(false);
export const currentUrl = signal("/");

const App = () => {
  const { auth, setActivePage }: any = useApp();

  useEffect(() => {
    const handleBackButton = (event: BeforeUnloadEvent) => {
      if (unsavedChanges.value === true) {
        event.preventDefault();
        unsavedChanges.value = false;
      }

      if (document.activeElement instanceof HTMLElement && typeof document.activeElement.blur === "function") {
        document.activeElement.blur();
      }
      setActivePage(window.location.pathname);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return (
    <>
      <Routes>
        <Route element={<RequireAuth />}>
          <Route path="/authenticate" element={<Authenticate />} />
          <Route path="/twofactor-setup" element={<TwoFactorSetup />} />
        </Route>

        <Route element={<RequireAuth2fa />}>
          <Route path="/" element={<MyCases />} />
          <Route path="/my-cases" element={<MyCases />} />
          <Route path="/my-cases/:id" element={auth?.profile?.role === "support" ? <MyCaseSupport /> : <MyCase />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/user" element={<EditUser />} />
          <Route path="/users/user/:id" element={<EditUser />} />
          <Route path="/users/user/:id?profile=true" element={<EditUser />} />
          <Route path="/cases" element={<Cases />} />
          <Route path="/cases/case" element={<EditCase />} />
          <Route path="/cases/case/:id" element={<EditCase />} />
          <Route path="/groups" element={<AllGroups />} />
          <Route path="/groups/group" element={<EditGroup />} />
          <Route path="/groups/group/:id" element={<EditGroup />} />
          <Route path="/groups/group/:id/meeting" element={<GroupMeeting />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/announcements" element={<Announcements />} />
          <Route path="/announcements/announcement" element={<EditAnnouncement />} />
          <Route path="/announcements/announcement/:id" element={<EditAnnouncement />} />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/send-reset" element={<SendPasswordReset />} />
        <Route path="/reset-sent" element={<PasswordResetSent />} />
        <Route path="/reset" element={<PasswordReset mode="desktop" />} />
        <Route path="/reset-mobile" element={<PasswordReset mode="mobile" />} />
        <Route path="/internal-login" element={<InternalLogin />} />

        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default App;

const Error = () => (
  <div>
    <h2>Error</h2>
  </div>
);
