import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, FloatingLabel, Stack, Button, Spinner } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  fetchGroup,
  fetchGroupParticipants,
  fetchGroupPossibleParticipants,
  patchGroup,
  postGroup,
  postGroupParticipant,
} from "../../services/httpClient";
import PageTitle from "../ui/PageTitle";
import ToastMsg from "../ui/ToastMsg";
import { GroupParticipant } from "../../types/groupparticipant";
import ParticipantCard from "./ParticipantCard";
import Input from "../ui/Input";
import SaveChangesModal from "../ui/SaveChangesModal";
import { unsavedChanges, currentUrl } from "../../App";
import useApp from "../../utils/useApp";

const EditGroup = () => {
  const { showMenu }: any = useApp();
  const location = useLocation();
  currentUrl.value = location.pathname;
  const { id } = useParams();
  const [editMode, setEditMode] = useState(id !== undefined ? true : false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [participants, setParticipants] = useState<GroupParticipant[] | undefined>(undefined);
  const [possibleParticipants, setPossibleParticipants] = useState<any[]>([]);
  const [selectedParticipant, setSelectedParticipant] = useState<string>();
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showAddParticipant, setShowAddParticipant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  const heading = editMode ? "Edit Group" : "Create Group";

  let navigate = useNavigate();

  const backGroup = () => {
    if (saveIsEnabled) {
      setShowModal(true);
    } else {
      navigate(`/groups`);
    }
  };

  const editGroup = (groupId: string) => {
    navigate(`/groups/group/${groupId}`);
  };

  useEffect(() => {
    if (editMode) {
      setIsLoading(true);
      getGroup();
    } else {
      setIsLoading(false);
    }
  }, [editMode]);

  const getGroup = () => {
    fetchGroup(id)
      .then((res: any) => {
        const currentGroup = res[0].data;
        setName(currentGroup.name);
        setDescription(currentGroup.description);

        getGroupParticipants();
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (saveIsEnabled) {
      unsavedChanges.value = true;
    } else {
      unsavedChanges.value = false;
    }
  }, [saveIsEnabled]);

  const getGroupParticipants = async () => {
    await fetchGroupParticipants(id).then((res: any) => {
      const currentParts = res[0].data;
      setParticipants(currentParts);
      setIsLoading(false);
    });
  };

  const getPossibleParticipants = () => {
    fetchGroupPossibleParticipants().then((res: any) => {
      const initialPossible = res[0].data[0];

      const possible = initialPossible.filter(
        (i: any) => !participants?.some((p: any) => p.participant_id === i.id && p.participant_type === i.type)
      );
      setPossibleParticipants(possible);
    });
  };

  useEffect(() => {
    if (participants !== undefined) {
      getPossibleParticipants();
    }
  }, [participants]);

  const saveGroup = () => {
    setIsLoading(true);

    const updatedGroup = {
      name: name,
      description: description,
    };

    if (editMode) {
      patchGroup(updatedGroup, id)
        .then((res: any) => {
          setSaveIsEnabled(false);
          setShowToast(true);
          setToastText("Group Updated");
          setToastBody(name);
          setIsLoading(false);
        })
        .catch((err: any) => {
          setIsLoading(false);
        });
    } else {
      postGroup(updatedGroup)
        .then((res: any) => {
          setSaveIsEnabled(false);
          setShowToast(true);
          setToastText("Group Created");
          setToastBody(name);
          setEditMode(true);
          editGroup(res[0].data?.group_id);
          setIsLoading(false);
        })
        .catch((err: any) => {
          setIsLoading(false);
        });
    }
  };

  const updateName = (e: any) => {
    setName(e.target.value);
    if (e.target.value) {
      setSaveIsEnabled(true);
    } else {
      setSaveIsEnabled(false);
    }
  };

  const updateDescription = (e: any) => {
    setDescription(e.target.value);
    enableSave();
  };

  function enableSave() {
    if (name) {
      setSaveIsEnabled(true);
    } else {
      setSaveIsEnabled(false);
    }
  }

  const onClickAddParticipant = () => {
    setShowAddParticipant(true);
  };

  const onClickSaveParticipant = () => {
    const part = possibleParticipants.filter((obj) => {
      const fullname = obj.firstname + " " + obj.lastname;
      return fullname === selectedParticipant;
    });

    const newPart = {
      participant_id: part[0].id,
      participant_type: part[0].type,
    };

    postGroupParticipant(newPart, id).then((res: any) => {
      getGroupParticipants();
    });
  };

  const onClickCancelAddParticipant = () => {
    setShowAddParticipant(false);
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <Row className="mb-3">
            <Col className="col-6 text-start">
              <Button className={"app-primary-color button"} onClick={(e) => backGroup()}>
                {"Back to Groups"}
              </Button>
            </Col>
            <Col className="col-6 text-end">
              <Button
                id="pagetitle-button"
                className={"app-secondary-color button"}
                onClick={saveGroup}
                disabled={!saveIsEnabled}
              >
                {"Save"}
              </Button>
            </Col>
          </Row>
          <PageTitle title={heading} />
          <Stack gap={2}>
            <h4 className="app-header-text">Details</h4>
            <Row>
              <Col className="col-6 mb-2">
                <Input label="Name" type="text" value={name} onUpdate={(e: any) => updateName(e)} required={true} />
              </Col>
            </Row>
            <Row>
              <Col className="col-6 mb-2">
                <Input
                  label="Description"
                  type="text"
                  value={description}
                  onUpdate={(e: any) => updateDescription(e)}
                />
              </Col>
            </Row>
          </Stack>

          {editMode && (
            <>
              <h4 className="app-header-text mt-2 mb-3">Participants</h4>
              <Row>
                {participants?.map((item: any) => (
                  <ParticipantCard item={item} getGroupParticipants={getGroupParticipants} />
                ))}
              </Row>
              <Row className="mb-3">
                <Col className="col-4">
                  <Button
                    className="app-tertiary-color button"
                    onClick={(e) => onClickAddParticipant()}
                    disabled={showAddParticipant}
                  >
                    Add Participant
                  </Button>
                </Col>
              </Row>

              {showAddParticipant === true && (
                <Row className="align-items-center">
                  <Col className="col-5">
                    <FloatingLabel controlId="floatingInput" label="Possible Participants">
                      <Form.Select
                        className="decorated"
                        value={selectedParticipant}
                        onChange={(e: any) => setSelectedParticipant(e.target.value)}
                      >
                        <option key={0}>Select from the following</option>
                        {possibleParticipants.map((i: any) => (
                          <option key={i.id + i.type} value={i.firstname + " " + i.lastname}>
                            {i.relationship
                              ? i.firstname + " " + i.lastname + " - " + i.relationship
                              : i.firstname + " " + i.lastname}
                          </option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col className="col-3">
                    <Stack direction="horizontal" gap={3}>
                      <Button className="app-tertiary-color button" onClick={() => onClickSaveParticipant()}>
                        Add
                      </Button>
                      <div className="vr" />
                      <Button className="app-primary-color button" onClick={() => onClickCancelAddParticipant()}>
                        Done
                      </Button>
                    </Stack>
                  </Col>
                </Row>
              )}
            </>
          )}
          <SaveChangesModal showModal={showModal} setShowModal={setShowModal} path="/groups" />
        </Container>
      )}
    </>
  );
};

export default EditGroup;
